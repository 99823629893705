<template>
    <div>
        <b-modal 
            id="modal-update-caddy-book-no-assignee-service"
            size="sm"
            centered
            hide-header
            :no-close-on-backdrop="true"
        >
                <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                <p class="mb-0 title-modal color-primary">
                    Cập nhật caddy
                </p>
                <feather-icon
                    icon="XIcon"
                    size="24"
                    class="cursor-pointer color-primary"
                    @click="hideModal"
                />
                </div>
            </b-card-header>
            <b-card-body>
                <div>
                    <div v-for="(item,indexBK) in data" :key="indexBK + 'booking'">
                        <div class="mb-1">
                            <span style="font-weight: bold;font-size: 15px;">{{ item.BookingDetail.Bdc }} - {{ item.BookingDetail.FullName }}</span> 
                            <span class="ml-1"><span style="font-weight: bold;font-size: 13px;">{{ item.BookingDetailService[0].ServiceName }}</span> {{ $t('golf_common_quantity') }}: {{ item.BookingDetailService.length }}</span>
                        </div>
                        <div v-for="(caddy,i) in item.BookingDetail.BookingDetailCaddy" 
                            :key="i + 'caddy'" 
                            class="pl-2 d-flex mb-1"
                        >
                            <b-form-checkbox v-model="caddy.checked" @change="checkSelectCaddyBalanceService(indexBK,i)"
                            >
                                {{ caddy.CaddyCode }} - {{ caddy.CaddyName }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
            </b-card-body>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                    variant="none"
                    size="md"
                    class="float-right"
                    @click="hideModal"
                    >
                    {{ $t('golf_common_back') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right mr-1"
                        @click="updateCaddyBook_RBK16"
                    >
                        Xác nhận
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { booking } from '@/api/booking'
export default {
    props: [
        'data'
    ],
    data() {
        return {
            
        }
    },
    watch: {
        data(value) {
            // console.log(value)
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-update-caddy-book-no-assignee-service')
        },
        async updateCaddyBook_RBK16() {
            let checkSelectCaddy = true
            for (const x of this.data) {
                if(x.BookingDetail.BookingDetailCaddy.filter(x => x.checked === true).length != x.BookingDetailService.length) {
                    checkSelectCaddy = false
                    break
                }
            }
            if(!checkSelectCaddy) {
                this.showToast('error', this.$t('golf_check_out_please_select_caddy_no_assignee_service'))
                return
            }
            for (let i = 0; i < this.data.length;i++) {
                const listCaddyDetail = this.data[i].BookingDetail.BookingDetailCaddy.filter(x => x.checked === true)
                listCaddyDetail.forEach(async (caddy,index) => {
                    const body = {
                        CaddyId: caddy.CaddyId,
                        BookingDetailServiceId: this.data[i].BookingDetailService[index].Id
                    }
                    await booking.api_UBK16(body)
                    if(i === this.data.length - 1 && index === listCaddyDetail.length - 1) {
                        this.$emit('event', {type: 'after-update-caddy'})
                        this.showToast('success', this.$t('golf_common_success'))
                        this.hideModal()
                    }
                })
            }
        },
        checkSelectCaddyBalanceService(indexBK,indexCaddy) {
            let conut = 0
            this.data[indexBK].BookingDetail.BookingDetailCaddy.forEach((x,index) => {
                if(x.checked) {
                    conut++
                }
            })
            if(this.data[indexBK].BookingDetailService.length < conut) {
                this.data[indexBK].BookingDetail.BookingDetailCaddy[indexCaddy].checked = false
                this.showToast('error', this.$t('golf_check_out_no_select_caddy_more_service'))
            }
        }
    },
}
</script>
<style lang="scss">
    
</style>