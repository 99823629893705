<template>
    <div>
      <b-modal
        id="modal-select-cardcode"
        size="md"
        v-model="isOpen"
        centered
        hide-header
        :no-close-on-backdrop="true"
      >
        <b-card-header class="bg-linear-warning">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-warning">
              Chọn mã thẻ
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#FDB913"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
          <p>
            Chọn thẻ áp dụng 
            <v-select 
                v-if="items"
                v-model="CardCodeSelected"
                :options="items.dataCardCodeValid"
                :clearable="false"
                label="CardCode"
            />
          </p>
        </b-card-body>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="warning"
              size="md"
              class="float-right"
              @click="save"
            >
              {{ $t('golf_common_confirm') }}
            </b-button>
          </div>
        </template>
      </b-modal>
      <ShortKey
        :shortcuts="ShortCutKey"
        :is-active="isOpen"
        :all-child-component-closed="allChildComponentClosed"
        @triggered="onTriggeredEventHandler"
      />
    </div>
  </template>
  
  <script>
  
  import { booking } from '@/api/booking'
  import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
  import ShortKey from '@/views/modules/ultils/ShortKey'
  export default {
    components: {
      ShortKey
    },
    props: [
      'data', 'items',
    ],
    data() {
      return {
        reason: '',
        ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
        allChildComponentClosed: [],
        isOpen: false,
        CardCodeSelected: null
      }
    },
    watch: {
      isOpen(val) {
        this.$emit('is-open', {ComponentId: "modal-select-cardcode",IsActive:val});
        if(val) {
            this.CardCodeSelected = this.items.dataCardCodeValid[0]
        }
      }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('modal-select-cardcode')
      },
      async save() {
        if(this.CardCodeSelected) {
            this.items.CardCodeSelected = JSON.parse(JSON.stringify(this.CardCodeSelected))
            this.$emit('event', { type: 'after_select_cardcode', data: this.items })
            this.hideModal()
            this.CardCodeSelected = null
        }
      },
      onTriggeredEventHandler(payload) {
        if (payload.evt.ctrlKey && payload.key === S) {
          this.save()
        }
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  