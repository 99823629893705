<template>
        <b-modal
        id="modal-line-course-copy"
        cancel-variant="secondary"
        v-model="isOpen"
        :cancel-title="$t('golf_common_back')"
        :ok-title="$t('golf_common_confirm')"
        centered
        size="sm"
        title="Cập nhật hole"
        modal-class="modal-primary"
        @ok="save"
      >
        <div class="p-1">
          <p><strong>Thông tin số hole của khách chơi</strong></p>
          <div
            v-if="data"
            class="d-card-hole"
          >
            <div class="d-flex d-title-hole">
              <div class="d-grow mr-auto">
                <div
                  :style="{ background: data.GolfClassColor}"
                  class="dot-size-10 d-inline-block rounded-circle"
                />
                <span>{{ data.FullName }}</span>
              </div>
              <!-- <div>
                {{ utcConvertToLocalTimeZone(detailHole.PlayerPosition.OpenDateTime, 'DD/MM/YYYY - HH:mm' ) }} - {{ detailHole.PlayerPosition.TeeName }}
              </div> -->
            </div>
            <div class="d-content-hole">
              <div class="d-flex align-items-center">
                <div class="mr-auto">
                  <strong>Tổng số hole</strong>
                </div>
                <div class="d-select-hole"> 
                  {{ dataDetail2B.BookingDetail.TotalNumberOfHole }} hole
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="mr-auto">
                  <strong>Đường chơi bóng</strong>
                </div>
                <!-- <div>
                  {{ linePlay }}
                </div> -->
              </div>
              <div class="d-flex mt-1">
                <div
                  v-for="(i, index) in (dataDetail2B.BookingDetail.TotalNumberOfHole/9)"
                  :key="i"
                  class="d-tee-select mr-1"
                >
                  <div
                    v-for="tee in listTee"
                    :key="tee.Id"
                    class="tee mb-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      class="btn-tee"
                      :variant="data.HoleNew.length > 0 && data.HoleNew[index] && data.HoleNew[index] === tee.Id ? 'outline-primary' : 'outline-secondary'"
                      @click="chooseTee(tee, index)"
                    >
                      {{ tee.TeeName }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                v-for="(course, i) in data.BookingDetailCourse"
                :key="i"
                class="d-flex mt-2"
              >
                <div class="mr-auto">
                  Số hole sân {{ course.CourseName }}
                </div>
                <div><strong>{{ course.NumberOfHole }} hole</strong></div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
</template>
<script>
import { CoolSelect } from 'vue-cool-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    props: [
      'listTee',
      'data',
      'dataDetail2B'
    ],
    data() {
      return {
        isOpen: false,
        optionHole: [],
      }
    },
    async mounted() {
      for (let index = 1; index <= 6; index++) {
        this.optionHole.push({
          id: index * 9,
          label: index * 9,
        })
      }
    },
    watch: {
      isOpen(value) {
        if(value) {

        }
      }
    },
    methods: {
      save() {
          this.$emit('event', { type: 'after-update-hole', dataDetail:  this.data})
        },
        chooseTee(tee, index) {
          this.data.HoleNew[index] = tee.Id

          const BookingInfoCourse = []
          this.data.HoleNew.forEach(x => {
            const courseTemp = this.listTee.find(course => course.Id === x)
            if (BookingInfoCourse.filter(y => y.CourseId == courseTemp.CourseId).length == 0) {
              BookingInfoCourse.push({
                CourseId: courseTemp.CourseId,
                NumberOfHole: 9,
                CourseName: courseTemp.CourseName,
              })
            } else {
              BookingInfoCourse.find(y => y.CourseId == courseTemp.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == courseTemp.CourseId).NumberOfHole + 9
            }
          })

          this.data.BookingDetailCourse = BookingInfoCourse

          this.$forceUpdate()
        }
    }
}
</script>
<style lang="scss">
    #modal-line-course-copy {
    .d-card-hole {
        background: #FFFFFF;
        /* 2) Button Shadow/Primary */
        box-shadow: 0px 4px 6px rgba(17, 74, 159, 0.38);
        border-radius: 6px;
        .d-title-hole {
            background: rgba(17, 74, 159, 0.2);
            padding: 9px 12px;
            border-radius: 6px 6px 0 0;
        }
        .d-content-hole {
            padding: 12px;
            .btn-tee {
                padding: 6px;
            }
            .d-select-hole {
                width: 92px;
            }
        }
    }
}
</style>