<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable no-restricted-syntax -->
<template>
  <div class="detail-into-money">
    <b-overlay
      :show="isLoading"
      :opacity="0.3"
      rounded="sm"
    >
      <b-table-simple
        hover
        small
        borderless
        :sticky-header="false"
        :no-border-collapse="false"
      >
        <b-thead>
          <b-tr class="header-table-custom">
            <b-th>
              Tee Time
            <!-- {{ $t('golf_booking_tee_picked') }} -->
            </b-th>
            <b-th class="text-right">
              {{ $t('golf_booking_tee_price') }}
            </b-th>
            <b-th class="text-right">
              {{ $t('golf_booking_tee_number_person') }}
            </b-th>
            <b-th class="text-right">
              {{ $t('golf_booking_total') }}
            </b-th>
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="header-table-body-custom">
            <b-th
              colspan="5"
            >
              <span v-html="headerTeeTime" />

              <!-- <GolfBallTrack
                v-model="golfBallTrack"
                :course-id="courseId"
                :number-of-hole="numberOfHole"
              /> -->
            </b-th>
          </b-tr>
        </b-tbody>
        <b-tbody
          v-for="(item, index) in dataTeeTime"
          :key="index"
        >
          <b-tr>
            <b-th colspan="4">
              <div class="d-flex align-items-center my-50">
                <div class="mr-auto d-flex justify-content-between align-items-center">
                  <strong
                    class="text-uppercase"
                    style="min-width: 50px"
                  >
                    {{ item[0].TeeName }}
                  </strong>
                  <GolfBallTrack
                    v-model="item[0].Line"
                    :course-id="courseId"
                    :number-of-hole="numberOfHole"
                    :tee-start-id="item[0].TeeId"
                    @update="updateLine()"
                  />
                </div>
                <strong> {{ item.map(tee => Number(tee.Price) * Number(tee.BookedNumberPlayer)).reduce((prev, curr) => prev + curr, 0) | formatNumber }}</strong>
              </div>
            </b-th>
            <b-th />
          </b-tr>
          <b-tr
            v-for="(tee, indexTee) in item"
            :key="indexTee"
          >
            <b-td>{{ utcConvertToLocalTimeZone(tee.Time, 'HH:mm' ) }}</b-td>
            <b-td class="text-right">
              {{ tee.Price | formatNumber }}
            </b-td>
            <b-td class="text-right">
              <b-form-group class="mb-0">
                <vue-numeric
                  v-model="tee.BookedNumberPlayer"
                  class="form-control"
                  style="width: 50px; float: right"
                  :min="0"
                  :max="+tee.NumberPlayer"
                  :empty-value="0"
                  :disabled="true"
                  @input="$event => changeBookNumber(tee, $event)"
                />
              </b-form-group>
            </b-td>
            <b-td class="text-right">
              {{ Number(tee.Price) * Number(tee.BookedNumberPlayer) | formatNumber }}
            </b-td>
            <b-td
              class="text-right text-danger"
              @click="tee.BookedNumberPlayer = 0; changeBookNumber(tee, 0)"
            >
              <ez-icon
                icon="ezGolf-icon-trash"
                size="18"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div
        v-if="detailTeeTime.length > 0"
        class="into-money"
      >
        <b-row>
          <b-col md="6">
            {{ $t('golf_booking_tee_total_player') }}:
          </b-col>
          <b-col md="6">
            <span>
              {{ computeTotalPlayerBooking(detailTeeTime) | formatNumber }}
            </span>
          </b-col>

          <b-col md="6">
            {{ $t('golf_booking_tee_total_into_money') }}:
          </b-col>
          <b-col md="6">
            <span>
              {{ computeTotalPriceBooking(detailTeeTime) | formatNumber }}
            </span>
          </b-col>
        </b-row>

        <div
          v-if="detailTeeTime.length <= 0"
        >
          <div class="h-100 bg-white">
            <NoRecordFound />
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { booking } from '@/api/booking'
import GolfBallTrack from '@/views/modules/ultils/GolfBallTrack/GolfBallTrack.vue'
import NoRecordFound from '../NoRecordFound/NoRecordFound'

export default {
  name: 'TableIntoMoneyBooking',
  components: {
    NoRecordFound,
    GolfBallTrack,
  },
  props: ['detailTeeTime', 'headerTeeTime', 'courseId', 'numberOfHole', 'golfClassId', 'rateId'],
  data() {
    return {
      numberOfGuest: 2,
      sumOfMoney: 0,
      sumOfPlayer: 0,
      golfBallTrack: [],
      isLoading: false,
    }
  },
  computed: {
    dataTeeTime() {
      const groupBy = function (xs, key) {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }
      return groupBy(this.detailTeeTime, 'TeeId')
    },
  },
  watch: {
    detailTeeTime(value) {
      this.updateLine()
    },
  },
  methods: {
    async updateLine() {
      this.isLoading = true

      for (const item in this.dataTeeTime) {
        this.dataTeeTime[item].forEach((detail, index) => {
          // Lấy line đầu tiên set cho các item sau trong store
          if (index != 0) {
            detail.Line = this.dataTeeTime[item][0].Line
          }

          // Tính lại giá
          const BookingInfoCourse = []

          detail.Line.forEach(x => {
            if (BookingInfoCourse.filter(y => y.CourseId == x.CourseId).length == 0) {
              BookingInfoCourse.push({
                CourseId: x.CourseId,
                NumberOfHole: 9,
              })
            } else {
              BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole + 9
            }
          })

          if (BookingInfoCourse.length && this.numberOfHole == detail.Line.filter(x => x.Id).length * 9) {
            const body = {
              GolfClassId: this.golfClassId,
              RateId: this.rateId,
              TeeTimeId: [detail.Id],
              Course: BookingInfoCourse,
            }
            booking.api_RTB02(body).then(res => {
              if (res.Status === '200') {
                const { BookingAmount } = res.Data
                detail.Price = BookingAmount.find(y => y.TeeTimeId == detail.Id).Amount
              }
            })
          }
        })
      }
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    changeBookNumber(item, number) {
      item.BookedNumberPlayer = number
      const tee = { ...item, number: +item.BookedNumberPlayer }
      if (+number === 0) {
        const flag = this.detailTeeTime.map(e => e.Id).indexOf(item.Id)
        this.detailTeeTime.splice(flag, 1)
      }
      this.$emit('change', tee)
    },
    computeTotalPriceBooking(teeTime) {
      let sumMoney = 0
      teeTime.forEach(tee => {
        sumMoney += Number(tee.Price) * Number(tee.BookedNumberPlayer)
      })
      return sumMoney
    },
    computeTotalPlayerBooking(teeTime) {
      let sumPlayer = 0
      teeTime.forEach(tee => {
        sumPlayer += Number(tee.BookedNumberPlayer)
      })
      return sumPlayer
    },
  },
}
</script>

<style scoped lang="scss">
</style>
