import vSelect from 'vue-select'
import Decimal from 'decimal.js'
import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import PaymentType from '@/views/modules/booking/PaymentType.vue'
import Cleave from 'vue-cleave-component'
import SelectDebitAccount from '@/views/modules/ultils/SelectDebitAccount'

const PaymentTypeCode = {
  Payment: 'PAY',
  Refund: 'REFUND',
}

const PaymentMethodGroup = {
  AR: 'AR',
  BANKTRANSFER: 'BANKTRANSFER',
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  EZTICKET: 'EZTICKET',
  EZGOLF: 'EZGOLF',
  OTHER: 'OTHER',
  PREPAIDCARD: 'PREPAIDCARD',
  QRCODE: 'QRCODE',
  ROOM: 'ROOM',
  VIP: 'VIP',
}

const paymentBase = {
  Id: null,
  PaymentCode: '',
  PaymentDate: '',
  FiscalPaymentDate: '',
  MemberId: null,
  TotalAmount: 0,
  ReceivedAmount: 0,
  PaymentDetail: [],
}

const formBase = {
  PaymentMethodId: null,
  PaymentTypeCode: null,
  Note: '',
  CashierCode: null,
  Amount: 0,
  CurrencyCode: '',
  CardNumber: '',
  ClientId: null,
  DebitAccountId: null,
  ExpirationDate: null,
  Bdc: null,
}

export default {
  components: {
    PaymentType,
    SelectDebitAccount,
    vSelect,
    Cleave,
  },
  props: {
    paymentId: {
      type: Number,
    },
    bookingDetailIdDistribution: {
      type: Number,
    },
    dataPayment: {
      type: Object,
    },
    distributionId: {
      type: Number,
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      PaymentMethods: [],
      PaymentTypeData: [],
      form: {
        PaymentMethodId: null,
        PaymentTypeCode: null,
        Note: '',
        CashierCode: null,
        Amount: 0,
        CurrencyCode: '',
        CardNumber: '',
        DebitAccountId: null,
        ClientId: null,
        ExpirationDate: null,
        Bdc: null,
      },
      fields: [
        {
          key: 'type',
          label: 'ID',
          thStyle: { width: '80%', display: 'none' },
          tdClass: 'e-td',
        },
        {
          key: 'total',
          label: 'ID',
          thStyle: { width: '20%', display: 'none' },
          tdClass: 'e-td',
        },
      ],
      paymentType: '',
      canShowPaymentType: false,
      paymentDr: { ...paymentBase },
      paymentAmountInputExchange: 0,
      debitAccountSelected: {
        Id: null,
        ClientName: '',
      },
      maskExpirationDate: {
        date: true,
        datePattern: ['m', 'y'],
        delimiter: '/',
      },
      maskCreditCard: {
        creditCard: true,
        delimiter: ' ',
      },
      Cashiers: [],
      paymentTypeOptions: [],
      listPaymentType: [],
      zoneId: [],
      paymentTypeName: null,
      TransactionCode: null,
      listClient: [],
      arId: null,
      isLoading: false,
      PaymentMethodByEvoucherInTransactionCode: null,
      PaymentMethodByVoucherInTransactionCode: null,
      isLoadingCheckVoucher: false
    }
  },
  created() {
    this.getPaymentConfig()
    this.getConfigInfo_RCOM01()
  },
  computed: {
    // paymentTypeOptions() {
    //   return [
    //     { title: this.$t('golf_common_payment'), value: PaymentTypeCode.Payment },
    //     { title: this.$t('golf_common_refund'), value: PaymentTypeCode.Refund },
    //   ]
    // },
    LS_Currency() {
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      return userData?.currency || []
    },
    baseCurrency() {
      const baseCurrency = this.LS_Currency.find(s => s.IsBase || false)
      return baseCurrency || {
        CurrencyCode: '',
        NoOfDec: 0,
      }
    },
    baseCurrencyCode() {
      return 'CurrencyCode' in this.baseCurrency ? this.baseCurrency.CurrencyCode : ''
    },
    currencyCodeSelected() {
      return this.form.CurrencyCode || ''
    },
    currencySelected() {
      const currencySelectedDetail = this.LS_Currency.find(s => s.CurrencyCode === this.currencyCodeSelected)
      if (typeof currencySelectedDetail !== 'undefined') {
        return {
          DivBase: currencySelectedDetail.DivBase || false,
          ExRate: currencySelectedDetail.ExRate || 1,
          NoOfDec: currencySelectedDetail.NoOfDec || 0,
        }
      }
      return {
        CurrencyCode: this.currencyCodeSelected,
        DivBase: false,
        ExRate: 1,
        NoOfDec: 0,
      }
    },
    totalAmountPaymentBaseCurr() {
      if (this.paymentDr) {
        return this.paymentDr.TotalAmount || 0
      }
      return 0
    },
    totalAmountPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.totalAmountPaymentBaseCurr
      }
      return this.GFn_GetExchangeMoney(this.totalAmountPaymentBaseCurr, this.currencySelected)
    },
    amountRemainPayment() {
      if (this.paymentDr) {
        const receivedAmount = this.paymentDr.ReceivedAmount || 0
        const balance = this.totalAmountPaymentBaseCurr - receivedAmount
        return balance >= 0 ? balance : 0
      }
      return 0
    },
    amountRemainPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.amountRemainPayment
      }
      return this.GFn_GetExchangeMoney(this.amountRemainPayment, this.currencySelected)
    },
    isPaymentDiffBase() {
      return this.currencyCodeSelected !== this.baseCurrencyCode
    },
    showMoneyExchange() {
      return this.isPaymentDiffBase
    },
    // Format Display
    amountRemainPaymentDisplay() {
      return `${this.formatPrice(this.amountRemainPayment)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentBaseCurrDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentBaseCurr)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentExchangeDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentExchange)} ${this.currencyCodeSelected}`
    },
    totalAmountPaymentInputExchangeDisplay() {
      return `${this.formatPrice(this.paymentAmountInputExchange)} ${this.baseCurrencyCode}`
    },
    showFormInputPaymentMethod() {
      return {
        AR: this.paymentType === PaymentMethodGroup.AR,
        BANKTRANSFER: this.paymentType === PaymentMethodGroup.BANKTRANSFER,
        CASH: this.paymentType === PaymentMethodGroup.CASH,
        CREDIT: this.paymentType === PaymentMethodGroup.CREDIT,
        EZTICKET: this.paymentType === PaymentMethodGroup.EZTICKET,
        EZGOLF: this.paymentType === PaymentMethodGroup.EZGOLF,
        OTHER: this.paymentType === PaymentMethodGroup.OTHER,
        PREPAIDCARD: this.paymentType === PaymentMethodGroup.PREPAIDCARD,
        QRCODE: this.paymentType === PaymentMethodGroup.QRCODE,
        ROOM: this.paymentType === PaymentMethodGroup.ROOM,
        VIP: this.paymentType === PaymentMethodGroup.VIP,
      }
    },
  },
  methods: {
    async getPaymentConfig() {
      const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      if (response.Status === '200') {
        this.arId = response?.Data.find(item => item.KeyCode === 'PAYMENT_METHOD_BY_BANK_AR').KeyValue
        this.PaymentMethodByEvoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_EVOUCHER_IN_TRANSACTIONCODE').KeyValue
        this.PaymentMethodByVoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_VOUCHER_IN_TRANSACTIONCODE').KeyValue
      }
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
        return this.call_api_RPF02()
      }
    },
    async call_api_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          this.listPaymentType = response?.Data?.PaymentType
          this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    async openForm() {
      this.isLoading = true
      this.resetFormData()

      // Get list client
      await this.getListClient()

      // this.form.PaymentTypeCode = this.paymentTypeOptions[0].value
      this.form.CurrencyCode = this.baseCurrencyCode
      if(this.paymentId) {
        await this.getDetailPayment_RPM04(this.paymentId)
      }
      // await this.getPaymentMethod()
      await this.getListCashier_RCS01()

      this.form.PaymentTypeCode = this.paymentTypeOptions[0].value
      // this.handleChoicePayment(this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])

      if (this.dataPayment.ClientId) {
        if (this.dataPayment.ClientId && this.listClient.filter(x => x.Id == this.dataPayment.ClientId).length) {
          this.debitAccountSelected.Id = this.dataPayment.ClientId
          this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.dataPayment.ClientId).ClientName
          this.form.DebitAccountId = this.dataPayment.ClientId
          this.form.ClientId = this.dataPayment.ClientId
          this.handleChoicePayment(this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
        }
      } else {
        this.handleChoicePayment(this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
      }
      this.isLoading = false
    },
    resetFormData() {
      this.form = { ...formBase }
      this.paymentDr = { ...paymentBase }
      this.paymentAmountExchange = 0
      this.PaymentTypeData = []
    },
    onSelectPaymentType(ev) {
      if (ev.value === PaymentTypeCode.Payment) {
        if (this.isPaymentDiffBase) {
          if (this.form.Amount > this.amountRemainPaymentExchange) {
            if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
              this.form.Amount = this.amountRemainPaymentExchange
            }
          }
          this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
        } else if (this.form.Amount > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
          this.form.Amount = this.amountRemainPayment
        }
      }
    },
    onSelectCurrency(ev) {
      this.paymentAmountInputExchange = this.amountRemainPayment
      this.form.Amount = this.amountRemainPaymentExchange
    },
    onChangeAmount(ev) {
      if (ev < 0) {
        this.form.Amount = 0
      }
      if (this.isPaymentDiffBase) {
        if (ev > this.amountRemainPaymentExchange) {
          if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
            this.form.Amount = this.amountRemainPaymentExchange
          }
        }
        this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
      } else if (ev > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
        this.form.Amount = this.amountRemainPayment
      }
    },
    close() {
      this.$emit('closePopup', true)
    },
    handleEvent() {
    },
    hideModal() {
      this.$bvModal.hide('modal-payment-for-distribution')
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(async response => {
        if (response.Status === '200') {
          this.Cashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))

          const userData = JSON.parse(localStorage.getItem('userData')) || {}
          const currentUser = userData?.userId || {}

          const temp = response?.Data?.Cashier.find(x => x.Id == currentUser).Id
          this.form.CashierCode = this.Cashiers.find(x => x.id == temp)
        }
      })
    },
    async getPaymentMethod() {
      await booking.getListPaymentMethod({}).then(response => {
        if (response.Status === '200') {
          this.PaymentMethods = response?.Data?.PaymentMethodGroup
          const paymentMethodDefault = this.getPaymentMethodDefault(this.PaymentMethods)
          if (paymentMethodDefault != null) {
            this.paymentType = paymentMethodDefault.Code
            this.form.PaymentMethodId = paymentMethodDefault.Id
          }
          // tach ra lam bo 3
          let count = 0
          const fields = []
          let fieldtmp = []
          let datatmp = []
          let { length } = this.PaymentMethods
          for (const payment of this.PaymentMethods) {
            const tmp = {}
            tmp.key = payment.Name
            tmp.label = payment.Name
            tmp.name = 'Code'
            payment[`${payment.Name}`] = payment.Name
            datatmp.push(payment)
            fieldtmp.push(tmp)
            count++
            length--
            if (count == 3) {
              fields.push({ fields: fieldtmp, data: datatmp })
              fieldtmp = []
              datatmp = []
              count = 0
            }
            if (length == 0) {
              fields.push(fieldtmp)
            }
          }
          this.PaymentTypeData = fields
        }
      })
    },

    async getDetailPayment_RPM04(paymentRetailId) {
      const payment = {
        Payment: {
          Id: this.paymentId,
        },
      }
      await booking.getListPaymentDetail(payment).then(async response => {
        if (response.Status === '200') {
          this.paymentDr = response?.Data?.Payment
          this.form.Amount = this.amountRemainPaymentExchange || 0
          if (this.isPaymentDiffBase) {
            this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
          }
        }
      })
    },
    getPaymentMethodDefault(paymentMethods) {
      let paymentMethodDefault = null
      paymentMethods.forEach(s => {
        const paymentMethodList = s.PaymentMethod || []
        paymentMethodList.forEach(s1 => {
          if (s1.Code === PaymentMethodGroup.CASH) {
            paymentMethodDefault = {
              Id: s1.Id,
              Code: s1.Code,
            }
          }
        })
      })
      return paymentMethodDefault
    },
    calTotalAmount() {
      const totalAmount = this.paymentDr.TotalAmount || 0
      const receivedAmount = this.paymentDr.ReceivedAmount || 0
      const balance = totalAmount - receivedAmount
      const total = balance >= 0 ? balance : 0
      if (this.paymentDr) {
        return `${this.formatPrice(total)} ${this.baseCurrencyCode}`
      }
      return `0 ${this.baseCurrencyCode}`
    },
    async createPaymentDetail_IPM04() {
      const { paymentId } = this
      const amount = parseFloat(this.form.Amount || '0')
      const paymentData = {
        BookingDetailId: (this.bookingDetailIdDistribution) ? this.bookingDetailIdDistribution : (this.dataPayment?.Id) ? this.dataPayment.Id : null,
        PaymentMethodId: parseInt(this.form.PaymentMethodId, 10),
        PaymentTypeCode: this.form.PaymentTypeCode,
        Amount: amount,
        Note: this.form.Note,
        CurrencyCode: this.form.CurrencyCode,
        CardNumber: this.form.CardNumber,
        DebitAccountId: this.form.DebitAccountId,
        ClientId: this.form.ClientId,
        Bdc: this.form.Bdc,
        CashierCode: (this.form.CashierCode && this.form.CashierCode.id) ? `${this.form.CashierCode.id}` : null,
        ExpirationDate: this.createFullDataExpirationDate(this.form.ExpirationDate),
        TransactionCode: this.TransactionCode,
      }

      const dataPost = {
        PaymentDetail: {
          ...paymentData,
        },
      }
      let isSubmit = true
      if (!paymentData.PaymentTypeCode) {
        this.showToast('error', this.$t('golf_common_please_select_payment_type'))
        isSubmit = false
      }
      if (amount < 0 || amount === 0) {
        isSubmit = false
        this.showToast('error', this.$t('golf_common_amount_invalid'))
      }
      if (!paymentData.CurrencyCode) {
        isSubmit = false
        this.showToast('error', this.$t('golf_common_please_select_a_currency'))
      }
      if (!this.paymentType || this.form.PaymentMethodId === null) {
        this.showToast('error', this.$t('golf_common_please_choose_a_form_of_payment'))
        isSubmit = false
      }
      if (this.paymentType === PaymentMethodGroup.AR) {
        if (!this.form.DebitAccountId) {
          this.showToast('error', this.$t('golf_common_please_choose_debit_account'))
          isSubmit = false
        }
      }
      // if (this.paymentType === PaymentMethodGroup.CREDIT) {
      //   if (!this.form.CardNumber) {
      //     this.showToast('error', this.$t('golf_common_please_enter_card_number'))
      //     isSubmit = false
      //   }
      //   if (!this.form.ExpirationDate) {
      //     this.showToast('error', this.$t('golf_common_please_enter_expiration_date'))
      //     isSubmit = false
      //   }
      // }
      if (this.paymentType === PaymentMethodGroup.OTHER && this.PaymentMethodByEvoucherInTransactionCode === this.TransactionCode || this.PaymentMethodByVoucherInTransactionCode === this.TransactionCode) {
        if (!this.form.VoucherCode) {
          this.showToast('error', this.$t('golf_common_please_enter_voucher'))
          isSubmit = false
        }
        if (this.form.VoucherCode && !this.voucherDetail) {
          this.showToast('error', this.$t('golf_common_voucher_invalid'))
          isSubmit = false
        }
      }
      if (!isSubmit) return
      if (this.bookingDetailIdDistribution) {
        const body = {
          TotalAmount: 0,
          Code: null,
          CashierCode: (this.form.CashierCode && this.form.CashierCode.id) ? `${this.form.CashierCode.id}` : null,
          PaymentDetail: [paymentData],
        }

        booking.api_IPM02D(body).then(res => {
          this.showResToast(res)
          if (res.Status == '200') {
            // Load lại giá trị sau update
            this.$emit('event', { type: 'after-add-new-payment-for-distribution-single' })
            this.hideModal()
          }
        })
      } else {
        this.$emit('event', { type: 'after-add-new-payment-for-distribution', body: dataPost, idItem: this.dataPayment.Id })
        this.hideModal()
      }
    },
    getMemberPay() {
      if (this.paymentDr) {
        return `Hóa đơn #${this.paymentDr.Id} ${this.paymentDr.FullName}`
      }
      return ''
    },
    async validateVoucher_VC_RV03(voucher) {
      if (voucher) {
        this.isLoadingCheckVoucher = true
        await booking.api_VC_RV03({ VoucherCode: voucher, PaymentId: this.paymentId }).then(response => {
          if (response.Status === '200') {
            this.voucherDetail = response.Data
            this.form.Amount = response.Data.TotalAmount
          } else {
            this.voucherDetail = null
            this.form.Amount = 0
            this.showResToast(response)
          }
        })
        this.isLoadingCheckVoucher = false
      } else {
        this.showToast('error', this.$t('golf_common_voucher_invalid'))
      }
    },
    openModalDeletePaymentDetail(id) {
      this.$emit('event', { type: 'open_modal_delete_payment_detail', paymentDetailId: id })
    },
    handlePaymentType(type) {
      const isShow = type === 'show'
      this.canShowPaymentType = isShow
    },
    handleChoicePayment(payment, trans) {
      this.paymentType = payment.Code
      this.paymentTypeName = `${payment.Name} - ${trans.Description}`
      this.TransactionCode = trans.TransactionCode
      this.form.PaymentMethodId = payment.Id
      this.canShowPaymentType = false
      if (this.paymentType !== PaymentMethodGroup.CREDIT) {
        this.form.ExpirationDate = null
        this.form.CardNumber = ''
      }
      if (this.paymentType !== PaymentMethodGroup.AR) {
        this.form.DebitAccountId = null
        this.form.ClientId = null
      }
    },
    getPaymentMethodNameById(id) {
      let name = id
      this.PaymentMethods.forEach(x => {
        x.PaymentMethod.forEach(y => {
          if (y.Id === id) {
            name = y.Name
          }
        })
      })
      return name
    },
    openSelectARAccount() {
      this.$root.$emit('bv::show::modal', 'modal-select-ar')
    },
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res?.Data?.Client || []
          }
        })
    },
    selectArAccount(arAccountData) {
      Object.keys(this.debitAccountSelected).forEach(arKey => {
        if (arKey in arAccountData) {
          this.debitAccountSelected[arKey] = arAccountData[arKey]
        }
      })
      this.form.DebitAccountId = this.debitAccountSelected.Id
      this.form.ClientId = this.debitAccountSelected.Id
    },
    createFullDataExpirationDate(shortExpirationDate) {
      if (shortExpirationDate) {
        const [month, yearOld] = shortExpirationDate.split('/')
        const year = this.dayjs(`${month}/01/${yearOld}`).format('YYYY')
        return new Date(year, month, 0, 23, 59, 59)
      }
      return null
    },
    GFn_GetExchangeMoney(baseMoney, {
      DivBase,
      ExRate,
      NoOfDec,
    }) {
      const baseMoneyToDecimal = new Decimal(baseMoney || 0)
      let exchangeMoney = 0
      const noOfDec = NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        exchangeMoney = Decimal.div(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        exchangeMoney = Decimal.mul(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(exchangeMoney).mul(1).toNumber()
    },
    GFn_GetBaseMoneyFromExchange(exchangeMoney, {
      DivBase,
      ExRate,
    }) {
      const exchangeMoneyToDecimal = new Decimal(exchangeMoney || 0)
      let baseMoney = 0
      const noOfDec = this.baseCurrency.NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        baseMoney = Decimal.mul(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        baseMoney = Decimal.div(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(baseMoney).mul(1).toNumber()
    },
  },
}
