<template>
  <div>
    <b-modal
      id="modal-copy-booking"
      v-model="isOpen"
      size="xl"
      :no-close-on-backdrop="true"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_common_copy_play_info') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>
      <b-card-body>
        <b-row>
          <b-col cols="3">
            <p class="player-info">
              {{ $t('golf_booking_play_info') }}
            </p>
            <b-media
              no-body
              class="py-1"
            >
              <b-media-aside
                class="media-left"
                style="margin-right: 10px;"
              >
                <UserAvatar
                  :user="data"
                  :size="80"
                />
              </b-media-aside>
              <b-media-body class="text-truncate">

                <h6 class="text-truncate">
                  <span>{{ data.BookingDetail.FullName }}</span>
                </h6>
                <div class="phone-email">
                  <div>
                    <ez-icon
                      icon="ezGolf-icon-phone"
                      size="12"
                    />
                    {{ data.BookingDetail.PhoneNumber }}
                  </div>
                  <div class="text-truncate">
                    <ez-icon
                      icon="ezGolf-icon-mail"
                      size="12"
                    />
                    {{ data.BookingDetail.Email }}
                  </div>
                  <div class="text-truncate">
                    <ez-icon
                      icon="ezGolf-icon-credit-card"
                      size="12"
                    />
                    {{ data.BookingDetail.CardCode }}
                  </div>
                </div>
              </b-media-body>
            </b-media>

            <b-form-group label="Golf class">
              <v-select
                v-model="golfClasses.selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="golfClasses.list"
                :clearable="false"
                label="GolfClassName"
                :disabled="true"
                @input="changeDateTime()"
              >
                <template #selected-option="{ GolfClassName, Color }">
                  <div
                    class="dot-size-10 d-inline-block rounded-circle"
                    :style="{backgroundColor: Color}"
                    style="margin-top: -2px;"
                  />
                  {{ GolfClassName }}
                </template>
                <template v-slot:option="{ GolfClassName, Color }">
                  <div
                    class="dot-size-10 d-inline-block rounded-circle"
                    :style="{backgroundColor: Color}"
                    style="margin-top: -2px;"
                  />
                  {{ GolfClassName }}
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              v-if="!isLoadingRate"
              :label="$t('golf_booking_new_rate')"
            >
              <v-select
                v-model="rates.new.selected"
                :options="rates.new.list"
                label="RateName"
                :clearable="false"
                :disabled="true"
              />
            </b-form-group>
            <div
              v-if="rates.new.selected && rates.new.selected.RateGolfClassRule && rates.new.selected.RateGolfClassRule.length"
              class="row"
            >
              <b-col>
                <b-form-group :label="$t('golf_booking_member_card_code')">
                  <b-form-input
                    disabled
                    v-model="rateGolfClassRule.cardCode"
                    @change="getListBDCByCardCode(true)"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="BDC">
                  <v-select
                    disabled
                    v-model="rateGolfClassRule.BDC"
                    :options="rateGolfClassRule.BDCList"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </div>

            <b-form-group :label="$t('golf_booking_old_rate')">
              <b-form-input
                v-model="data.BookingDetail.RateName"
                :disabled="true"
              />
            </b-form-group>

            <b-form-group :label="$t('golf_booking_copy_number_hole')">
              <div class="hover-editable disable">
                <span
                  v-for="(hole, index) in data.BookingDetail.BookingDetailCourse"
                  :key="hole.id"
                >
                  {{ hole.NumberOfHole }} Hole - {{ hole.Course.CourseName }}
                  <template v-if="data.BookingDetail.BookingDetailCourse.length > 1 && (data.BookingDetail.BookingDetailCourse.length - 1) != index">
                    <span class="mx-50">+</span>
                  </template>
                </span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('golf_common_company')">
              <b-form-input
                v-model="data.BookingDetail.ClientName"
                :disabled="true"
              />
            </b-form-group>
            <b-form-group :label="$t('golf_common_source')">
              <b-form-input
                v-model="data.BookingDetail.SourceName"
                :disabled="true"
              />
            </b-form-group>

          </b-col>
          <b-col cols="9">
            <b-row class="pb-1">
              <b-col md="3">

                <!-- <b-form-group
                  :label="$t('golf_booking_new_rate')"
                > -->
                <v-select
                  v-model="copyBy"
                  :options="listSelectBy"
                  label="label"
                  :reduce="label => label.id"
                  :clearable="false"
                />
                <!-- </b-form-group> -->
              </b-col>
              <b-col md="9">

                <v-select
                  v-if="copyBy == 'bybdc'"
                  ref="listBDC"
                  v-model="listbdc"
                  taggable
                  multiple
                  :placeholder="$t('golf_booking_search_bdc')"
                  class="custom-select-bdc"
                >
                  <!-- @input="getDetailBooking2ASearch(true,true)" -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No results found for <em>{{ search }}</em>.
                    </template>
                    <em
                      v-else
                      style="opacity: 0.5"
                    >{{ $t('golf_booking_search_bdc') }}</em>
                  </template>
                </v-select>
                <b-form-group v-else >
                  <b-form-input
                    v-model="inputBookingCode"
                    @change="getDetailBooking2ASearch()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-overlay
              :show="isLoading"
              :opacity="0.3"
              rounded="sm"
            >
              <DxDataGrid
                ref="tableRate"
                :height="432"
                class="table-rate"
                :show-borders="false"
                :data-source="dataDetail2A"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="false"
                :allow-column-resizing="false"
                column-resizing-mode="widget"
                :column-auto-width="true"
                @cellClick="cellClick"
              >
                <DxColumn
                  :caption="this.$t('golf_booking_play_info')"
                  :data-field="'name'"
                  :cell-template="'name'"
                  :header-cell-template="'headerName'"
                  :alignment="'left'"
                  :width="'30%'"
                />
                
                <DxColumn
                  :caption="'Rate'"
                  :data-field="'rate'"
                  :cell-template="'rate'"
                  :header-cell-template="'headerRate'"
                  :alignment="'center'"
                  :width="selectAllHole ? '10%' : '14%'"
                />
                <DxColumn
                  :caption="'TeeTime'"
                  :data-field="'teeTime'"
                  :cell-template="'teeTime'"
                  :header-cell-template="'headerTeeTime'"
                  :alignment="'center'"
                  :width="selectAllHole ? '10%' : '14%'"
                />
                <DxColumn
                  :caption="'Hole'"
                  :data-field="'hole'"
                  :cell-template="'hole'"
                  :header-cell-template="'headerHole'"
                  :alignment="'center'"
                  :width="selectAllHole ? '10%' : '14%'"
                />
                <DxColumn
                  :caption="$t('golf_member_member_sex')"
                  :data-field="'sex'"
                  :cell-template="'sex'"
                  :header-cell-template="'headerSex'"
                  :alignment="'center'"
                  :width="selectAllHole ? '10%' : '14%'"
                />
                <DxColumn
                  :caption="$t('golf_member_country')"
                  :data-field="'nation'"
                  :cell-template="'nation'"
                  :header-cell-template="'headerNation'"
                  :alignment="'center'"
                  :width="selectAllHole ? '10%' : '14%'"
                />
                <DxColumn
                  v-if="checKSelectLine"
                  :caption="'Line mới'"
                  :data-field="'Line'"
                  :cell-template="'Line'"
                  :header-cell-template="'headerLine'"
                  :alignment="'center'"
                  :width="'20%'"
                />
                <template #headerName="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                  </div>
                </template>

                <template #name="{ data }">
                  <div class="text-truncate" v-b-tooltip.hover :title="`${data.data.FullName } (${data.data.BookingDetailCode})`" >
                    {{ data.data.FullName }} ({{ data.data.BookingDetailCode }})
                  </div>
                </template>
                <template #headerLine="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                  </div>
                </template>

                <template #Line="{ data }">
                  <div class="text-truncate">
                    <span 
                      style="margin-left: 2px;border: 1px solid #114A9F;border-radius: 10%;" 
                      v-for="(item,index) in data.data.HoleNew" 
                      :key="index +'line'"
                    >
                      {{ listTee.find(x => x.Id === item).TeeName }}
                    </span> 
                  </div>
                </template>

                <template #headerRate="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                    <b-form-checkbox
                      v-model="selectAllRate"
                      style="margin-top: 0; margin-left: 7px;"
                    />
                  </div>
                </template>

                <template #rate="{ data }">
                  <div class="checkbox-center">
                    <b-form-checkbox
                      v-model="data.data.rate"
                    />
                  </div>
                </template>

                <template #headerHole="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                    <b-form-checkbox
                      v-model="selectAllHole"
                      style="margin-top: 0; margin-left: 7px;"
                    />
                  </div>
                </template>

                <template #hole="{ data }">
                  <div class="checkbox-center">
                    <b-form-checkbox
                      v-model="data.data.hole"
                      @input="changeCheckBox(data.data.hole)"
                    />
                  </div>
                </template>
                <template #headerTeeTime="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                    <b-form-checkbox
                      v-model="selectAllTeeTime"
                      style="margin-top: 0; margin-left: 7px;"
                    />
                  </div>
                </template>

                <template #teeTime="{ data }">
                  <div class="checkbox-center">
                    <b-form-checkbox
                      v-model="data.data.teeTime"
                    />
                  </div>
                </template>
                <template #headerSex="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                    <b-form-checkbox
                      v-model="selectAllSex"
                      style="margin-top: 0; margin-left: 7px;"
                    />
                  </div>
                </template>

                <template #sex="{ data }">
                  <div class="checkbox-center">
                    <b-form-checkbox
                      v-model="data.data.sex"
                    />
                  </div>
                </template>
                <template #headerNation="{ data }">
                  <div
                    class="d-flex"
                    style="line-height: 24px"
                  >
                    {{ data.column.caption }}
                    <b-form-checkbox
                      v-model="selectAllNation"
                      style="margin-top: 0; margin-left: 7px;"
                    />
                  </div>
                </template>

                <template #nation="{ data }">
                  <div class="checkbox-center">
                    <b-form-checkbox
                      v-model="data.data.nation"
                    />
                  </div>
                </template>
                <!-- Bật cố định column -->
                <DxColumnFixing :enabled="false" />

                <!-- Phân trang -->
                <DxPaging :page-size="100" />
                <DxSorting mode="none" />
              </DxDataGrid>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card-body>
      <template #modal-footer>
        <b-button
          size="md"
          variant="primary"
          @click="batchUpdateBooking()"
        >
        {{ $t('golf_common_confirm') }}
        </b-button>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :is-active="isOpen"
      :all-child-component-closed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
    <UpdateHoleCopy
      :listTee="listTee"
      :data="DataDetailProps"
      :dataDetail2B="data"
      @event="handEvent"
     />
  </div>

</template>

<script>
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { teeSheet } from '@/api/tee-sheet'
import { members } from '@/api/members'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxSorting,
  DxExport,
} from 'devextreme-vue/data-grid'
import UpdateHoleCopy from '@/views/modules/booking/modal/updateHoleCopy.vue'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import vSelect from 'vue-select'
import { golfClassSetting } from '@/api/golf-class-setting'
import { booking } from '@/api/booking'
import { starter } from '@/api/starter'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S,
} from '@/api/constant.js'

export default {
  name: 'SidebarUpdateBooking',
  components: {
    vSelect,
    ShortKey,
    UserAvatar,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxSorting,
    DxExport,
    UpdateHoleCopy
  },
  props: ['data', 'chooseDate'],
  data() {
    return {
      selectAllRate: false,
      selectAllHole: false,
      selectAllTeeTime: false,
      selectAllSex: false,
      selectAllNation: false,
      configTable: [
        {
          caption: this.$t('golf_booking_play_info'),
          field: 'name',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'name',
          headerCellTemplate: 'headerName',
          width: '30%',
        },
        {
          caption: this.$t('golf_booking_copy_new_line'),
          field: 'Line',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'Line',
          headerCellTemplate: 'headerLine',
          width: '20%',
        },
        {
          caption: 'Rate',
          field: 'rate',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'rate',
          headerCellTemplate: 'headerRate',
          width: '10%',
        },
        {
          caption: 'TeeTime',
          field: 'teeTime',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'teeTime',
          headerCellTemplate: 'headerTeeTime',
          width: '10%',
        },
        {
          caption: 'Hole',
          field: 'hole',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'hole',
          headerCellTemplate: 'headerHole',
          width: '10%',
        },
        {
          caption: this.$t('golf_member_sex'),
          field: 'hole',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'sex',
          headerCellTemplate: 'headerSex',
          width: '10%',
        },
        {
          caption: this.$t('golf_member_country'),
          field: 'hole',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'Nation',
          headerCellTemplate: 'headerNation',
          width: '10%',
        }
      ],
      openDate: '',
      golfClasses: {
        selected: null,
        list: [],
      },
      hole: {
        selected: 0,
        list: [],
      },
      company: {
        selected: '',
        list: [],
      },
      rates: {
        old: {
          selected: '',
          list: [],
        },
        new: {
          selected: '',
          list: [],
        },
      },
      keepOldRate: {
        value: false,
        reason: '',
      },
      getAnotherFee: {
        value: false,
        reason: '',
        amount: '',
      },
      listWeeklyCode: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      fields: [],
      items: [],
      selectedTeeTime: [],
      hovering: {
        indexTeeTime: '',
        indexPlayer: [],
      },
      additionalFee: {
        selected: null,
        list: [],
      },
      zoneId: [],
      teeTimeWasPicked: '',
      rateGolfClassRule: {
        cardCode: null,
        BDCList: [],
        BDC: null,
      },
      isLoadingRate: false,
      isLoading: false,
      isOpen: false,
      allChildComponentClosed: [],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S],
      listbdc: [],
      listSelectBy: [
        {
          id: 'bygroup',
          label: this.$t('golf_copy_by_group'),
        },
        {
          id: 'bybdc',
          label: this.$t('golf_copy_by_bdc'),
        },
      ],
      copyBy: 'bygroup',
      dataDetail2A: [],
      listTee: null,
      defaultLine: null,
      listTeeTimeCopy: null,
      DataDetailProps: null,
      inputBookingCode: null,
      checKSelectLine: false
    }
  },
  watch: {
    selectAllRate(value) {
      this.dataDetail2A.forEach(x => {
        x.rate = value
      })
    },
    selectAllHole(value) {
      this.checKSelectLine = value
      this.dataDetail2A.forEach(x => {
        x.hole = value
      })
      this.$forceUpdate()
    },
    selectAllTeeTime(value) {
      this.dataDetail2A.forEach(x => {
        x.teeTime = value
      })
    },
    selectAllSex(value) {
      this.dataDetail2A.forEach(x => {
        x.sex = value
      })
    },
    selectAllNation(value) {
      this.dataDetail2A.forEach(x => {
        x.nation = value
      })
    },
    data(value) {
      if (value.BookingDetail.Id) {
        // this.getDetailBooking2ASearch()
        this.inputBookingCode = this.data.BookingCode
        this.openDate = this.data.BookingDetail.OpenDate
        this.doubleRaf(async () => {
          await this.getGolfClass_RGC01()
          await this.getRateCodes_RRA04()
          await this.getListTeeTimeBooking_RTB01()
          await this.getConfigInfo_RCOM01()
          await this.getListServicesFee_RSV02()

          const bookingDetailCourse = this.data?.BookingDetail?.BookingDetailCourse
          if(bookingDetailCourse) {
            this.hole.list = bookingDetailCourse.map(item => item.NumberOfHole)
            this.hole.selected = bookingDetailCourse.map(item => item.NumberOfHole)[0]
          }
          this.company.selected = this.data.ClientName

          this.rateGolfClassRule.cardCode = this.data.BookingDetail.CardCode4GolfClassRule
          this.getListBDCByCardCode(false)
          this.rateGolfClassRule.BDC = this.data.BookingDetail.BDC4GolfClassRule
        })
      }
    },
    'data.BookingDetail.Id': async function (old) {
      // await this.getListTeeTime_API_RTT01()
      // await this.getDetailBooking2ASearch()
    },
    copyBy(val) {
      if (val == 'bybdc') {
        this.listbdc = []
      }
      this.getDetailBooking2ASearch()
    },
    listbdc(val) {
      if (val) {
        this.getDetailBooking2ASearch()
        setTimeout(() => {
          this.$refs.listBDC.$refs.search.focus()
        }, 10)
      }
    },
    'rates.new.selected': async function (newValue, _) {
      this.rateGolfClassRule.cardCode = null
      this.rateGolfClassRule.BDCList = []
      this.rateGolfClassRule.BDC = null
    },
    async isOpen(val) {
      if(val) {
        if(this.data.BookingDetail.Id) {
        await this.getListTeeTime_API_RTT01()
        await this.getDetailBooking2ASearch()
      }
      }
      // this.getDetailBooking2ASearch()
    },
  },
  methods: {
    chooseTee(tee) {
      if (tee.NumberPlayer > 0) {
        this.data.BookingDetail.TeeTimeId = tee.Id
      }
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideSideBar() {
      this.$root.$emit('bv::hide::modal', 'modal-update-booking')
    },
    handleCleanTeeTime(_teeTime) {
      let teeTimeRep = this.selectedTeeTime
      teeTimeRep = teeTimeRep.filter(item => item !== _teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      this.selectedTeeTime = teeTimeRep
      this.hovering = {
        indexTeeTime: '',
        indexPlayer: [],
      }
    },
    handleClickTeeTimeCell(_teeTime) {
      const teeTimeRep = this.selectedTeeTime
      teeTimeRep.push(_teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      this.selectedTeeTime = teeTimeRep
      this.teeTimeWasPicked = _teeTime
    },
    addPlayerToTee(tee, value, indexItems, indexTeeTime) {
      const players = this.hovering.indexPlayer
      const oldValue = this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer
      this.handleClickTeeTimeCell(tee)

      if ((oldValue === value && oldValue !== 0) || (oldValue > value)) {
        this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value - 1
        if (value - 1 === 0) {
          this.handleCleanTeeTime(tee)
        }
      } else {
        this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value
      }

      this.hovering.indexTeeTime = tee.Id
      if (players.includes(value)) {
        this.hovering.indexPlayer = players.filter(item => item !== value).filter(item => item < value)
        return
      }

      for (let i = 0; i < value; i++) {
        this.hovering.indexPlayer.push(i + 1)
      }
    },
    autoRang(start, end, step = 1, offset = 0) {
      const len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1
      const direction = start < end ? 1 : -1
      const startingPoint = start - (direction * (offset || 0))
      const stepSize = direction * (step || 1)

      return Array(len).fill(0).map((_, index) => startingPoint + (stepSize * index))
    },
    computeBookingDetailCourse(BookingDetailCourse) {
      return BookingDetailCourse.map(item => ({ Id: item.CourseId, NumberOfHole: item.NumberOfHole }))
    },
    async getGolfClass_RGC01() {
      const CourseId = this.data?.BookingDetail?.BookingDetailCourse.map(item => item.CourseId)
      const response = await golfClassSetting.getListGolfClass({ CourseId })
      if (response.Status === '200') {
        this.golfClasses.list = response?.Data?.GolfClass
        this.golfClasses.selected = response?.Data?.GolfClass.find(
          item => item.GolfClassName === this.data.BookingDetail.GolfClassName,
        )
      }
    },
    async changeDateTime() {
      await this.getRateCodes_RRA04(true)
      await this.getListTeeTimeBooking_RTB01()
    },
    async getRateCodes_RRA04(setNewRate) {
      this.isLoadingRate = true
      const weeklyCode = this.listWeeklyCode[new Date().getDay()]
      await booking.getRateCode({
        BookingDetailId: this.data?.BookingDetail?.Id,
        Date: this.openDate, // this.data.BookingDetail.OpenDate,
        Course: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse),
        // GolfClassId: this.data.BookingDetail.GolfClassId,
        GolfClassId: (this.golfClasses.selected.Id) ? this.golfClasses.selected.Id : null,
        WeeklyCode: String(weeklyCode),
        ClientId: (this.data.BookingDetail.ClientId) ? this.data.BookingDetail.ClientId : null,
        SourceId: (this.data.BookingDetail.SourceId) ? this.data.BookingDetail.SourceId : null,
      }).then(response => {
        if (response.Status === '200') {
          this.rates.new.list = response?.Data?.Rate
          if (setNewRate) {
            this.rates.new.selected = (response?.Data?.Rate[0]) ? response?.Data?.Rate[0] : null
          } else {
            this.rates.new.selected = response?.Data?.Rate.find(item => item.Id === this.data.BookingDetail.RateId)
          }
        }
        this.isLoadingRate = false
      })
    },
    async getListTeeTimeBooking_RTB01() {
      this.fields = []
      this.items = []
      const weeklyCode = this.listWeeklyCode[new Date().getDay()]

      const params = {
        Date: this.openDate, // this.data.BookingDetail.OpenDate,
        // GolfClassId: this.data.BookingDetail.GolfClassId,
        GolfClassId: (this.golfClasses.selected) ? this.golfClasses.selected.Id : null,
        RateId: (this.rates.new.selected) ? this.rates.new.selected.Id : null, // ) ? this.rates.new.selected.Id : this.data.BookingDetail.RateId, // this.data.BookingDetail.RateId,
        Course: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse),
        BookingDetailId: this.data.BookingDetail.Id,
        WeeklyCode: String(weeklyCode),
      }
      if (this.rates.new.selected.Id == -1) {
        return
      }
      const response = await booking.getListTeeTimeBooking(params)
      if (response.Status === '200') {
        const teeNames = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
        this.fields = ['TEETIME', ...teeNames]
        this.items = response.Data?.TeeSheet
      } else {
        this.showResToast(response)
      }
    },
    handEvent(result) {
      this.dataDetail2A[this.dataDetail2A.indexOf(x => x.Id === result.dataDetail.Id)] = result.dataDetail
      this.dataDetail2A[this.dataDetail2A.indexOf(x => x.Id === result.dataDetail.Id)].TeeId =  result.dataDetail.HoleNew[0]
      if(this.listTeeTimeCopy.TeeTime.find(teeTime => teeTime.TeeId === result.dataDetail.HoleNew[0])) {
        this.dataDetail2A[this.dataDetail2A.indexOf(x => x.Id === result.dataDetail.Id)].TeeTimeIdNew = this.listTeeTimeCopy.TeeTime.find(teeTime => teeTime.TeeId === result.dataDetail.HoleNew[0]).Id
      }
      this.$forceUpdate()
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
      }
    },
    async getListServicesFee_RSV02() {
      const response = await booking.getListServicesFee({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
        Type: 'BOOKING_TEETIME_CHARGE_FEE',
        BookingType: 'COURSE',
      })
      if (response.Status === '200') {
        this.additionalFee.list = response?.Data?.Menu.map(item => item.MenuDetail).flat()
        this.additionalFee.selected = response?.Data?.Menu.map(item => item.MenuDetail).flat()[0]
      }
    },
    async getListBDCByCardCode(isResetBDC) {
      const response = await booking.api_RBK10({
        Date: this.openDate,
        CardCode: this.rateGolfClassRule.cardCode,
      })
      if (response.Status === '200') {
        if (isResetBDC) {
          this.rateGolfClassRule.BDC = null
        }
        this.rateGolfClassRule.BDCList = response.Data.BDC
        if (this.rateGolfClassRule.BDCList.length > 0) {
          this.rateGolfClassRule.BDC = response.Data.BDC[0]
        } else {
          this.rateGolfClassRule.BDC = null
        }
      }
    },
    async batchUpdateBooking() {
      this.isLoading = true
      let success = false
      for (let i = 0; i < this.dataDetail2A.length; i++) {
        if(this.dataDetail2A[i].rate && !this.dataDetail2A[i].hole && !this.dataDetail2A[i].teeTime) {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              ClientId: this.data.BookingDetail.ClientId,
              SourceId: this.data.BookingDetail.SourceId,
              GolfClassId: this.data.BookingDetail.GolfClassId,
              TeeTimeId: this.dataDetail2A[i].TeeTimeId,
              RateId: this.data.BookingDetail.RateId,
              CardCode4GolfClassRule: null,
              Bdc4golfClassRule: null,
              BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole, ActualNumberOfHole: item.NumberOfHole}))
            },
          }
          await booking.updateTeeTimeForBooking(params).then(res2 => {
            if (res2.Status == '200') {
              success = true
            } else {
                        this.showResToast(res2)
                      }
          })
        } else if (!this.dataDetail2A[i].rate && this.dataDetail2A[i].hole && !this.dataDetail2A[i].teeTime) {
          const BookingDetailCourse = []
          this.dataDetail2A[i].BookingDetailCourse.forEach(y => {
            BookingDetailCourse.push({
              CourseId: y.CourseId,
              NumberOfHole: y.NumberOfHole,
              ActualNumberOfHole: y.NumberOfHole
            })
          })
          const body = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              Line: this.dataDetail2A[i].HoleNew,
              BookingDetailCourse,
            },
          }

          await starter
            .api_UBK02B(body)
            .then(res => {
              if (res) {
                if (res.Status == '200') {
                  success = true
                } else {
                        this.showResToast(res)
                      }
              }
            })
        } else if(!this.dataDetail2A[i].rate && !this.dataDetail2A[i].hole && this.dataDetail2A[i].teeTime) {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              ClientId: this.dataDetail2A[i].ClientId,
              SourceId: this.dataDetail2A[i].SourceId,
              GolfClassId: this.dataDetail2A[i].GolfClassId,
              TeeTimeId: this.dataDetail2A[i].TeeTimeIdNew,
              RateId: this.dataDetail2A[i].RateId,
              CardCode4GolfClassRule: this.dataDetail2A[i].CardCode4GolfClassRule,
              Bdc4golfClassRule: this.dataDetail2A[i].Bdc4golfClassRule,
              BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole, ActualNumberOfHole:  item.NumberOfHole}))

            },
          }
          await booking.updateTeeTimeForBooking(params).then(res2 => {
            if (res2.Status == '200') {
              success = true
            } else {
                        this.showResToast(res2)
                      }
          })
        } else if(this.dataDetail2A[i].rate && this.dataDetail2A[i].hole && !this.dataDetail2A[i].teeTime) {
          const BookingDetailCourse = []
          this.dataDetail2A[i].BookingDetailCourse.forEach(y => {
            BookingDetailCourse.push({
              CourseId: y.CourseId,
              NumberOfHole: y.NumberOfHole,
              ActualNumberOfHole: y.NumberOfHole
            })
          })
          const body = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              Line: this.dataDetail2A[i].HoleNew,
              BookingDetailCourse,
            },
          }

          await starter
            .api_UBK02B(body)
            .then(async res => {
              if (res) {
                if (res.Status == '200') {
                  success = true
                    const params = {
                      BookingDetail: {
                        Id: this.dataDetail2A[i].Id,
                        ClientId: this.data.BookingDetail.ClientId,
                        SourceId: this.data.BookingDetail.SourceId,
                        GolfClassId: this.data.BookingDetail.GolfClassId,
                        TeeTimeId: res.Data.TeeTimeId,
                        RateId: this.data.BookingDetail.RateId,
                        CardCode4GolfClassRule: null,
                        Bdc4golfClassRule: null,
                        BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole, ActualNumberOfHole: item.NumberOfHole }))
                      },
                    }
                    await booking.updateTeeTimeForBooking(params).then(res2 => {
                      if (res2.Status == '200') {
                        // success = true
                      } else {
                        this.showResToast(res2)
                      }
                    })
                } else {
                  this.showResToast(res)
                }
              }
            })
        } else if(this.dataDetail2A[i].rate && !this.dataDetail2A[i].hole && this.dataDetail2A[i].teeTime) {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              ClientId: this.data.BookingDetail.ClientId,
              SourceId: this.data.BookingDetail.SourceId,
              GolfClassId: this.data.BookingDetail.GolfClassId,
              TeeTimeId: this.dataDetail2A[i].TeeTimeIdNew,
              RateId: this.data.BookingDetail.RateId,
              CardCode4GolfClassRule: null,
              Bdc4golfClassRule: null,
              BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole,ActualNumberOfHole: item.NumberOfHole}))
            },
          }
          await booking.updateTeeTimeForBooking(params).then(res2 => {
            if (res2.Status == '200') {
              success = true
            } else {
              this.showResToast(res2)
            }
          })
        } else if(!this.dataDetail2A[i].rate && this.dataDetail2A[i].hole && this.dataDetail2A[i].teeTime) {
          const BookingDetailCourse = []
          this.dataDetail2A[i].BookingDetailCourse.forEach(y => {
            BookingDetailCourse.push({
              CourseId: y.CourseId,
              NumberOfHole: y.NumberOfHole,
              ActualNumberOfHole: y.NumberOfHole
            })
          })
          const body = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              Line: this.dataDetail2A[i].HoleNew,
              BookingDetailCourse,
            },
          }

          await starter
            .api_UBK02B(body)
            .then(async res => {
              if (res) {
                if (res.Status == '200') {
                  success = true
                    const params = {
                      BookingDetail: {
                        Id: this.dataDetail2A[i].Id,
                        ClientId: this.dataDetail2A[i].ClientId,
                        SourceId: this.dataDetail2A[i].SourceId,
                        GolfClassId: this.dataDetail2A[i].GolfClassId,
                        TeeTimeId: this.dataDetail2A[i].TeeTimeIdNew,
                        RateId: this.dataDetail2A[i].RateId,
                        CardCode4GolfClassRule: this.dataDetail2A[i].CardCode4GolfClassRule,
                        Bdc4golfClassRule: this.dataDetail2A[i].Bdc4golfClassRule,
                        BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole,ActualNumberOfHole: item.NumberOfHole}))
                      },
                    }
                    await booking.updateTeeTimeForBooking(params).then(res2 => {
                      if (res2.Status == '200') {
                        // success = true
                      } else {
                        this.showResToast(res2)
                      }
                    })
                } else {
                        this.showResToast(res)
                      }
              }
            })
        } else if(this.dataDetail2A[i].rate && this.dataDetail2A[i].hole && this.dataDetail2A[i].teeTime) {
          const BookingDetailCourse = []
          this.dataDetail2A[i].BookingDetailCourse.forEach(y => {
            BookingDetailCourse.push({
              CourseId: y.CourseId,
              NumberOfHole: y.NumberOfHole,
              ActualNumberOfHole: y.NumberOfHole
            })
          })
          const body = {
            BookingDetail: {
              Id: this.dataDetail2A[i].Id,
              Line: this.dataDetail2A[i].HoleNew,
              BookingDetailCourse,
            },
          }

          await starter
            .api_UBK02B(body)
            .then(async res => {
              if (res) {
                if (res.Status == '200') {
                  success = true
                    const params = {
                      BookingDetail: {
                        Id: this.dataDetail2A[i].Id,
                        ClientId: this.data.BookingDetail.ClientId,
                        SourceId: this.data.BookingDetail.SourceId,
                        GolfClassId: this.data.BookingDetail.GolfClassId,
                        TeeTimeId: this.dataDetail2A[i].TeeTimeIdNew,
                        RateId: this.data.BookingDetail.RateId,
                        CardCode4GolfClassRule: null,
                        Bdc4golfClassRule: null,
                        BookingDetailCourse: this.dataDetail2A[i].BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole,ActualNumberOfHole: item.NumberOfHole}))
                      },
                    }
                    await booking.updateTeeTimeForBooking(params).then(res2 => {
                      if (res2.Status == '200') {
                        // success = true
                      } else {
                        this.showResToast(res2)
                      }
                    })
                } else {
                  this.showResToast(res)
                }
              }
            })
        }
        if(this.data.BookingDetail.MemberId != -1 && this.dataDetail2A[i].MemberId != -1) {
          if(this.dataDetail2A[i].sex || this.dataDetail2A[i].nation) {
            const response = await members.detailMember({
              Member: {
                  Id: this.dataDetail2A[i].MemberId
              },
            })
            if(this.dataDetail2A[i].nation) {
              response.Data.Member.NationalityCode = this.data.BookingDetail.NationalityCode
            }
            if(this.dataDetail2A[i].sex) {
              response.Data.Member.SexCode = this.data.BookingDetail.SexCode
            }
            await members.updateMember({
              Member: response.Data.Member,
              BookingDetailId: this.dataDetail2A[i].Id,
            })
          }
        }
        if(this.dataDetail2A.length - 1 === i) {
          this.isLoading = false
          this.$emit('event', { type: 'after-update-hole' }) 
          this.showToast('success', this.$t('golf_common_success'))
        }
      }
      // this.dataDetail2A.forEach(async (x,index) => {
        
      // })
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.updateBooking_UBK02()
      }
      // if(payload.key === F4) {
      //   setTimeout(() => {
      //     this.hideSideBar()
      //   },500)
      // }
    },
    async getDetailBooking2ASearch() {
      this.selectAllRate = false
      this.selectAllHole = false
      this.selectAllTeeTime = false
      this.isLoading = true
      this.dataDetail2A = []
      const data = {
        KeyWord: null,
        BookingCode: (this.copyBy == 'bygroup') ? this.inputBookingCode : null,
        BookingId: null,
        GroupName: '',
        OpenDate: this.OpenDate,
        BDC: (this.copyBy == 'bybdc') ? this.listbdc : [],
      }

      const response = await booking.getDetailBooking2A(data)
      if (response.Status === '200') {
        response?.Data?.TeeTime.forEach(x => {
          if (x.Booking.length) {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                z.CourseId = this.listTee.find(tee => tee.Id === x.TeeId).CourseId
                z.rate = false
                z.hole = false
                z.teeTime = false
                z.sex = false
                z.nation = false
                z.TeeTimeId = x.Id
                z.TeeTimeIdNew = this.data.BookingDetail.TeeTimeId
                z.HoleNew = JSON.parse(JSON.stringify(this.defaultLine.find(dfLine => dfLine.CourseId === z.CourseId && dfLine.TeeStart === x.TeeId && dfLine.NumberOfHoles === this.data.BookingDetail.TotalNumberOfHole).Tee)) 
                z.TeeId = x.TeeId
                if (z.Id != this.data.BookingDetail.Id) {
                  this.dataDetail2A.push(z)
                }
                const BookingInfoCourse = []
                z.HoleNew.forEach(holenew => {
                  const courseTemp = this.listTee.find(course => course.Id === holenew)
                  if (BookingInfoCourse.filter(y => y.CourseId == courseTemp.CourseId).length == 0) {
                    BookingInfoCourse.push({
                      CourseId: courseTemp.CourseId,
                      NumberOfHole: 9,
                      CourseName: courseTemp.CourseName,
                    })
                  } else {
                    BookingInfoCourse.find(y => y.CourseId == courseTemp.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == courseTemp.CourseId).NumberOfHole + 9
                  }
                })
                z.BookingDetailCourse = JSON.parse(JSON.stringify(BookingInfoCourse))
              })
            })
          }
        })
      }
      this.isLoading = false
    },
    async getListTeeTime_API_RTT01() {
      const body = {
        OpenDate: this.openDate,
        SearchChooseDate: false,
      }
      const responseTeeTime = await teeSheet.getListTeeTime(body)
      this.listTeeTimeCopy = responseTeeTime.Data.TeeTimeInfo[0]
      this.listTee = responseTeeTime.Data.Tee
      this.defaultLine = responseTeeTime.Data.DefaultLine
    },
    async updateBooking_UBK02() {
      const params = {
        BookingDetail: {
          Id: this.data.BookingDetail.Id,
          ClientId: this.data.BookingDetail.ClientId,
          SourceId: this.data.BookingDetail.SourceId,
          GolfClassId: (this.golfClasses.selected.Id) ? this.golfClasses.selected.Id : null,
          TeeTimeId: this.data.BookingDetail.TeeTimeId,
          RateId: (this.rates.new.selected.Id) ? this.rates.new.selected.Id : null,
          CardCode4GolfClassRule: this.rateGolfClassRule.cardCode,
          Bdc4golfClassRule: this.rateGolfClassRule.BDC,
          BookingDetailServices: this.getAnotherFee.value ? bookingDetailServices : undefined,
          BookingDetailCourse: this.data.BookingDetail.BookingDetailCourse.map(item => ({CourseId: item.CourseId,  NumberOfHole: item.NumberOfHole,}))
        },
      }

      const response = await booking.updateTeeTimeForBooking(params)
      this.showResToast(response)
      if (response.Status == '200') {
        this.$nextTick(() => {
          this.$emit('event', { type: 'after-update-booking-tee-time', newTeeTimeId: this.data.BookingDetail.TeeTimeId })
          this.$bvModal.hide('modal-update-booking')
        })
      }
    },
    cellClick(data) {
      if (data.column.dataField == 'Line') {
        // Gán data để mở modal chọn số hố
        if(data.rowType != "header") {
          this.DataDetailProps = data.data
          this.$root.$emit('bv::show::modal', 'modal-line-course-copy')
        }
      }
    },
    changeCheckBox(value) {
      if(value) {
        this.checKSelectLine = value
      } else {
        const data = this.dataDetail2A.filter(x => x.hole)
        if(data.length > 0) {
          this.checKSelectLine = true
        } else {
          this.checKSelectLine = false
        }
      }
      this.$forceUpdate()
    }
  },
}
</script>

  <style scoped lang="scss">
  @import '@/assets/scss/variables/variables';
  #modal-update-booking{
    .selected-background-color{
      background: rgba(17, 74, 159, 0.04);;
    }
    .blocked-background-color{
      background: rgba(193, 193, 193, 0.12);
    }
  }
  .bg-linear-blue {
    background: $linear-blue;
  }
  .bg-primary {
    background-color: $primary;
  }
  .bg-light-gray {
    background-color: $light-grey;
  }
  .bg-grey {
    background-color: $grey;
  }
  .color-primary {
    color: $primary;
  }
  .color-gray {
    color: $light-grey;
  }
  .header-sidebar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $primary;
    }
  }

  .block {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 16px;
  }

  .player-info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  .wrapper {
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }

    .block-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 6px 0;
    }
  }
  .text-checkbox {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
  }
  ::v-deep {
    .vs__selected-options {
      flex-wrap: inherit !important;
      max-width: 100%;
      overflow: hidden;
      .vs__selected {
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
      }
    }
  }

  ::v-deep {
    [dir] .custom-checkbox {
      width: 30px;
      margin: 0 auto;
    }
    .b-table-sticky-header {
      overflow: auto;
      max-height: 429px;
      text-align: center;

    }

    tbody > tr > td {
      text-align: center;
    }

  }
  .scroll-area {
    position: relative;
    margin: auto;
    height: 50vh;
  }
  </style>
