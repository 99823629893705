<template>
    <div>
      <b-modal
        id="modal-verify-service"
        size="sm"
        centered
        hide-header
        :no-close-on-backdrop="true"
      >
        <b-card-header class="bg-linear-warning">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 :class="['mb-0',data.Type === 'Verify' ? 'text-warning' :'text-danger']">
                {{  data.Type === 'Verify' ? $t('modal_verify_service_verify_service_header') : $t('modal_verify_service_delete_service_header') }}
            </h4>
            <ez-icon
                icon="ezGolf-icon-x"
                class="cursor-pointer"
                size="24"
                :color="data.Type === 'Verify' ? '#FDB913' :'#EA5455'"
                @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
          <p v-if="data.Type === 'Verify'" class="mb-0">
            {{ $t('modal_verify_service_verify_service') }}
          </p>
          <p v-else class="mb-0">
            {{ $t('modal_verify_service_delete_service') }}
          </p>
        </b-card-body>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="none"
              size="md"
              class="float-right"
              @click="hideModal"
            >
              {{ $t('golf_common_back') }}
            </b-button>
            <b-button
              v-if="data.Type === 'Verify'"
              variant="warning"
              size="md"
              class="float-right mr-1"
              @click="VerifyService()"
            >
              {{ $t('golf_common_verify') }}
            </b-button>
            <b-button
              v-else
              variant="danger"
              size="md"
              class="float-right mr-1"
              @click="DeleteService()"
            >
              {{ $t('golf_common_delete') }}
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  import { booking } from '@/api/booking'
  
  export default {
    components: {},
    props: [
        'data'
    ],
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('modal-verify-service')
      },
      async DeleteService() {
        await booking.api_RPM01F(this.data.Body)
        .then(res => {
          this.showResToast(res)
          this.$emit('event', {
            type: 'after-delete-service'
          })
          this.hideModal()
        })
        
      },
      async VerifyService() {
        await booking.api_RPM01F(this.data.Body)
        .then(res => {
            const listServiceNoFolio = []
            res.Data.CheckExists.forEach(x => {
                if(!x.Exist) {
                  listServiceNoFolio.push(x.BookingDetailServiceId)
                }
            })
            this.$emit('event', {
                type: 'after-verify-service', 
                listBookingDetailServiceId: listServiceNoFolio
            })
          this.hideModal()
        })
      }
    },
  }
  
  </script>
  
  <style lang="scss">
  ::v-deep {
    .modal-content {
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      width: 400px;
    }
  }
  
  .color-red {
    color: red;
  }
  
  .color-warning {
    color: #114A9F;
  }
  
  .title-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #114A9F;
    line-height: 21px;
  }
  
  .card-body {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #5D5F65;
    }
  }
  
  .content-body-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  </style>
  