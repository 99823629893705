import vSelect from 'vue-select'
import SidebarUpdateBooking from '@/views/modules/booking/modal/UpdatePlayInfo.vue'
import CopyPlayInfo from '@/views/modules/booking/modal/CopyPlayInfo.vue'
import { booking } from '@/api/booking'
import { bookingPrint } from '@/api/booking-print'
import VueQrcode from 'vue-qrcode'
import { starter } from '@/api/starter'
import ExcelJS from "exceljs";
import readXlsxFile from 'read-excel-file';
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import InterestedCustomer from '@/views/modules/booking/detail/InterestedCustomer'
import SplitGroup from '@/views/modules/booking/modal/SplitGroup'
import Coupon from '@/views/modules/booking/modal/Coupon'
import UpdateGroup from '@/views/modules/booking/modal/UpdateGroup'
import CombineGroup from '@/views/modules/booking/modal/CombineGroup'
import ShortKey from '@/views/modules/ultils/ShortKey'
import UpdateHole from '@/views/modules/booking/modal/UpdateHole'
import UpdateRateCode from '@/views/modules/booking/modal/UpdateRateCode'
import UpdatePackage from '@/views/modules/booking/modal/UpdatePackage'
import UpdateCaddy from '@/views/modules/booking/modal/UpdateCaddy'
import CheckIn from '@/views/modules/booking/modal/CheckIn'
import InputChangeBDC from '@/views/modules/booking/modal/InputChangeBDC.vue'
import CancelBooking from '@/views/modules/booking/modal/CancelBooking.vue'
import CancelBookingGroup from '@/views/modules/booking/modal/CancelBookingGroup.vue'
import UndoCheckOut from '@/views/modules/booking/modal/UndoCheckOut.vue'
import SelectMemberToBooking from '@/views/modules/booking/modal/SelectMemberToBooking.vue'
import { convertUTC } from '@/@core/utils/utils'
import selectCardCode from '@/views/modules/booking/modal/selectCardCode.vue'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P, UP,DOWN,Key1,Key2,Key3,Key4,Key5,Key6,Key7,Key8,Key9,subKey1,subKey2,subKey3,subKey4,subKey5,subKey6,subKey7,subKey8,subKey9
} from '@/api/constant.js'
import SidebarContent from '@/views/modules/members/SidebarContent.vue'
import { member_params } from '@/views/modules/booking/params/member'
import { members } from '@/api/members'
import { commonServices } from '@/api/common-services'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import NoShow from '@/views/modules/booking/modal/Noshow.vue'
// import History from '@/views/modules/booking/modal/History'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import GolfService from '../AddGolfService/AddGolfService.vue'

export default {
  name: 'SidebarDetailBooking',
  components: {
    'confirm-dialog': ConfirmDialog,
    UpdateCaddy,
    UpdatePackage,
    UpdateGroup,
    CombineGroup,
    UpdateHole,
    UpdateRateCode,
    SidebarContent,
    SidebarUpdateBooking,
    CopyPlayInfo,
    UserAvatar,
    InterestedCustomer,
    SplitGroup,
    Coupon,
    CheckIn,
    NoShow,
    vSelect,
    GolfService,
    'delete-dialog': DeleteDialog,
    CancelBooking,
    CancelBookingGroup,
    ShortKey,
    VueQrcode,
    UndoCheckOut,
    selectCardCode,
    SelectMemberToBooking,
    InputChangeBDC
  },
  props: [
    'id',
    'chooseDate',
    'bookingDetailId',
    'bookingId',
    'teeTimeId',
    'bookingDetailprops',
    'KeyWordDataProps',
    'listUserOfSale',
    'listUserOfGM',
    'listUserOfCashier',
    'defaultPrintBDCinCheckIn',
    'defaultCheckIn',
    'quickViewListBdc',
    'listCourse',
    'TemBDCFormat'
  ],
  data() {
    return {
      selectSevice: '',
      mode: 'info',
      KeyWord: '',
      BookingCode: '',
      checkboxSelected: '',
      groupName: {
        selected: null,
        list: [],
      },
      dataDetail2A: [],
      dataDetail2B: {
        GroupName: '',
        ClientName: '',
        SourceId: '',
        BagtagCode: '',
        TotalNumberOfGolfer: 0,
        ClientId: '',
        TeeName: '',
        RepresentativeNote: '',
        BookingDetail: {
          FullName: '',
          FirstName: '',
          BookingCode: '',
          LastName: '',
          MemberTypeId: '',
          Email: '',
          PhoneNumber: '',
          GolfClassName: '',
          RateName: '',
          PackageName: '',
          CaddyName: '',
          CaddyId: '',
          MemberId: '',
          Avatar: '',
        },
      },
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      countries: [],
      companies: [],
      cardType: [],
      isOpenSidebarUpdateMember: false,
      isCreateMember: true,
      Member: member_params,
      checkboxSelectedAll: null,
      fields: ['age'],
      items: [{ age: 40, dataUser: 1 }],

      detailBookingId: null,
      detailBookingIds: [],
      bookingCode: [],
      caddy: [],
      bookingIdsChecked: [],
      // active: null,
      canShowAddService: false,
      checkedAll: false,
      isRemove: false,
      addMember: {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
        NationalityCode: null,
        Vgacode: null
      },
      listSex: [],
      currentLang: this.$i18n.locale,
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      listMember: [],
      isMultiple: true,
      groupNameSearch: null,
      OpenDate: this.getUTC('start', 'day'),
      isLoading: false,
      data2ACheckedForNoShow: [],
      data2ACheckedForCancel: [],
      data2ACheckedForSplitGroup: [],
      data2ACheckedForCheckIn: [],
      data2ACheckedForCheckOut: [],
      data2ABagtagCheckedForCheckOut: [],
      data2ABDCCheckedForCheckOut: [],
      data2ACheckedForPrint: [],
      listBDC: [],
      isOpen: false,
      sources: [],
      isLoading2A: false,
      listSale: [],
      allChildComponentClosed: [
        {
          ComponentId: 'modal-update-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-checkin',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-rate-code',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-hole',
          IsActive: false,
        },
        {
          ComponentId: 'modal-noshow-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-cancel-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-cancel-group-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-booking-info',
          IsActive: false,
        },
        {
          ComponentId: 'modal-combine-group',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-package',
          IsActive: false,
        },
        {
          ComponentId: 'modal-undo-checkout',
          IsActive: false,
        },
        {
          ComponentId: 'add-more-service-item',
          IsActive: false,
        },
        {
          ComponentId: 'member-sidebar',
          IsActive: false,
        },
        {
          ComponentId: 'modal-select-cardcode',
          IsActive: false,
        },
        {
          ComponentId: 'modal-input-member-to-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-change-bdc',
          IsActive: false,
        }
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P, UP,DOWN, Key1,Key2,Key3,Key4,Key5,Key6,Key7,Key8,Key9,subKey1,subKey2,subKey3,subKey4,subKey5,subKey6,subKey7,subKey8,subKey9],
      caddyOption: [],
      BookingDetailPrint: null,
      isLoadPrintBDC: false,
      ListRate_RRA04A: null,
      Selected_Rate_RRA04A: null,
      checkInputRRA04A: false,
      feeCaddy: (localStorage.getItem('isDefaultCaddyChargeFee') == '1'),
      feeCaddyConfig: null, // Thông tin giá, transaction code vv.. của phí chuyển caddy
      zoneId: [],
      NationalityCodeDefault: null,
      IsAutoAddSaler: null,
      isClearDataBookingDetail: true,
      IsRequriedSale: null,
      DefaultSearch: null,
      listLocker: [],
      listBagtag: [],
      IsAutoChangeCaddy: null,
      ColorBookingDetail: {
        CHECK_IN: null,
        CHECK_OUT: null,
        TEEOFF: null,
        FINISH: null,
        BOOKED: null,
        NO_SHOW: null,
        CANCELLED: null
      },
      TextStatus: {
        BOOKED: 'Booked',
        CHECK_IN: 'CheckIn',
        CHECK_OUT: 'CheckOut',
        CANCELLED: 'Cancelled',
        NO_SHOW: 'No-Show',
      },
      ListBookingStatus: [
        {
          label: 'Booked',
          value: 'BOOKED',
          check: true
        },
        {
          label: 'CheckIn',
          value: 'CHECK_IN',
          check: true
        },
        {
          label: 'CheckOut',
          value: 'CHECK_OUT',
          check: true
        },
        {
          label: 'No-Show',
          value: 'NO_SHOW',
          check: false
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
          check: false
        }
      ],
      isShowFilterBookingStatus: false,
      isManualCheckDupCaddie: null,
      isAllowDiscountOnRate: null,
      isModuleEmail: null,
      dataUndoCheckOut: {
        Id: null,
        Type: null
      },
      dataSelectCardCode: null,
      listWorkingCaddyStatus: null,
      listStatusDefault: null,
      TimeDelayScanBDC: null,
      manualSearchFromBDCBagtagNumber: null,
      isCheckAddtionalFeeFromRate: null,
      showActualNumberOfHole: null,
      listAdditionalType: null,
      dataInputMemberToBooking: null,
      isShowBagtagRBK02A: null,
      colorNote: null,
      backgroundColorNote: null,
      colorNoteGroup: null,
      backgroundColorNoteGroup: null,
      isChangeBDC: null,
      isShowNationSexCheckIn: null,
      IsPrintTemp: null
    }
  },
  watch: {
    bookingId(val) {
      if (val) {
        this.getDetailBooking2A()
      }
    },
    bookingDetailId(val) {
      // Nếu vào click từ màn teesheet, cần load hết các danh sách người chơi cùng tee
      // Nếu click vào detail2B từ kết quả tìm kiếm, thì không cần load danh sách người chơi cùng tee
      if (val) {
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode && !this.bookingId) {
          this.getDetailBooking2A()
        }
      }
    },
    listBDC() {
      setTimeout(() => {
        this.$refs.listBDC.$refs.search.focus()
      }, 10)
    },
    listBagtag() {
      setTimeout(() => {
        this.$refs.listBagtag.$refs.search.focus()
      }, 10)
    },
    listLocker() {
      setTimeout(() => {
        this.$refs.listLocker.$refs.search.focus()
      }, 10)
    },
    // quickViewListBdc(val) {
    //   console.log('ngon', this.quickViewListBdc)
    //   if (val) {
    //     this.listBDC = [val]
    //   }
    // },
    // KeyWord(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    OpenDate(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (val) {
          this.getDetailBooking2ASearch(true)
        } else {
          this.getDetailBooking2A()
        }
      }, 500)
    },
    chooseDate(value) {
      this.OpenDate = value
    },
    // groupNameSearch(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    // BookingCode(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    dataDetail2A: {
      handler(val) {
        const arr = []
        for (const tee of val) {
          for (const booking of tee.Booking) {
            for (const BookingDetail of booking.BookingDetail) {
              if (BookingDetail.checked) {
                arr.push(BookingDetail.Id)
              }
            }
          }
        }
        this.bookingIdsChecked = [...arr]
      },
      deep: true,
    },
    // addMember: {
    //   handler(val) {
    //     clearTimeout(this.timeout)
    //     this.timeout = setTimeout(() => {
    //       this.getListMember_RME01(val)
    //     }, 500)
    //   },
    //   deep: true,
    // },
    isRemove(val) {
      if (!val) {
        this.listMember = []
      }
    },
    isOpen(val) {
      if (val) {
        if (this.quickViewListBdc) {
          this.listBDC = [this.quickViewListBdc.toString()]
          this.getDetailBooking2ASearch(true)
        }
        setTimeout(() => {
          if(this.DefaultSearch === 'BAGTAG') {
            this.$refs.listBagtag.$refs.search.focus()
          } else if (this.DefaultSearch === 'BDC') {
            this.$refs.listBDC.$refs.search.focus()
          }
        }, 10)
      } else {
        const removeIframe = document.querySelectorAll('iframe')
        if (removeIframe.length > 0) {
          removeIframe.forEach(x => {
            document.body.removeChild(x)
          })
        }
        // reset lại list bdc khi đóng sidebar
        this.listBagtag = []
        this.listLocker = []
        this.listBDC = []
        this.KeyWord = null
        this.groupNameSearch = null
        this.BookingCode = null
        this.OpenDate = this.chooseDate
        // if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
      }
      this.$emit('is-open', { ComponentId: 'sidebar-detail-booking', IsActive: val, isClearDataBookingDetail: this.isClearDataBookingDetail })
    },
    isOpenSidebarUpdateMember(val) {
      this.SidebarOpenOrCloseHandler({ComponentId: "member-sidebar",IsActive:val})
    }
    // ,
    // isShowFilterBookingStatus(value) {
    //   if(!value) {
    //     this.changeFilterBookingStatus()
    //   }
    // }
  },
  async created() {
    await commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
    await this.getConfigInfo_RCOM01()
    await this.getListServicesFee_RSV02()
  },
  mounted() {
    // this.getListGroup_RBK06()
    this.getCountries_RNA01()
    // this.getCompanies_RCL01()
    // this.getListResources_RSO01()
    // this.getListCashier_RCS01()
  },
  methods: {
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listSale = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    openSidebarHistory() {
      this.$emit('event', { type: 'open-model-history', data: this.dataDetail2B })
      // if (!this.checkPermission('HRS01')) {
      //   return
      // }
      // this.$refs.History.open()
    },
    call_api_UBK04() {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          PackageId: this.dataDetail2B.BookingDetail.PackageId,
        },
        Type: 'DELETE',
      }
      booking.api_UBK04(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
        }
      })
    },

    async call_api_UBK07(ClientId, SourceId, changeClient) {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          ClientId,
          SourceId,
        },
      }
      await booking.api_UBK07(body).then(res => {
        // this.showResToast(res)
        if (res.Status === '200') {
          if (this.IsAutoAddSaler == '1' && ClientId && changeClient) {
            this.getSaleIdClient(ClientId)
          } else if (this.IsAutoAddSaler == '1' && !ClientId && changeClient && this.dataDetail2B.SaleId) {
            this.DeleteSaleClient(ClientId)
          }
          const params = {
            BookingDetail: {
              Id: this.dataDetail2B.BookingDetail.Id,
              RateId: res.Data.Rate[0].Id,
              Reasons: null,
              BookingDetailServices: res.Data.Rate[0].ServiceList,
            },
          }

          booking.updateRateCode(params).then(response => {
            this.showResToast(response)
            if (response.Status === '200') {
              this.$emit('event', { type: 'after-update-booking-rate-code' })
              this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
            }
          })
        } else {
          this.showResToast(res)
        }
      })
    },

    async getListMember_RME01() {
      if (this.addMember.FullName || this.addMember.CardCode || this.addMember.Email || this.addMember.PhoneNumber || this.addMember.Vgacode) {
        const requestData = {
          MemberName: (this.addMember.FullName ? this.addMember.FullName : ''),
          CardCode: this.addMember.CardCode,
          CardTypeId: null,
          MemberType: null,
          Email: this.addMember.Email,
          PhoneNumber: this.addMember.PhoneNumber,
          BookingDetailId: this.dataDetail2B.BookingDetail.Id,
          Vgacode: this.addMember.Vgacode
        }

        const response = await members.getListMember(requestData)
        if (response.Status === '200') {
          this.listMember = response?.Data?.Member
        }
      } else {
        this.listMember = []
      }
    },
    async addMemberToDetailBooking_UBK09(item, check) {
      let newCardCode = null
      let rateId = null
      let golfClassId = null
      if(!check) {
      // Khi nhập số thẻ
      // Tìm số thẻ phù hợp và trạng thái không bị truất quyền, nếu thấy thì kết quả phù hợp, không thông báo
      if (item.MemberSubscription.filter(x => x.Status == 'VALID' && x.CardCode && this.addMember.CardCode && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).length) {
        newCardCode = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Id
        golfClassId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).MemberCard.GolfClassId
        rateId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).MemberCard.RateId[0]
      } else {
        // Không phù hợp thì hiện thông báo, chỉ hiện thông báo khi có giá trị cardCode
        if (this.addMember.CardCode) {
          let error = ''
          if (item.MemberSubscription.filter(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).length) {
            if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'DEPOSITION') {
              error = this.$t('golf_booking_card_code_deposition')
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'EXPIRED') {
              error = this.$t('golf_booking_card_code_expired')
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'TURNOFF') {
              error = this.$t('golf_booking_card_code_turnoff')
            }
          }
          // this.showToast('error', `Mã thẻ ${this.addMember.CardCode} đã ${error} và sẽ được thay thế bằng thẻ khác nếu có.`)
          this.showToast('error', this.replaceString(this.replaceString(this.$t('golf_booking_error_select_card_code'),'{CardCode}',this.addMember.CardCode),'{error}',error))
        }

        // Chọn thẻ đầu tiên nếu có, nếu không có thẻ phù hợp
        if (item.MemberSubscription.filter(x => x.Status == 'VALID').length == 1) {
          newCardCode = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].Id
          golfClassId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.GolfClassId
          rateId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.RateId[0]
        } else if(item.MemberSubscription.filter(x => x.Status == 'VALID').length > 1){
          this.dataSelectCardCode = item
          this.dataSelectCardCode.dataCardCodeValid = item.MemberSubscription.filter(x => x.Status == 'VALID')
          this.dataSelectCardCode.CardCodeSelected = null
          this.$bvModal.show('modal-select-cardcode')
          return
        }
        }
      } else {
        newCardCode = item.CardCodeSelected.Id
        rateId = item.CardCodeSelected.MemberCard.RateId[0]
        golfClassId = item.CardCodeSelected.MemberCard.GolfClassId
      }
      

      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          MemberId: item.Id,
          MemberSubscriptionId: newCardCode,
        },
        IsForce: false,
      }
      await booking.api_UBK09(body).then(async res => {
        if (res.Status == 200) {
          // Nếu member đã được add
          if (res.Messages.length > 0) {
            const dataConfirm = {
              dataConfirm: {
                title: this.$t('golf_booking_confirm_add_member'),
                content: res.Messages[0].MessageText,
                body: {
                  BookingDetail: {
                    Id: this.dataDetail2B.BookingDetail.Id,
                    MemberId: item.Id,
                    MemberSubscriptionId: newCardCode,
                  },
                  IsForce: true,
                },
                golfClassId,
                rateId,
              },
            }
            // Hiển thị xác nhận add member đã có
            this.$refs.confirmDialogAddMemberToDetailBoooking.open(dataConfirm)
          } else {
            await this.updateBooking_UBK02(golfClassId, rateId)
            this.showResToast(res)
            this.listMember = []
            this.isRemove = false
            this.addMember = {
              FullName: null,
              FirstName: null,
              LastName: null,
              PhoneNumber: null,
              Email: null,
              SexCode: 'SEX_M',
              CardCode: null,
              NationalityCode: this.NationalityCodeDefault,
            }
            // this.getDetailBooking2A()
            // Nếu đã từng vào detail id hay vào từ màn quét bdc
            if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
                this.getDetailBooking2A()
            } else {
                this.getDetailBooking2ASearch()
            }
            this.$emit('event', { type: 'after-add-member-to-detail-booking' })
          }
        } else if (res.Status == 400) {
          if(res.Messages.length > 0) {
            if (res.Messages[0].Code == "E.UBK09.C13") {
              const dataConfirm = {
                dataConfirm: {
                  title: this.$t('golf_booking_confirm_add_member'),
                  content: this.$t('golf_booking_confirm_add_member_to_tour'),
                  body: {
                    BookingDetail: {
                      Id: this.dataDetail2B.BookingDetail.Id,
                      MemberId: item.Id,
                      MemberSubscriptionId: newCardCode,
                    },
                    IsForce: true,
                    IsForceUpdateTour: true
                  },
                  golfClassId,
                  rateId,
                },
              }
              this.$refs.confirmDialogAddMemberToDetailBoooking.open(dataConfirm)
            }
          }
          this.showResToast(res)
        }
      })
    },
    async updateBooking_UBK02(golfClassId, rateId) {
      // Nếu là member, có MemberSubscriptionId
      if (this.dataDetail2B.BookingDetail.MemberSubscriptionId) {
        const params = {
          BookingDetail: {
            Id: this.dataDetail2B.BookingDetail.Id,
            ClientId: this.dataDetail2B.BookingDetail.ClientId,
            SourceId: this.dataDetail2B.BookingDetail.SourceId,
            // GolfClassId: golfClassId,
            TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
            // RateId: rateId,
            CardCode4GolfClassRule: null,
            Bdc4golfClassRule: null,
            BookingDetailCourse: this.dataDetail2B.BookingDetail.BookingDetailCourse.map(x => ({CourseId: x.CourseId , NumberOfHole: x.NumberOfHole, ActualNumberOfHole: x.ActualNumberOfHole}))
          },
        }
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          params.BookingDetail.GolfClassId = golfClassId
          params.BookingDetail.RateId = rateId
        } else {
        // Nếu không có rate id và golf class id
          params.BookingDetail.GolfClassId = this.dataDetail2B.BookingDetail.GolfClassId
          params.BookingDetail.RateId = -1
        }
        await booking.updateTeeTimeForBooking(params)
        if (!golfClassId || !rateId) {
          this.showToast('error', this.$t('golf_common_please_select_golfclass'))
        }
        this.$emit('event', { type: 'after-change-rate-golf-class-from-select-add-new-member' })
      } else {
        // Nếu là Guest
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2B.BookingDetail.Id,
              ClientId: this.dataDetail2B.BookingDetail.ClientId,
              SourceId: this.dataDetail2B.BookingDetail.SourceId,
              GolfClassId: golfClassId,
              TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
              RateId: rateId,
              CardCode4GolfClassRule: null,
              Bdc4golfClassRule: null,
              BookingDetailCourse: this.dataDetail2B.BookingDetail.BookingDetailCourse.map(x => ({CourseId: x.CourseId , NumberOfHole: x.NumberOfHole, ActualNumberOfHole: x.ActualNumberOfHole}))
            },
          }
          await booking.updateTeeTimeForBooking(params)
          this.$emit('event', { type: 'after-change-rate-golf-class-from-select-add-new-member' })
        }
      }
    },

    confirmAddMemberToDetailBooking_UBK09(dataConfirm) {
      // const body = {
      //   BookingDetail: {
      //     Id: this.dataDetail2B.BookingDetail.Id,
      //     MemberId: item.Id,
      //     MemberSubscriptionId: (item.MemberSubscription[0]) ? item.MemberSubscription[0].Id : null,
      //   },
      // }
      booking.api_UBK09(dataConfirm.dataConfirm.body).then(async res => {
        this.showResToast(res)
        this.listMember = []
        this.isRemove = false
        this.addMember = {
          FullName: null,
          FirstName: null,
          LastName: null,
          PhoneNumber: null,
          Email: null,
          SexCode: 'SEX_M',
          CardCode: null,
          NationalityCode: this.NationalityCodeDefault,
        }
        if (res.Status == 200) {
          await this.updateBooking_UBK02(dataConfirm.dataConfirm.golfClassId, dataConfirm.dataConfirm.rateId)

          // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
          if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              this.getDetailBooking2A()
          } else {
              this.getDetailBooking2ASearch()
          }
          this.$emit('event', { type: 'after-add-member-to-detail-booking' })
        }
      })
    },
    async removeMemberCard() {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          MemberId: this.dataDetail2B.BookingDetail.MemberId,
          MemberSubscriptionId: null,
        },
        IsForce: true,
      }
      await booking.api_UBK09(body).then(res => {
        this.showResToast(res)
        if (res.Status == 200) {
          // Nếu đã từng vào detail id hay vào từ màn quét bdc
          if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
            setTimeout(() => {
              this.getDetailBooking2A()
            }, 500)
          } else {
            setTimeout(() => {
              this.getDetailBooking2ASearch()
            }, 500)
          }
          this.$emit('event', { type: 'after-add-member-to-detail-booking' })
        }
      })
    },
    cancelAddMember() {
      this.listMember = []
      this.isRemove = false
      this.addMember = {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
        NationalityCode: this.NationalityCodeDefault,
      }
    },
    addNewMemberToDetailBooking_IME01() {
      const body = {
        BookingDetailId: this.dataDetail2B.BookingDetail.Id,
        Member: {
          FullName: this.addMember.FullName,
          FirstName: this.addMember.FirstName,
          LastName: this.addMember.LastName,
          CardCode: this.addMember.CardCode,
          SexCode: this.addMember.SexCode,
          PhoneNumber: this.addMember.PhoneNumber,
          Email: this.addMember.Email,
          NationalityCode: this.addMember.NationalityCode,
        },
      }
      members.createMember(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.listMember = []
          this.isRemove = false
          this.addMember = {
            FullName: null,
            FirstName: null,
            LastName: null,
            PhoneNumber: null,
            Email: null,
            SexCode: 'SEX_M',
            CardCode: null,
            NationalityCode: this.NationalityCodeDefault,
          }
          this.updateBooking_UBK02(null, null)
          if (res.Status == 200) {
            if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
              setTimeout(() => {
                this.getDetailBooking2A()
              }, 500)
            } else {
              setTimeout(() => {
                this.getDetailBooking2ASearch()
              }, 500)
            }
            this.$emit('event', { type: 'after-add-new-member-to-detail-booking' })
          }
        } else if (res.Status == 400) {
          if(res.Messages.length > 0) {
            if (res.Messages[0].Code == "E.UBK09.C13" || res.Messages[0].Code == "E.E.UBK09.C13.") {
              const dataConfirm = {
                dataConfirm: {
                  title: this.$t('golf_booking_confirm_add_member'),
                  content: this.$t('golf_booking_confirm_add_member_to_tour'),
                  body: {
                    BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                    Member: {
                      FullName: this.addMember.FullName,
                      FirstName: this.addMember.FirstName,
                      LastName: this.addMember.LastName,
                      CardCode: this.addMember.CardCode,
                      SexCode: this.addMember.SexCode,
                      PhoneNumber: this.addMember.PhoneNumber,
                      Email: this.addMember.Email,
                      NationalityCode: this.addMember.NationalityCode,
                    },
                    IsForceUpdateTour: true
                  }
                },
              }
              this.$refs.confirmDialogAddNewMemberToDetailBoookingTour.open(dataConfirm)
            }
          }
          this.showResToast(res)
        }
      })
    },
    ConfirmaddNewMemberToDetailBooking_IME01(dataConfirm) {
      members.createMember(dataConfirm.dataConfirm.body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.listMember = []
          this.isRemove = false
          this.addMember = {
            FullName: null,
            FirstName: null,
            LastName: null,
            PhoneNumber: null,
            Email: null,
            SexCode: 'SEX_M',
            CardCode: null,
            NationalityCode: this.NationalityCodeDefault,
          }
          this.updateBooking_UBK02(null, null)
          if (res.Status == 200) {
            if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
              setTimeout(() => {
                this.getDetailBooking2A()
              }, 500)
            } else {
              setTimeout(() => {
                this.getDetailBooking2ASearch()
              }, 500)
            }
            this.$emit('event', { type: 'after-add-new-member-to-detail-booking' })
          }
        }
      })
    },
    async createBdcCode() {
      const body = {
        BookngDetailId: [this.dataDetail2B.BookingDetail.Id],
      }
      await booking.api_UBK15(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
            setTimeout(() => {
              this.getDetailBooking2A()
            }, 500)
          } else {
            setTimeout(() => {
              this.getDetailBooking2ASearch()
            }, 500)
          }
        }
      })
    },
    Preview() {
      const data2ACheckedForPrint = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            // only get checkin data
            if (z.checked) {
              data2ACheckedForPrint.push(z.Id)
            }
          })
        })
      })

      if (!data2ACheckedForPrint.length) {
        return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
      }

      if (data2ACheckedForPrint.length > 0) {
        const routeData = this.$router.resolve({
          name: 'bookingPrint',
          query: {
            id: data2ACheckedForPrint,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },
    openSidebarCheckout(fromDetail = true) {
      this.data2ACheckedForCheckOut = []
      this.data2ABagtagCheckedForCheckOut = []
      this.data2ABDCCheckedForCheckOut = []
      if (fromDetail) {
        this.data2ACheckedForCheckOut.push(this.dataDetail2B.BookingDetail.Id)
      } else {
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                this.data2ACheckedForCheckOut.push(z.Id)
                if(this.DefaultSearch === 'BAGTAG') {
                  if(z.BagtagCode) {
                    this.data2ABagtagCheckedForCheckOut.push(z.BagtagCode)
                  }
                } else if (this.DefaultSearch === 'BDC') {
                  if(z.BookingDetailCode) {
                    this.data2ABDCCheckedForCheckOut.push(z.BookingDetailCode)
                  }
                }
              }
            })
          })
        })
      }

      if (!this.data2ACheckedForCheckOut.length) {
        return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
      }
      this.isClearDataBookingDetail = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      this.$emit('event', {
        type: 'open-sidebar-checkout',
        bookingDetailData: { bookingDetailId: this.data2ACheckedForCheckOut },
        bookingDetailprops: { Id: this.bookingDetailId, TeeTimeId: this.teeTimeId },
        KeyWordDataProps: {
          KeyWord: this.KeyWord, BookingCode: this.BookingCode, BookingId: this.id ? !this.id.TeeTimeId ? this.id.Id : this.groupName.selected : this.groupName.selected, GroupName: this.groupNameSearch, OpenDate: this.OpenDate, BDC: this.listBDC,Bagtag:this.listBagtag,Locker:this.listLocker
        },
      })
    },
    handleAddService() {
      this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      this.canShowAddService = true
      this.$root.$emit('bv::toggle::collapse', 'add-service-item', { dataDetail2B: this.dataDetail2B, detailBookingId: this.detailBookingId })
    },
    async updateNoteAndLockerBooking_UBK10() {
      const params = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          LockerCode: this.dataDetail2B.BookingDetail.LockerCode,
          BagTagCode: this.dataDetail2B.BookingDetail.BagtagCode,
          Note: this.dataDetail2B.BookingDetail.Note,
        },
      }
      const response = await booking.updateNoteAndLockerForBooking(params)
      this.showResToast(response)
    },
    // async getListGroup_RBK06() {
    //   const response = await booking.api_RBK06({})
    //   if (response.Status === '200') {
    //     this.groupName.list = response?.Data?.Booking
    //   }
    // },
    async getDetailBooking2A() {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false
      const bookingDetailData = {
        Id: this.bookingDetailId,
        TeeTimeId: this.teeTimeId,
      }

      const data = {
        KeyWord: this.KeyWord,
        BookingCode: this.BookingCode,
        BookingDetail: !this.bookingId ? bookingDetailData : null,
        BookingId: this.bookingId,
        BookingDetailStatus: this.ListBookingStatus.filter(x => x.check == true).map(x => x.value)
      }

      const response = await booking.getDetailBooking2A(data)
      this.isLoading2A = false
      if (response.Status === '200') {
        this.dataDetail2A = response?.Data?.TeeTime
        if (this.dataDetail2A) {
          this.bookingCode = []
          this.dataDetail2A.forEach((item, index) => {
            if (item) {
              item.Booking.forEach((item1, index1) => {
                this.bookingCode.push(item1.BookingCode)
              })
            }
          })
        }
        if(this.dataDetail2A.length === 0) {
          this.clearData()
          return
        }
        // Có bookingId thì active người đầu tiên, có bookingDetailId thì active người đó.
        // Có tồn tại bookingDetailId và không tồn bookingId ( View từ màn tee sheet)
        // if (this.bookingDetailId && !this.bookingId) {

        if (this.bookingDetailId) {
          await this.getDetailBooking2B(this.bookingDetailId)
          return
        }

        // Sau khi add new ( có bookingId ), chọn người đầu tiên để active
        if (this.bookingId && this.dataDetail2A[0] && this.dataDetail2A[0].Booking[0] && this.dataDetail2A[0].Booking[0].BookingDetail[0]) {
          // Check điều kiện chỉ thực hiện gán lần đầu tiên, nếu không sẽ luôn active người đầu tiên, không phải người hiện tại
          if (!this.bookingDetailId) {
            this.setActiveUser({ Id: this.dataDetail2A[0].Booking[0].BookingDetail[0].Id })
          }
        }
      }
    },
    async getDetailBooking2ASearch(isActiveLast, isSearchBDC,isSearchBagtag,isSearchLocker) {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false
      const data = {
        KeyWord: this.KeyWord,
        BookingCode: this.BookingCode,
        BookingId: this.id ? !this.id.TeeTimeId ? this.id.Id : this.groupName.selected : this.groupName.selected,
        GroupName: this.groupNameSearch,
        OpenDate: this.OpenDate,
        BDC: this.listBDC,
        Bagtag: this.listBagtag,
        Locker: this.listLocker,
        BookingDetailStatus: this.ListBookingStatus.filter(x => x.check == true).map(x => x.value)
      }

      const response = await booking.getDetailBooking2A(data)
      if (response.Status === '200') {
        if(response.Messages.length > 0) {
          this.showResToast(response)
        }
        this.dataDetail2A = response?.Data?.TeeTime
        if (this.dataDetail2A) {
          this.bookingCode = []
          this.dataDetail2A.forEach((item, index) => {
            if (item) {
              item.Booking.forEach((item1, index1) => {
                this.bookingCode.push(item1.BookingCode)
              })
            }
          })
        }

        // if (reloadList) {
        //   this.$emit('event', { type: 'reload_list' })
        // }

        // if (this.bookingDetailId) {
        //   await this.getDetailBooking2B(this.bookingDetailId)
        //   return
        // }

        // if (this.bookingId && this.dataDetail2A[0] && this.dataDetail2A[0].Booking[0] && this.dataDetail2A[0].Booking[0].BookingDetail[0]) {
        //   const bookingDetailId = this.dataDetail2A[0].Booking[0].BookingDetail[0].Id
        //   this.bookingDetailId = bookingDetailId
        //   await this.getDetailBooking2B(bookingDetailId)
        // }

        // Có bookingId thì active người đầu tiên, có bookingDetailId thì active người đó.
        // Có tồn tại bookingDetailId và không tồn bookingId ( View từ màn tee sheet)
        // if (this.bookingDetailId && !this.bookingId) {
        //   await this.getDetailBooking2B(this.bookingDetailId)
        //   return
        // }

        // Load lại 2 trường hợp, khi tìm kiếm trực tiếp bdc thì active người cuối, nếu không phải tìm kiếm trực tiếp thì active người hiện tại
        // Sau khi add new( có bookingId ), chọn người cuối cùng để active
        if (this.dataDetail2A.length) {
          const lastBoookingDetail = JSON.parse(JSON.stringify(this.dataDetail2A))
          if (isActiveLast && isSearchBDC && !isSearchBagtag && !isSearchLocker) {
            let BDCCheckDefault = null
            let checkBDC = false
            let listBDCTemp = JSON.parse(JSON.stringify(this.listBDC))
            if(listBDCTemp.length > 0) {
              if (listBDCTemp[listBDCTemp.length - 1].length === 4) {
                BDCCheckDefault = listBDCTemp[listBDCTemp.length - 1]
              } else if (listBDCTemp[listBDCTemp.length - 1].length === 9) {
                BDCCheckDefault = listBDCTemp[listBDCTemp.length - 1].slice(5, 9)
              }
              if (BDCCheckDefault) {
                for (const tee of this.dataDetail2A) {
                  for (const booking of tee.Booking) {
                    for (const bookingDetail of booking.BookingDetail) {
                      if (bookingDetail.BookingDetailCode.slice(5, 9) === BDCCheckDefault) {
                        this.setActiveUser({ Id: bookingDetail.Id })
                        checkBDC = true
                        break
                      }
                    }
                  }
                }
              }
              if (!checkBDC) {
                this.setActiveUser({ Id: lastBoookingDetail[0].Booking[0].BookingDetail[0].Id })
              }
            } else {
              this.clearData()
            }
          } else if (isActiveLast && !isSearchBDC && isSearchBagtag && !isSearchLocker) {
            let checkBagtag = false
            let listBagtagTemp = JSON.parse(JSON.stringify(this.listBagtag))
            if(listBagtagTemp.length > 0) {
              for (const tee of this.dataDetail2A) {
                for (const booking of tee.Booking) {
                  for (const bookingDetail of booking.BookingDetail) {
                    if (bookingDetail.BagtagCode === listBagtagTemp[listBagtagTemp.length - 1]) {
                      this.setActiveUser({ Id: bookingDetail.Id })
                      checkBagtag = true
                      break
                    }
                  }
                  if(checkBagtag) {
                    break
                  }
                }
                if(checkBagtag) {
                  break
                }
              }
              if (!checkBagtag) {
                this.setActiveUser({ Id: lastBoookingDetail[0].Booking[0].BookingDetail[0].Id })
              }
            } else {
              this.clearData()
            }
           
          } else if (isActiveLast && !isSearchBDC && !isSearchBagtag && isSearchLocker) {
            let checkLocker = false
            let listLockerTemp = JSON.parse(JSON.stringify(this.listLocker))
            if(listLockerTemp.length > 0) {
              for (const tee of this.dataDetail2A) {
                for (const booking of tee.Booking) {
                  for (const bookingDetail of booking.BookingDetail) {
                    if (bookingDetail.LockerCode === listLockerTemp[listLockerTemp.length - 1]) {
                      this.setActiveUser({ Id: bookingDetail.Id })
                      checkLocker = true
                      break
                    }
                  }
                  if(checkLocker) {
                    break
                  }
                }
                if(checkLocker) {
                  break
                }
              }
              if (!checkLocker) {
                this.setActiveUser({ Id: lastBoookingDetail[0].Booking[0].BookingDetail[0].Id })
              }
            } else {
              this.clearData()
            }
            
          } else if (isActiveLast && !isSearchBDC && !isSearchBagtag && !isSearchLocker) {
            this.setActiveUser({ Id: lastBoookingDetail[0].Booking[0].BookingDetail[0].Id })
          } else {
            this.setActiveUser({ Id: this.bookingDetailId })
          }
        } else {
          this.clearData()
        }
      } 
      this.isLoading2A = false
      if(isSearchBDC) {
        setTimeout(() => {
          this.$refs.listBDC.$refs.search.focus()
        },100)
      } else if (isSearchBagtag) {
        setTimeout(() => {
          this.$refs.listBagtag.$refs.search.focus()
        },100)
      } else if (isSearchLocker) {
        setTimeout(() => {
          this.$refs.listLocker.$refs.search.focus()
        },100)
      }
    },
    async getDetailBooking2B(Id) {
      this.Selected_Rate_RRA04A = null
      this.checkedAll = false
      this.checkAll(this.checkedAll)
      this.isLoading = true
      const resBooking2B = await booking.getDetailBooking2B({ BookingDetail: { Id } })
      if (resBooking2B.Status === '200') {
        for (const tee of this.dataDetail2A) {
          for (const booking of tee.Booking) {
            for (const BookingDetail of booking.BookingDetail) {
              if (BookingDetail.Id === Id) {
                BookingDetail.checked = true
                break
              }
            }
          }
        }
        this.dataDetail2B = resBooking2B?.Data?.Booking
        this.getListRate_RRA04A()
        this.getListResources_RSO01(this.dataDetail2B.BookingDetail.OpenDateTime)
        this.getCompanies_RCL01(this.dataDetail2B.BookingDetail.OpenDateTime)
        this.getListCaddy_RCA04()
        if (this.dataDetail2B.BookingDetail.MemberId === -1) {
          this.isRemove = true
        } else {
          this.isRemove = false
        }
      } else {
        this.dataDetail2B = null
      }
      this.isLoading = false
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.TimeDelayScanBDC = Number(response?.Data.find(x => x.KeyCode === 'BOOKING_SCAN_BDC_DELAY_TIME').KeyValue)
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
        this.NationalityCodeDefault = response?.Data.find(x => x.KeyCode === 'CUSTOMER_NATIONALITY_DEFAULT').KeyValue
        this.listWorkingCaddyStatus = response?.Data.find(x => x.KeyCode === 'BOOKING_CADDY_STATUS_ALLOW_BOOKING').KeyValue
        this.IsAutoAddSaler = response?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_ADD_SALER').KeyValue
        this.isChangeBDC = response?.Data.find(x => x.KeyCode === 'BOOKING_ALLOW_CHANGE_BDC') ? response?.Data.find(x => x.KeyCode === 'BOOKING_ALLOW_CHANGE_BDC').KeyValue : null
        this.IsRequriedSale = response?.Data.find(x => x.KeyCode === 'BOOKING_SALE_REQUIRED').KeyValue
        this.IsPrintTemp = response?.Data.find(x => x.KeyCode === 'TEMP_CONFIG_BDC') ? response?.Data.find(x => x.KeyCode === 'TEMP_CONFIG_BDC').KeyValue : null
        this.isShowNationSexCheckIn = response?.Data.find(item => item.KeyCode === 'BOOKING_CHECK_IN_SHOW_NATIONALITY_SEX').KeyValue
        this.isShowBagtagRBK02A = response?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_BAGTAG_ON_RBK02A').KeyValue
        this.isCheckAddtionalFeeFromRate = response?.Data.find(x => x.KeyCode === 'BOOKING_GET_ADDITIONAL_FEE_FROM_RATE').KeyValue
        this.manualSearchFromBDCBagtagNumber = Number(response?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_SEARCH_FROM_BDC_BAGTAG_NUMBERS').KeyValue)
        this.DefaultSearch = response?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
        this.showActualNumberOfHole = response?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_ACTUAL_NUMBER_OF_HOLE').KeyValue
        this.isAllowDiscountOnRate = response?.Data.find(x => x.KeyCode === 'BOOKING_ALLOW_DISCOUNT_ON_RATE').KeyValue
        this.IsAutoChangeCaddy = response?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_UPDATE_HOLE_OF_CADDIE').KeyValue 
        this.isManualCheckDupCaddie = response?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_CHECK_DUP_CADDIE').KeyValue
        this.isModuleEmail = response?.Data.find(x => x.KeyCode === 'BOOKING_ALLOW_SENDING_EMAIL').KeyValue
        this.backgroundColorNote = response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_BACKGROUND_COLOR') ? response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_BACKGROUND_COLOR').KeyValue : null
        this.colorNote = response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_COLOR') ? response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_COLOR').KeyValue : null
        this.backgroundColorNoteGroup = response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_BACKGROUND_COLOR') ? response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_BACKGROUND_COLOR').KeyValue : null
        this.colorNoteGroup = response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_COLOR') ? response?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_COLOR').KeyValue : null
        this.listStatusDefault = JSON.parse(response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_SHOW_IN_BOOKING_DETAIL').KeyValue)
        this.addMember.NationalityCode = this.NationalityCodeDefault
        this.ColorBookingDetail = {
          CHECK_IN: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CHECK_IN_COLOR').KeyValue,
          CHECK_OUT: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CHECK_OUT_COLOR').KeyValue,
          TEEOFF: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_TEEOFF_COLOR').KeyValue,
          FINISH: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_FINISH_COLOR').KeyValue,
          BOOKED: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_BOOKED_COLOR').KeyValue,
          CANCELLED: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CANCELLED_COLOR').KeyValue,
          NO_SHOW: response?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_NO_SHOW_COLOR').KeyValue
        }
        this.ListBookingStatus.forEach(x => {
          if(this.listStatusDefault.find(y => y == x.value)) {
            x.check = true
          }
        })
        this.listAdditionalType = []
        const listAdditionalTypetemp = response?.Data.find(x => x.KeyCode === 'ADDITIONALFEE_ODD_HOLE_TYPE').KeyValue.split(',')
        listAdditionalTypetemp.forEach(x => {
          this.listAdditionalType.push({
            Code: x, 
            Name: this.$t(x.toLocaleLowerCase())
          })
        })
      }
    },
    async getListServicesFee_RSV02() {
      const response = await booking.getListServicesFee({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
        Type: 'BOOKING_CADDY_CHARGE_FEE',
        BookingType: 'COURSE',
      })
      if (response.Status === '200') {
        // this.additionalFee.list = response?.Data?.Menu.map(item => item.MenuDetail).flat()
        this.feeCaddyConfig = response?.Data?.Menu.map(item => item.MenuDetail).flat()[0]
        if (response?.Data?.Menu.length > 0) {
          this.feeCaddy = (localStorage.getItem('isDefaultCaddyChargeFee') == '1')
        }
      }
    },
    handleOpenModal(key, id) {
      this.checkPermissionOpenModal(key, id)
    },
    async getListRate_RRA04A() {
      const listCourse = []
      this.dataDetail2B.BookingDetail.BookingDetailCourse.forEach(x => {
        listCourse.push({
          Id: x.CourseId,
          NumberOfHole: x.NumberOfHole,
        })
      })
      const body = {
        Date: this.dataDetail2B.BookingDetail.OpenDate,
        GolfClassId: this.dataDetail2B.BookingDetail.GolfClassId,
        Course: listCourse,
        WeeklyCode: this.weekLyCode,
      }
      await booking.api_RRA04A(body).then(res => {
        this.ListRate_RRA04A = res.Data.Rate
      })
    },
    handleEvent(event) {
      // Chuyển sang màn thu ngân
      if (event.type === 'open-sidebar-checkout') {
        // Đóng sidebar mua dịch vụ
        this.$root.$emit('bv::toggle::collapse', 'add-service-item')
        // Mở sidebar thu ngân
        this.openSidebarCheckout(true)
      }
      // save_success là sự kiện sau khi update thông tin thành viên thành công
      // Sau khi checkin thành công
      if (event.type === 'after-checkin' || event.type === 'after-checkout' || event.type === 'after-cancel-booking' || event.type === 'after-cancel-booking-group' || event.type === 'after-add-member-to-detail-booking' || event.type === 'after-update-booking-package' || event.type === 'after-update-booking-rate-code' || event.type === 'save_success' || event.type === 'after-no-show' || event.type === 'after-add-caddy' || event.type === 'after-update-add-caddy' || event.type === 'after-update-hole' || event.type === 'after-input-member' || event.type === 'after-change-bdc') {
        // 2A đã bao gồm gọi 2B
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }

      // Sau khi đổi tee time, cập nhật thông tin chơi
      if (event.type === 'after-update-booking-tee-time') {
        this.teeTimeId = event.newTeeTimeId
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }
      // Sau khi hủy, gửi email cancel
      if (event.type === 'cancel_email') {
        this.openConfirmEmail('CANCEL')
      }
      // sau khi undo check out
      if (event.type === 'after-undo-checkout') {
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
        this.$emit('event', { type: 'after-undo-checkout' })
      }
      // Sau khi tách đoàn và gộp đoàn, đổi thông tin booking
      if (event.type === 'after-combine-group' || event.type === 'after-split-group' || event.type === 'after-update-group-info') {
        // 2A đã bao gồm gọi 2B
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }

      // Chuyển tiếp sự kiện ra ngoài com cha
      if (event.type != 'open-sidebar-checkout') {
        this.$emit('event', event)
      }
      if(event.type == 'after_select_cardcode') {
        this.addMemberToDetailBooking_UBK09(event.data, true)
        this.dataSelectCardCode = null

      }
    },
    changeModeTo(mode) {
      this.mode = mode
    },
    hideSideBar() {
      // this.teeTimeId = null
      // this.bookingDetailId = null
      // this.bookingId = null
      // this.bookingDetailprops = null
      // this.KeyWordDataProps = null
      // this.clearData()
      this.isClearDataBookingDetail = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
    },
    openModal(key, id) {
      this.checkPermissionOpenModal(key, id)
    },
    addMoreCustomer() {
      this.isClearDataBookingDetail = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      this.$emit('event', { type: 'add-more-customer-from-detail-booking', dataDetail2B: this.dataDetail2B })
    },
    addNewBooking() {
      this.isClearDataBookingDetail = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      this.$emit('event', { type: 'add-new-booking-from-detail-booking' })
    },
    async openCaddyModal() {
      this.openModal('RCAG01', 'modal-update-caddy')
    },
    async getListCaddy_RCA04() {
      const data_request = {
        BookingDetailId: this.dataDetail2B.BookingDetail.Id,
        CaddyWorkingStatus: this.listWorkingCaddyStatus.split(',')
      }
      const response = await booking.getListCaddy(data_request)
      if (response.Status === '200') {
        this.caddy = response.Data.Caddy

        this.caddyOption = response.Data.Caddy.map(e => Object.assign(e, { label: `${e.CaddyCode} - ${e.CaddyName}` }))
      }
    },
    async addCaddyToBooking(ischeck) {
      if(this.isCheckAddtionalFeeFromRate == 1) {
        const res = await this.getServiceAddtional(this.dataDetail2B.BookingDetail.Id, 'ADDITIONALFEE_BOOK_CADDY')
        if(res.Status == 200) {
          if(res.Data.RateAdditionalType[0].Service.length > 0) {
            var payload = {
              BookingDetailCaddy: {
                Id: null,
                BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                CaddyId: Number(this.dataDetail2B.BookingDetail.CaddyId),
                NumberOfHole: this.dataDetail2B.BookingDetail.TotalNumberOfHole,
                WorkingStatus: 'B',
                Reasons: '',
                IsDeleted: false,
                IsBooked: true,
                BookingDetailServices: [
                  {
                    BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                    ServiceId: Number(!this.feeCaddy ? 0 : res.Data.RateAdditionalType[0].Service[0].ServiceId),
                    UnitPrice: Number(!this.feeCaddy ? 0 : res.Data.RateAdditionalType[0].Service[0].Amount),
                    Quantity: Number(!this.feeCaddy ? 0 : 1),
                    Amount: Number(!this.feeCaddy ? 0 : res.Data.RateAdditionalType[0].Service[0].Amount),
                    TotalAmount: Number(!this.feeCaddy ? 0 : res.Data.RateAdditionalType[0].Service[0].Amount),
                    TransactionCode: String(!this.feeCaddy ? '' : res.Data.RateAdditionalType[0].Service[0].TransactionCode),
                    RateAdditionalId: res.Data.RateAdditionalType[0].Service[0].RateAdditionalId,
                    RateAdditionalType: res.Data.RateAdditionalType[0].Service[0].RateAdditionalType,
                    RetailBy: 'RECEPTIONIST'
                  },
                ],
              },
            }
          } else {
            var payload = {
              BookingDetailCaddy: {
                Id: null,
                BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                CaddyId: Number(this.dataDetail2B.BookingDetail.CaddyId),
                NumberOfHole: this.dataDetail2B.BookingDetail.TotalNumberOfHole,
                WorkingStatus: 'B',
                Reasons: '',
                IsDeleted: false,
                IsBooked: true,
                BookingDetailServices: [
                  {
                    BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                    ServiceId: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductId),
                    UnitPrice: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                    Quantity: Number(!this.feeCaddy ? 0 : 1),
                    Amount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                    TotalAmount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                    TransactionCode: String(!this.feeCaddy ? '' : this.feeCaddyConfig.TransactionCode.TransactionCode),
                    RetailBy: 'RECEPTIONIST'
                  },
                ],
              },
            }
          }
        } else {
          var payload = {
            BookingDetailCaddy: {
              Id: null,
              BookingDetailId: this.dataDetail2B.BookingDetail.Id,
              CaddyId: Number(this.dataDetail2B.BookingDetail.CaddyId),
              NumberOfHole: this.dataDetail2B.BookingDetail.TotalNumberOfHole,
              WorkingStatus: 'B',
              Reasons: '',
              IsDeleted: false,
              IsBooked: true,
              BookingDetailServices: [
                {
                  BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                  ServiceId: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductId),
                  UnitPrice: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                  Quantity: Number(!this.feeCaddy ? 0 : 1),
                  Amount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                  TotalAmount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                  TransactionCode: String(!this.feeCaddy ? '' : this.feeCaddyConfig.TransactionCode.TransactionCode),
                  RetailBy: 'RECEPTIONIST'
                },
              ],
            },
          }
        }
      } else {
        var payload = {
          BookingDetailCaddy: {
            Id: null,
            BookingDetailId: this.dataDetail2B.BookingDetail.Id,
            CaddyId: Number(this.dataDetail2B.BookingDetail.CaddyId),
            NumberOfHole: this.dataDetail2B.BookingDetail.TotalNumberOfHole,
            WorkingStatus: 'B',
            Reasons: '',
            IsDeleted: false,
            IsBooked: true,
            BookingDetailServices: [
              {
                BookingDetailId: this.dataDetail2B.BookingDetail.Id,
                ServiceId: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductId),
                UnitPrice: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                Quantity: Number(!this.feeCaddy ? 0 : 1),
                Amount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                TotalAmount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
                TransactionCode: String(!this.feeCaddy ? '' : this.feeCaddyConfig.TransactionCode.TransactionCode),
                RetailBy: 'RECEPTIONIST'
              },
            ],
          },
        }
      }
      if(ischeck) {
        payload.IsForceUpdateCaddie = true
      }
      await booking.updateCaddy(payload).then(response => {
          this.showResToast(response)
        if (response.Status === '200') {
          this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
          this.$emit('event', { type: 'after-add-caddy' })
        } else if(response.Status === '400' && response.Messages[0].Code === "E.UBK05.C07") {
          const dataConfirm = {
            dataConfirm: {
              title:  this.$t('golf_booking_update_caddy'),
              content: this.$t('golf_check_update_dup_caddy'),
              body: {
                data: [this.dataDetail2B],
              },
            },
          }
          this.$refs.confirmUpdateDupCaddy.open(dataConfirm)
        } else {
          this.dataDetail2B.BookingDetail.CaddyId = null
        }
      })
    },
    async getServiceAddtional(bkId, type) {
       const response =  await booking.api_RBK15({
        BookingDetailId: bkId, AdditionalFeeType: [type]
      })
      if(response.Status == 400) {
        this.showResToast(response)
        }
      return response
    },
    openModalCombineGroup() {
      this.$root.$emit('bv::show::modal', 'modal-combine-group')
    },
    openConfirmEmail(e) {
      const routeData = this.$router.resolve({ name: 'confirmEmail', params: { id: this.bookingDetailId, type: e } })
      window.open(routeData.href, '_blank')
    },
    openModalCopyBooking() {
      this.$root.$emit('bv::show::modal', 'modal-copy-booking')
    },
    openModalSplitGroup(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      } else {
        const data2ACheckedForSplitGroup = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                data2ACheckedForSplitGroup.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForSplitGroup.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForSplitGroup = Object.assign([], data2ACheckedForSplitGroup)
      }

      this.$root.$emit('bv::show::modal', 'modal-split-group')
    },
    openModalNoShow(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      } else {
        const data2ACheckedForNoShow = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                data2ACheckedForNoShow.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForNoShow.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForNoShow = Object.assign([], data2ACheckedForNoShow)
      }

      this.$root.$emit('bv::show::modal', 'modal-noshow-booking')
    },
    openModalCancelBooking(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
        this.data2ACheckedForCancel = []
      } else {
        const data2ACheckedForCancel = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              // only get checkin data
              if (z.checked) {
                data2ACheckedForCancel.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForCancel.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForCancel = Object.assign([], data2ACheckedForCancel)
        this.detailBookingId = null
      }

      this.$root.$emit('bv::show::modal', 'modal-cancel-booking')
    },
    openModalCancelBookingGroup() {
      this.$root.$emit('bv::show::modal', 'modal-cancel-group-booking')
    },
    openModalUpdateBookingInfo() {
      this.$root.$emit('bv::show::modal', 'modal-update-booking-info')
    },
    async openModalCheckIn(fromDetail = true) {
      if (!this.checkPermission('ICI01')) {
        return
      }
      const data2ACheckedForCheckIn = []
      if (!fromDetail) {
        this.isLoading2A = true
        var isSale = true
        for (const [index, tee] of this.dataDetail2A.entries()) {
          for (const bookingData of tee.Booking) {
            for (const BookingDetail of bookingData.BookingDetail) {
              // only get checkin data
              if (!BookingDetail.checked || BookingDetail.BookingDetailStatus !== 'BOOKED' || BookingDetail.BookingDetailStatus == 'CANCELLED' || BookingDetail.BookingDetailStatus == 'NO_SHOW') {
                continue
              }

              const resBooking2B = await booking.getDetailBooking2B({ BookingDetail: { Id: BookingDetail.Id } })
              if (resBooking2B.Status === '200') {
                if(!resBooking2B.Data.Booking.SaleId && this.IsRequriedSale == 1) {
                  isSale = false
                  break
                }
                if (!data2ACheckedForCheckIn[index]) {
                  data2ACheckedForCheckIn[index] = {
                    OpenDate: tee.OpenDate,
                    GroupName: tee.Booking[0].GroupName,
                    StartTime: tee.StartTime,
                    TeeName: tee.TeeName,
                    Members: [],
                    Checked: false,
                  }
                }

                const dataDetail2B = resBooking2B?.Data?.Booking.BookingDetail
                data2ACheckedForCheckIn[index].Members.push({
                  BookingDetail,
                  CaddyName: dataDetail2B.CaddyName,
                  CaddyCode: dataDetail2B.CaddyCode,
                  CaddyId: dataDetail2B.CaddyId,
                  BagtagCode: dataDetail2B.BagtagCode,
                  LockerCode: dataDetail2B.LockerCode,
                  BDC: dataDetail2B.BDC,
                  Checked: false,
                  NationalityCode: dataDetail2B.NationalityCode, 
                  SexCode: dataDetail2B.SexCode
                })
              }
            }
          }
        }
        this.isLoading2A = false
        if(!isSale && this.IsRequriedSale == 1) {
          return this.showToast('error', this.$t('golf_booking_require_sale_befor_checkin'))
        }
        if (!data2ACheckedForCheckIn.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_one_person_checkin'))
        }
      } else if (this.dataDetail2B.BookingDetail.BookingDetailStatus === 'BOOKED') {
        if(!this.dataDetail2B.SaleId && this.IsRequriedSale == 1) {
          return this.showToast('error', this.$t('golf_booking_require_sale_befor_checkin'))
        }
        for (const tee of this.dataDetail2A) {
          for (const booking of tee.Booking) {
            for (const BookingDetail of booking.BookingDetail) {
              if (BookingDetail.Id === this.dataDetail2B.BookingDetail.Id) {
                data2ACheckedForCheckIn.push({
                  OpenDate: tee.OpenDate,
                  GroupName: tee.Booking[0].GroupName,
                  StartTime: tee.StartTime,
                  TeeName: tee.TeeName,
                  Members: [
                    {
                      BookingDetail,
                      CaddyName: this.dataDetail2B.BookingDetail.CaddyName,
                      CaddyCode: this.dataDetail2B.BookingDetail.CaddyCode,
                      CaddyId: this.dataDetail2B.BookingDetail.CaddyId,
                      BagtagCode: this.dataDetail2B.BookingDetail.BagtagCode,
                      LockerCode: this.dataDetail2B.BookingDetail.LockerCode,
                      BDC: this.dataDetail2B.BookingDetail.BDC,
                      Checked: false,
                    },
                  ],
                  Checked: false,
                })
                break
              }
            }
          }
        }
        //   const data = {
        //     BookingId: this.dataDetail2B.Id,
        //   }
        //   const response = await booking.getDetailBooking2A(data)
        //   if (response.Status === '200' && response.Data.TeeTime[0]) {
        //     const bookingData = response.Data.TeeTime[0]
        //     data2ACheckedForCheckIn.push({
        //       OpenDate: bookingData.OpenDate,
        //       GroupName: bookingData.Booking[0].GroupName,
        //       StartTime: bookingData.StartTime,
        //       TeeName: bookingData.TeeName,
        //       Members: [{
        //         BookingDetail: this.dataDetail2B.BookingDetail,
        //         CaddyName: this.dataDetail2B.BookingDetail.CaddyName,
        //         CaddyCode: this.dataDetail2B.BookingDetail.CaddyCode,
        //         CaddyId: this.dataDetail2B.BookingDetail.CaddyId,
        //         BagtagCode: this.dataDetail2B.BookingDetail.BagtagCode,
        //         LockerCode: this.dataDetail2B.BookingDetail.LockerCode,
        //         BDC: this.dataDetail2B.BookingDetail.BDC
        //       }],
        //     })
        // }
      } else {
        return this.showToast('error', this.$t('golf_booking_please_select_one_person_checkin'))
      }

      // Loại bỏ item rỗng
      this.data2ACheckedForCheckIn = Object.assign([], data2ACheckedForCheckIn.filter(x => x))
      this.$root.$emit('bv::show::modal', 'modal-checkin')
    },
    setActiveUser(bookingDetail) {
      this.bookingDetailId = bookingDetail.Id
      // Cập nhật lại bookingDetailId cho prop để khi load lại ko bị mất trạng thái active người hiện tại trong 2A
      // Không cập nhật prop nếu như đang tìm kiếm, nếu không sẽ lỗi khi xem lại chính detail booking đó sau khi tìm kiếm
      // if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
      this.$emit('event', { type: 'update-current-booking-detail-id', bookingDetailId: this.bookingDetailId })
      // }
      this.getDetailBooking2B(bookingDetail.Id)
    },
    openModalDistributionForSingle() {
      this.bookingDetailId = this.dataDetail2B.BookingDetail.Id
      // Cập nhật phân bổ cho 1 người
      this.$emit('event', { type: 'open-modal-distribution-single', bookingDetailIdDistribution: this.dataDetail2B.BookingDetail.Id })
    },
    getStatusText(data) {
      if(data.BookingDetailStatus === 'CHECK_IN' && data.TeeOff && !data.Finish) {
        return 'TeeOff'
      } else if (data.BookingDetailStatus === 'CHECK_IN' && data.TeeOff && data.Finish) {
        return 'Finish'
      } else {
        return this.TextStatus[data.BookingDetailStatus] 
      }
    },

    checkAll(value) {
      for (const tee of this.dataDetail2A) {
        tee.checked = value
        this.checkAllDate(tee)
      }
    },

    async showMemberForm() {
      if (!this.checkPermission('UME01')) {
        return
      }
      if (this.dataDetail2B.BookingDetail.MemberId === -1) {
        this.isCreateMember = true
      } else {
        await this.getDetailMember_RME02(this.dataDetail2B.BookingDetail.MemberId)
        this.isCreateMember = false
      }

      this.detailBookingId = this.dataDetail2B.BookingDetail.Id

      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },

    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries = response.Data.Country
      }
    },

    async getCompanies_RCL01(OpenDateTime) {
      const response = await members.getCompanies({ OpenDateTime })
      if (response) {
        this.companies = response.Data.Client
      }
    },
    async getListResources_RSO01(OpenDateTime) {
      const response = await booking.getListResources({ OpenDateTime })

      if (response.Status === '200') {
        this.sources = response?.Data?.Source
        // this.source.selected = response?.Data?.Source[0]
      }
    },

    async getDetailMember_RME02(id) {
      this.isCreate = false
      const response = await members.detailMember({
        Member: {
          Id: id,
        },
      })
      if (response.Status === '200') {
        this.Member = response?.Data?.Member
      }
    },

    checkAllDate(value) {
      this.dataDetail2A.forEach(x => {
        if (x.OpenDate === value.OpenDate) {
          if (value.checked) {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                if (z.BookingDetailStatus === 'CANCELLED' || z.BookingDetailStatus === 'NO_SHOW') {
                  z.checked = false
                } else {
                  z.checked = true
                }
              })
            })
          } else {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                z.checked = false
              })
            })
          }
        }
      })
      this.$forceUpdate()
    },
    checkItem() {
      let ischeckAll = true
      for (const tee of this.dataDetail2A) {
        tee.checked = true
        for (const booking of tee.Booking) {
          for (const BookingDetail of booking.BookingDetail) {
            if (!BookingDetail.checked) {
              tee.checked = false
              if (this.checkedAll) {
                this.checkedAll = false
              }
              return
            }
          }
        }
        if (!tee.checked) {
          ischeckAll = false
        }
      }
      if (ischeckAll) {
        this.checkedAll = true
      }
    },
    openConfirmEmailGroup(e) {
      if (e == 'BOOKED') {
        const routeData = this.$router.resolve({ name: 'confirmEmailGroupAll', params: { BookingCode: this.dataDetail2B.BookingCode }, query: { BookingDetailId: this.dataDetail2B.BookingDetail.Id } })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({ name: 'confirmEmailGroupAll', params: { BookingCode: this.dataDetail2B.BookingCode }, query: { BookingDetailId: null } })
        window.open(routeData.href, '_blank')
      }
    },
    AfterCloseSidebarCheckout() {
      if (this.KeyWordDataProps.BDC.length > 0 || this.KeyWordDataProps.Bagtag.length > 0 || this.KeyWordDataProps.Locker.length > 0 || this.KeyWordDataProps.BookingCode || this.KeyWordDataProps.BookingId || this.KeyWordDataProps.GroupName || this.KeyWordDataProps.KeyWord) {
        this.KeyWord = this.KeyWordDataProps.KeyWord
        this.BookingCode = this.KeyWordDataProps.BookingCode
        this.groupNameSearch = this.KeyWordDataProps.GroupName
        this.OpenDate = this.KeyWordDataProps.OpenDate
        this.listBDC = this.KeyWordDataProps.BDC
        this.listBagtag = this.KeyWordDataProps.Bagtag
        this.listLocker = this.KeyWordDataProps.Locker
        this.getDetailBooking2ASearch()
      } else {
        this.bookingDetailId = this.bookingDetailprops.Id
        this.teeTimeId = this.bookingDetailprops.TeeTimeId
        this.getDetailBooking2A()
      }
    },
    async getDetailBooking2ABookingList() {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false

      const data = {
        KeyWord: null,
        BookingCode: this.BookingCode,
        BookingId: null,
        GroupName: null,
        OpenDate: this.OpenDate,
        BDC: [],
      }

      const response = await booking.getDetailBooking2A(data)
      this.isLoading2A = false
      if (response.Status === '200') {
        this.dataDetail2A = response?.Data?.TeeTime
        await this.getDetailBooking2B(this.bookingDetailId)
      }
    },
    openDetailBookingList() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      this.BookingCode = this.KeyWordDataProps.BookingCode
      this.OpenDate = this.KeyWordDataProps.OpenDate
      this.bookingDetailId = this.KeyWordDataProps.BookingDetailId
      this.BookingId = this.KeyWordDataProps.BookingId
      this.getDetailBooking2ABookingList()
    },
    undoCheckout(check) {
      if(check) {
        const listBkdId = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked === true && z.BookingDetailStatus == 'CHECK_OUT') {
                listBkdId.push(z.Id)
              }
            })
          })
        })
        if(listBkdId.length > 0) {
          this.dataUndoCheckOut.Id = listBkdId
          this.dataUndoCheckOut.Type = 'All' 
          this.$root.$emit('bv::show::modal', 'modal-undo-checkout')
        }
        
      } else {
        this.dataUndoCheckOut.Type = 'Single' 
        this.dataUndoCheckOut.Id = this.dataDetail2B.BookingDetail.Id
        this.$root.$emit('bv::show::modal', 'modal-undo-checkout')
      }
    },
    async confirmUndoCheckout_ICI03(dataConfirm) {
      const response = await booking.api_ICI03(dataConfirm.dataConfirm.body)
      this.showResToast(response)
      if (response.Status == 200) {
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
        this.$emit('event', { type: 'after-undo-checkout' })
      }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    clearKeyWord(key) {
      if(key == 'BDC') {
        if(this.listBDC.length > 0) {
          this.listBDC = []
          if (this.KeyWord || this.groupNameSearch || this.BookingCode) {
            this.getDetailBooking2ASearch()
          } else {
            this.clearData()
          }
        }
      } else if (key == 'Locker') {
        if(this.listLocker.length > 0) {
          this.listLocker = []
          if (this.KeyWord || this.groupNameSearch || this.BookingCode) {
            this.getDetailBooking2ASearch()
          } else {
            this.clearData()
          }
        }
      } else if (key == 'Bagtag') {
        if(this.listBagtag.length > 0) {
          this.listBagtag = []
          if (this.KeyWord || this.groupNameSearch || this.BookingCode) {
            this.getDetailBooking2ASearch()
          } else {
            this.clearData()
          }
        }
      }
    },
    clearData() {
      
      this.dataDetail2A = [],
      this.dataDetail2B = {
        GroupName: '',
        ClientName: '',
        SourceId: '',
        BagtagCode: '',
        TotalNumberOfGolfer: 0,
        ClientId: '',
        TeeName: '',
        RepresentativeNote: '',
        BookingDetail: {
          FullName: '',
          FirstName: '',
          BookingCode: '',
          LastName: '',
          MemberTypeId: '',
          Email: '',
          PhoneNumber: '',
          GolfClassName: '',
          RateName: '',
          PackageName: '',
          CaddyName: '',
          CaddyId: '',
          MemberId: '',
          Avatar: '',
        },
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === P) {
        this.FrintBDCinBookingDetail()
      }
      if (payload.evt.ctrlKey && payload.key === A) {
        if (this.dataDetail2A.length > 0) {
          this.checkedAll = !this.checkedAll
          this.checkAll(this.checkedAll)
        }
      }
      if (payload.evt.ctrlKey && payload.key === F) {
        if(this.DefaultSearch === 'BAGTAG') {
          this.$refs.listBagtag.$refs.search.focus()
        } else if (this.DefaultSearch === 'BDC') {
          this.$refs.listBDC.$refs.search.focus()
        }
      }
      if (payload.key === F1) {
        if(this.isRemove && this.listMember.length > 0) {
          this.addMemberToDetailBooking_UBK09(this.listMember[0])
        } else {
          this.isRemove = !this.isRemove
        }
      }
      if (payload.key === F2) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 1) {
            this.addMemberToDetailBooking_UBK09(this.listMember[1])
          }
        } else {
          const listChekcin = []
          this.dataDetail2A.forEach(x => {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                if (z.checked === true) {
                  listChekcin.push(z.Id)
                }
              })
            })
          })
          if (this.dataDetail2B.Id) {
            if (this.checkedAll || listChekcin.length > 0) {
              this.openModalCheckIn(false)
            } else {
              this.openModalCheckIn(true)
            }
          }
        }
      }
      if (payload.key === F3) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 2) {
            this.addMemberToDetailBooking_UBK09(this.listMember[2])
          }
        } else {
          const listCheckout = []
          this.dataDetail2A.forEach(x => {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                if (z.checked === true) {
                  listCheckout.push(z.Id)
                }
              })
            })
          })
          if (this.dataDetail2B.Id) {
            if (this.checkedAll || listCheckout.length > 0) {
              this.openSidebarCheckout(false)
            } else {
              this.openSidebarCheckout(true)
            }
          }
        } 
      }
      if (payload.key === F4) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 3) {
            this.addMemberToDetailBooking_UBK09(this.listMember[3])
          }
        } else {
          if (this.dataDetail2B.Id) {
            this.openModal('UBK01', 'modal-update-booking')
          }
        }
      }
      if (payload.key === F5) {
        if(this.isRemove && this.listMember.length > 4) {
          this.addMemberToDetailBooking_UBK09(this.listMember[4])
        } else {
          this.SearchBooking()
        }
      }
      if (payload.evt.ctrlKey && payload.key === S) {
        this.addNewMemberToDetailBooking_IME01()
      }
      
      if (payload.key === UP) {
        this.changeDefaultBookingDetail("UP")
      }
      if (payload.key === DOWN) {
        this.changeDefaultBookingDetail("DOWN")
      }
      
      if (payload.key === F6) {
        if(this.isRemove && this.listMember.length > 5) {
          this.addMemberToDetailBooking_UBK09(this.listMember[5])
        }
      }
      if (payload.key === F7) {
        if(this.isRemove && this.listMember.length > 6) {
          this.addMemberToDetailBooking_UBK09(this.listMember[6])
        }
      }
      if (payload.key === F8) {
        if(this.isRemove && this.listMember.length > 7) {
          this.addMemberToDetailBooking_UBK09(this.listMember[7])
        }
      }
      if (payload.key === F9) {
        if(this.isRemove && this.listMember.length > 8) {
          this.addMemberToDetailBooking_UBK09(this.listMember[8])
        }
      }
      if (payload.key === F10) {
        if(this.isRemove && this.listMember.length > 9) {
          this.addMemberToDetailBooking_UBK09(this.listMember[9])
        }
      }
      if (payload.key === F11) {
        if(this.isRemove && this.listMember.length > 10) {
          this.addMemberToDetailBooking_UBK09(this.listMember[10])
        }
      }
      if (payload.key === F12) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 11) {
            this.addMemberToDetailBooking_UBK09(this.listMember[11])
          }
        } else {
          this.$refs.Selected_Rate_RRA04A.$refs.search.focus()
        }
      }
    },
    FrintBDCinBookingDetail() {
      const listBDC = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            if (z.checked === true) {
              listBDC.push(z.Id)
            }
          })
        })
      })
      if (listBDC.length > 0) {
        this.PrintBDC(listBDC)
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_one_person_printbdc'))
      }
    },
    printBDCinCheckIn(data) {
      this.PrintBDC(data.data)
    },
    PrintBDC(data) {
      this.isLoadPrintBDC = true
      const boby = {
        BookingDetailId: data,
      }
      bookingPrint
        .getListDetailBooking(boby)
        .then(res => {
          if (res) {
            this.BookingDetailPrint = res.Data.BookingDetail
            setTimeout(() => {
              const divContents = document.getElementById('print-tem').innerHTML
              const data = `<html><head><title>ezCloud_ezGolf</title> <meta charset="UTF-8">
              </head><body style="padding: 0px;margin: 0px;">
              <style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} </style>
              ${divContents}
              </body></html>
              `
              const newBlob = new Blob([data], {
                type: 'text/html',
              })
              const fileLink = window.URL.createObjectURL(newBlob)
              const iframe = document.createElement('iframe')
              iframe.src = fileLink
              iframe.style.display = 'none'
              iframe.onload = () => {
                iframe.contentWindow.focus()
                iframe.contentWindow.print()
              }
              document.body.appendChild(iframe)
              this.isLoadPrintBDC = false
            }, 2000)
          }
        })
    },
    CopyText(text) {
      document.body.insertAdjacentHTML('beforeend', `<div id="copy" contenteditable>${text}</div>`)
      document.getElementById('copy').focus()
      document.execCommand('selectAll')
      document.execCommand('copy')
      document.getElementById('copy').remove()
    },
    inputRate_RRA04A() {
      this.checkInputRRA04A = true
      this.updateBookingByInputRRA04A_UBK02()
    },
    async updateBookingByInputRRA04A_UBK02() {
      const params = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          ClientId: this.Selected_Rate_RRA04A.ClientId,
          SourceId: this.Selected_Rate_RRA04A.SourceId,
          GolfClassId: this.Selected_Rate_RRA04A.GolfClassId,
          TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
          RateId: this.Selected_Rate_RRA04A.Id,
          CardCode4GolfClassRule: null,
          Bdc4golfClassRule: null,
          BookingDetailCourse: this.dataDetail2B.BookingDetail.BookingDetailCourse.map(x => ({CourseId: x.CourseId , NumberOfHole: x.NumberOfHole, ActualNumberOfHole: x.ActualNumberOfHole}))
        },
      }
      await booking.updateTeeTimeForBooking(params).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          if (this.IsAutoAddSaler == '1' && this.Selected_Rate_RRA04A.ClientId) {
            this.getSaleIdClient(this.Selected_Rate_RRA04A.ClientId)
          } else if (this.IsAutoAddSaler == '1' && !this.Selected_Rate_RRA04A.ClientId && this.dataDetail2B.SaleId) {
            this.DeleteSaleClient(null)
          } else if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            this.getDetailBooking2A()
          } else {
            this.getDetailBooking2ASearch()
          }
        }
      })
    },
    searchQuickGroupNameOrBookingCode(GroupNameSearch, BookingCodeSearch) {
      if (!GroupNameSearch) {
        this.BookingCode = BookingCodeSearch
      } else if (!BookingCodeSearch) {
        this.groupNameSearch = GroupNameSearch
      }
      this.getDetailBooking2ASearch()
    },
    async getSaleIdClient(DataId) {
      const body = {
        Id: DataId,
      }
      await booking.api_RCL03(body)
        .then(res => {
          this.updateInfoGroup_UBK01(res.Data.SaleId)
        })
    },
    DeleteSaleClient(ClientId) {
      const dataConfirm = {
        dataConfirm: {
          title: this.$t('golf_common_delete_sale_client_title'),
          content: this.$t('golf_common_delete_sale_client'),
          body: {
            Id: ClientId,
          },
        },
      }
      // Hiển thị xác nhận undo checkout
      this.$refs.deleteSaleClient.open(dataConfirm)
    },
    async updateInfoGroup_UBK01(SaleId) {
      const body = {
        Booking: {
          Id: this.dataDetail2B.Id,
          GroupName: this.dataDetail2B.GroupName,
          RepresentativeName: this.dataDetail2B.RepresentativeName,
          RepresentativePhoneNumber: this.dataDetail2B.RepresentativePhoneNumber,
          SaleId,
          RepresentativeNote: this.dataDetail2B.RepresentativeNote,
        },
        BookingDetailId: this.dataDetail2B.BookingDetail.Id
      }
      const response = await booking.updateBookingInfo(body)
      if (response.Status === '200') {
        if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }
    },
    changeDefaultBookingDetail(type) {
      let listIndexBookingDetail = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          listIndexBookingDetail = [...listIndexBookingDetail, ...y.BookingDetail]
        })
      })
      for(let i = 0; i< listIndexBookingDetail.length; i++) {
        if(listIndexBookingDetail[i].Id === this.dataDetail2B.BookingDetail.Id) {
          if(type === "UP" && i > 0) {
            this.setActiveUser(listIndexBookingDetail[i - 1])
            break
          } else if (type === "DOWN" && i < listIndexBookingDetail.length - 1) {
            this.setActiveUser(listIndexBookingDetail[i + 1])
            break
          }
        }
      } 
    },
    findColorBookingStatus(BookingStatus) {
      const color = this.ColorBookingDetail[BookingStatus]
      return color
    },
    changeFilterBookingStatus() {
      if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
        if(this.bookingDetailId) {
          this.getDetailBooking2A()
        }
      } else {
        this.getDetailBooking2ASearch()
      }
      this.isShowFilterBookingStatus = false
    },
    refreshData() {
      if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
        this.getDetailBooking2A()
      } else {
        this.getDetailBooking2ASearch()
      }
    },
    CheckAllowDiscount() {
      if(this.isAllowDiscountOnRate == 0) {
        return
      } else if(this.dataDetail2B.BookingDetail.RateId == -1){
        return
      } else {
        this.openModal('UBK03', 'modal-update-rate-code')
      }
    },
    replaceString(string, oldValue, newValue) {
      return string.replace(oldValue, newValue)
    },
    openModalDistributionForGroup(data) {
      this.$emit('event', { type: 'open-modal-distribution-group', BookingCodeDistribution: data })
    },
    SearchBooking() {
      if (!this.listBDC.length > 0 && !this.listBagtag.length > 0 && !this.listLocker.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
        if(this.bookingDetailId && this.teeTimeId) {
          this.getDetailBooking2A()
        }
      } else {
        this.getDetailBooking2ASearch()
      }
    },
    SearchScanBDCBagtagLocker(isBDC,isBagtag,isLocker) {
      if(isBDC) {
        if(this.listBDC.length < this.manualSearchFromBDCBagtagNumber) {
          this.getDetailBooking2ASearch(true, true, false, false)
        } else {
          setTimeout(() => {
            this.$refs.listBDC.$refs.search.focus()
          },100)
        }
      } else if (isBagtag) {
        if(this.listBagtag.length < this.manualSearchFromBDCBagtagNumber) {
          this.getDetailBooking2ASearch(true, false, true, false)
        } else {
          setTimeout(() => {
            this.$refs.listLocker.$refs.search.focus()
          },100)
        }
      } else if (isLocker) {
        if(this.listLocker.length < this.manualSearchFromBDCBagtagNumber) {
          this.getDetailBooking2ASearch(true, false, false, true)
        } else {
          setTimeout(() => {
            this.$refs.listBagtag.$refs.search.focus()
          },100)
        }
      }
    },
    openInputMember() {
      var dataInput = []
      this.dataDetail2A.forEach(x => {
        var dataTemp = {
          TeeTimeId: x.Id,
          IsBlock: x.IsBlock,
          OpenDate: x.OpenDate,
          TeeId: x.TeeId,
          TeeName: x.TeeName,
          TeeTimeInfoId: x.TeeTimeInfoId,
          BookingDetail: []
        }
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            if(z.checked == true && z.MemberId == -1) {
              dataTemp.BookingDetail.push(
                {
                  ...z,
                  ...{
                    BookingCode: y.BookingCode,
                    GroupName: y.GroupName
                  },
                  ...{
                    DataMember: {
                      FullName: null,
                      CardCode: null,
                      PhoneNumber: null,
                      Email: null,
                      NationalityCode: this.NationalityCodeDefault,
                      SexCode: 'SEX_M'
                    }
                  }
                }
              )
            }
          })
        })
        if(dataTemp.BookingDetail.length > 0) {
          dataInput.push(dataTemp)
        }
      })
      if(dataInput.length > 0) {
        this.dataInputMemberToBooking = JSON.parse(JSON.stringify(dataInput))
        this.$root.$emit('bv::show::modal', 'modal-input-member-to-booking')
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_one_person'))
      }
    },
    async SearchStarterGroup(data) {
      const body = {
        KeyWord: null,
        OpenDate: this.dataDetail2B.BookingDetail.OpenDate,
        ExpirationDate: this.dataDetail2B.BookingDetail.OpenDate,
        BDC: [this.dataDetail2B.BookingDetail.BDC],
        CourseId: [],
        BagTag: [],
      }
      await starter
      .api_RBKSA1(body)
      .then(res => {
        if (res) {
          
          const dataTemp = []
          res.Data.BookingDetail.forEach(x => {
            if(this.DefaultSearch === 'BAGTAG') {
              dataTemp.push(x.BagtagCode)
            } else if (this.DefaultSearch === 'BDC') {
              dataTemp.push(x.BDC)
            }
          })
          if(this.DefaultSearch === 'BAGTAG') {
            this.listBDC = []
            this.listLocker = []
            this.listBagtag = dataTemp
            this.SearchScanBDCBagtagLocker(false, true, false)
          } else if (this.DefaultSearch === 'BDC') {
            this.listBDC = dataTemp
            this.listLocker = []
            this.listBagtag = []
            this.SearchScanBDCBagtagLocker(true, false, false)
          }
        }
      })
    },
    exportExcel() {
      const data = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            if(z.checked) {
              data.push(z)
            }
          })
        })
      })
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Member");
      const worksheetSexCode = workbook.addWorksheet("SexCode");
      const worksheetFormatDate = workbook.addWorksheet("FormatDate");

      worksheet.columns = [
          { header: "Họ tên", key: "FullName"},
          { header: "Mã Vga", key: "Vgacode"},
          { header: "Giới tính", key: "SexCode"},
          { header: "Ngày sinh", key: "BirthDay"},
          { header: "Số điện thoại", key: "PhoneNumber"},
          { header: "Email", key: "Email"},
          { header: "Handicap Index", key: "HandicapIndex"},
          { header: "Handicap Course", key: "HandicapCourse"},
          { header: "Mã nhóm đấu", key: "FlightName"},
          { header: "Locker", key: "Locker"},
          { header: "Bagtag", key: "Bagtag"},
          { header: "Ghi chú", key: "Note"},
          { header: "BDC", key: "BDC"},
          { header: "TeeTime", key: "TeeTime"},
          { header: "TeeTimeId", key: "TeeTimeId"},
          { header: "MemberId", key: "MemberId"},

      ];
      worksheetFormatDate.columns = [
        { header: "Format Date", key: "key"},
      ];
      worksheetFormatDate.addRow({key: "'01/01/2001"})
      worksheetSexCode.columns = [
        { header: "Mã giới tính", key: "KeyCode"},
        { header: "Giới tính", key: "KeyValue"},
      ];
      this.listSex.forEach(x => {
        worksheetSexCode.addRow(x)
      })
      data.forEach(x => {
        worksheet.addRow({
          FullName: x.MemberId != -1 ? x.FullName : '',
          Vgacode:x.MemberId != -1 ? x.Vga : '',
          SexCode: x.MemberId != -1 ? x.SexCode : '', 
          BirthDay: x.MemberId != -1 ? x.Brithday : '',
          PhoneNumber: x.MemberId != -1 ? x.PhoneNumber : '',
          Email: x.MemberId != -1 ? x.Email : '',
          HandicapIndex: '',
          HandicapCourse: '',
          FlightName: '', 
          Locker: x.LockerCode,
          Bagtag: x.BagtagCode,
          Note: x.Note,
          BDC:  x.BookingDetailCode,
          TeeTime: this.dataDetail2A.filter(t=>t.Id == x.TeeTimeId)[0].TeeName + convertUTC(x.OpenDateTime,' HH:mm'),
          TeeTimeId: x.TeeTimeId,
          MemberId:  x.MemberId
        })
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateMemberExport.xlsx`)
      this.isShowFilterBookingStatus = false
      })
    },
    EditBDC() {
      this.$root.$emit('bv::show::modal', 'modal-change-bdc')
    }
  },
}
