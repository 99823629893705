<template>
  <div class="d-flex justify-content-center align-items-center flex-column h-100">
    <div class="no-record-found" />
    <p class="text">
      {{ $t('golf_booking_text1') }} <br>
      {{ $t('golf_booking_text2') }}
    </p>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  name: 'NoRecordFound',
  components: {
    BAvatar,
  },
}
</script>

<style scoped lang="scss">
.no-record-found {
  // background-image: url("../../../assets/images/svg/no-record-found.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 175px;
  height: 195px;
  margin-bottom: 20px;
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
</style>
