export const add_member_params = {
  Id: null,
  BookingInfo: [
    {
      ClientId: 1,
      GolfClassId: null,
      BookingInfoDate: '',
      NumberOfGolfer: null,
      RateId: null,
      TeeTimeId: null,
      BookingInfoCourse: [
        {
          CourseId: null,
          NumberOfHole: null
        }
      ]
    }
  ]
}
