<template>
  <div
    class="p-2"
  >
    <div class="booking-content">
      <div
        v-if="step === 1"
        class="col-left"
      >
        <div class="search-form">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <date-range-picker
                  ref="picker"
                  v-model="startTime"
                  :single-date-picker="true"
                  opens="right"
                  :ranges="false"
                  :time-picker="false"
                  :time-picker-increment="1"
                  :show-days-of-week="true"
                  :show-dropdowns="true"
                  :auto-apply="true"
                  :locale-data="{
                    format: 'DD/MM/YYYY HH:mm',
                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <v-select
                  v-model="courses.selected"
                  label="CourseName"
                  :options="courses.list"
                  :clearable="false"
                >
                  <!-- @input="getListTeeTimeBooking_RTB01" loại bỏ do thay đổi sân => thay đổi hố-->
                  <template #selected-option="{ CourseName, Color }">
                    <div
                      class="text-truncate"
                      style="width: 94px"
                    >
                      <ez-icon
                        icon="ezGolf-icon-golf-course"
                        :color="Color"
                        :size="18"
                      />
                      {{ CourseName }}
                    </div>
                  </template>
                  <template v-slot:option="item">
                    <ez-icon
                      icon="ezGolf-icon-golf-course"
                      :size="18"
                      :color="item.Color"
                    />
                    {{ item.CourseName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="courses.selected"
              md="3"
            >
              <b-form-group>
                <b-dropdown
                  variant="outline-secondary"

                  style="width: 100%;"
                >
                  <template #button-content>
                    <span>{{ courses.selected.holeAmount }} {{ courses.selected.CourseName }}</span>
                  </template>
                  <b-dropdown-text>
                    Hole: {{ courses.selected.CourseName }}
                    <b-input-group>
                      <b-form-spinbutton
                        id="sb-step"
                        v-model="courses.selected.holeAmount"
                        min="0"
                        :step="9"
                      />
                    </b-input-group>
                  </b-dropdown-text>
                <!-- <b-dropdown-item >
@input="getListTeeTimeBooking_RTB01"
                    </b-dropdown-item> -->
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-checkbox v-model="IsCheckLock.Check" style="margin-top: auto;margin-bottom: auto;"
               >
                {{ $t('golf_sidebar_add_booking_check_lock_teetime') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="1"
              class="m-auto pr-0"
            >
              <b-form-group>
                <ez-icon
                  icon="ezGolf-icon-search"
                  size="16"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="11"
              class="pl-0"
            >
              <b-form-group class="inputRateRRA04A">
                <v-select
                  v-if="ListRate_RRA04A"
                  v-model="Selected_Rate_RRA04A"
                  label="RRA04RateName"
                  :options="ListRate_RRA04A"
                  :disabled="isLoadding"
                  placeholder="Tìm kiếm rate: GolfClass#Công ty#Nguồn"
                  :clearable="false"
                  @input="inputRate_RRA04A()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="golfClasses.selected"
                  disabled
                  label="GolfClassName"
                  :options="golfClasses.list"
                  placeholder="Golf Class"
                  :clearable="false"
                >
                  <template #selected-option="{ GolfClassName, Color }">
                    <div
                      class="dot-size-10 d-inline-block rounded-circle"
                      :style="{backgroundColor: Color}"
                      style="margin-top: -2px;"
                    />
                    {{ GolfClassName }}
                  </template>
                  <template v-slot:option="{ GolfClassName, Color }">
                    <div
                      class="dot-size-10 d-inline-block rounded-circle"
                      :style="{backgroundColor: Color}"
                      style="margin-top: -2px;"
                    />
                    {{ GolfClassName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="rateCodes.selected"
                  class="select-opacity"
                  label="RateName"
                  :options="rateCodes.list"
                  :clearable="true"
                  @input="getListTeeTimeBooking_RTB01"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="golfClasses.selected"
                  label="GolfClassName"
                  :options="golfClasses.list"
                  placeholder="Golf Class"
                  :clearable="true"
                >
                  <template #selected-option="{ GolfClassName, Color }">
                    <div
                      class="dot-size-10 d-inline-block rounded-circle"
                      :style="{backgroundColor: Color}"
                      style="margin-top: -2px;"
                    />
                    {{ GolfClassName }}
                  </template>
                  <template v-slot:option="{ GolfClassName, Color }">
                    <div
                      class="dot-size-10 d-inline-block rounded-circle"
                      :style="{backgroundColor: Color}"
                      style="margin-top: -2px;"
                    />
                    {{ GolfClassName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="source.selected"
                  label="SourceName"
                  :options="source.list"
                  :placeholder="$t('golf_booking_source_picker')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="companies.selected"
                  label="ClientName"
                  :options="companies.list"
                  :placeholder="$t('golf_booking_company_picker')"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="rateCodes.selected"
                  class="select-opacity"
                  label="RateName"
                  :options="rateCodes.list"
                  :clearable="true"
                  @input="getListTeeTimeBooking_RTB01"
                />
              </b-form-group>
            </b-col>

          </b-row> -->
          <!-- <b-row>
            <b-col class="text-right">
              <b-button
                variant="outline-primary"
                :disabled="isLoading"
                @click="refresh"
              >
                {{ $t('golf_booking_pick_again') }}
              </b-button>

              <b-button
                variant="primary"
                :disabled="isLoading"
                class="ml-1"
                @click="getListTeeTimeBooking_RTB01"
              >
                {{ $t('golf_booking_search') }}
              </b-button>
            </b-col>
          </b-row> -->
        </div>

        <div v-if="items.length > 0">
          <b-table-simple
            responsive
            bordered
            sticky-header
          >
            <b-thead>
              <b-tr
                v-if="timeFilter"
                v-model="isFilterTeetime"
                @click="handerFilterTeeTime()"
              >
                <b-th
                  v-for="(item, index) in fields"
                  :key="index"
                >
                  {{ item }}
                  <ez-icon
                    v-if="index == 0"
                    icon="ezGolf-icon-filter"
                    :size="12"
                  />
                </b-th>
              </b-tr>
              <b-tr v-else>
                <b-th
                  v-for="(item, index) in fields"
                  :key="index"
                >
                  {{ item }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) in items"
                :key="index"
              >
                <b-th
                  style="width: 120px"
                  class="text-center"
                >
                  <!-- <div class="d-flex flex-row align-items-center justify-content-center"> -->
                  <!-- <div> -->
                  {{ convertUTC(item.Time, 'HH:mm' ) }}
                  <!-- </div> -->

                  <!-- </div> -->
                </b-th>
                <b-td
                  v-for="(tee, indexTeeTime) in item.TeeTime"
                  :key="indexTeeTime"
                  :class="selectedTeeTime && selectedTeeTime.includes(tee.Id) && tee.BookedNumberPlayer > 0 ? 'selected-background-color' :
                    Number(tee.NumberPlayer) === 0 ? 'blocked-background-color' : ''"
                  class="p-0"
                >
                  <div
                    v-if="!tee.IsBlock && !tee.IsDeleted"
                    class="cursor-pointer"
                  >
                    <div v-if="bookingShowIconGolfer != 1" class="hover-me">
                      <div class="d-select-remaning">
                        <div class="text-center">
                          <strong>{{ tee.NumberPlayer - (tee.BookedNumberPlayer ? tee.BookedNumberPlayer : 0) }}</strong>
                        </div>
                        <div
                          v-if="tee.NumberPlayer > 0 && tee.NumberPlayer < 5 && tee.BookedNumberPlayer > 0"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <div
                            v-for="i in tee.NumberPlayer"
                            :key="i"
                            class="d-line"
                            style="height: 3px; width: 30px; margin: 0 2px"
                            :class="i <= tee.BookedNumberPlayer ? 'bg-primary' : 'bg-secondary'"
                          />
                        </div>
                        <div v-else-if="tee.NumberPlayer > 4">

                        </div>
                      </div>
                      <div class="d-select-tee d-flex justify-content-center">
                        <ez-icon
                          icon="ezGolf-icon-slash"
                          :size="18"
                          style="padding: 0 6px;"
                          :style="`color: ${'#114A9F'}`"
                          @click="handleCleanTeeTime(tee)"
                        />
                        <div v-if="tee.NumberPlayer < 5">
                          <ez-icon
                          v-for="(value) in Math.abs(tee.NumberPlayer)"
                          :key="value"
                          icon="ezGolf-icon-user"
                          :size="18"
                          style="padding: 0 6px;"
                          :style="`color: ${value <= tee.BookedNumberPlayer ? '#114A9F' : 'rgba(17, 74, 159, 0.2)'}`"
                          @click="addPlayerToTee(tee, value, index, indexTeeTime, false)"
                        />
                        </div>
                        
                        <b-form-group v-else-if="tee.NumberPlayer > 4">
                          <vue-numeric
                            v-model="tee.BookedNumberPlayerTemp"
                            class="form-control"
                            style="width: 50px; float: right;height: 25px;"
                            :min="0"
                            :max="tee.NumberPlayer"
                            :empty-value="0"
                            @change="addPlayerToTee(tee, tee.BookedNumberPlayerTemp, index, indexTeeTime, true)"
                          />
                          <!-- <b-form-input style="width: 50px; float: right;height: 25px;" v-model="tee.BookedNumberPlayer" type="number" min="0" max="5"></b-form-input> -->
                        </b-form-group>
                      </div>
                    </div>
                    <div v-else class="hover-me">
                      <div v-if="Number(tee.NumberPlayer) > 0" class="d-flex justify-content-center" style="padding-top: 9px;">
                        
                        <div v-if="tee.NumberPlayer < 5">
                          <ez-icon
                          v-for="(value) in Math.abs(tee.NumberPlayer)"
                          :key="value"
                          icon="ezGolf-icon-user"
                          :size="18"
                          style="padding: 0 6px;"
                          :style="`color: ${value <= tee.BookedNumberPlayer ? '#114A9F' : 'rgba(17, 74, 159, 0.2)'}`"
                          @click="addPlayerToTee(tee, value, index, indexTeeTime, false)"
                        />
                        </div>
                        
                        <b-form-group v-else-if="tee.NumberPlayer > 4">
                          <vue-numeric
                            v-model="tee.BookedNumberPlayerTemp"
                            class="form-control"
                            style="width: 50px; float: right;height: 25px;"
                            :min="0"
                            :max="tee.NumberPlayer"
                            :empty-value="0"
                            @change="addPlayerToTee(tee, tee.BookedNumberPlayerTemp, index, indexTeeTime, true)"
                          />
                        </b-form-group>
                        <ez-icon
                          icon="ezGolf-icon-x-circle"
                          :size="18"
                          style="padding: 0 6px;"
                          :style="`color: ${'#114A9F'}`"
                          @click="handleCleanTeeTime(tee)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="text-center cursor-not-allowed"
                  >
                    <ez-icon
                      :icon="(tee.IsBlock) ? 'ezGolf-icon-lock' : 'ezGolf-icon-slash'"
                      :size="18"
                    />
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

        <div
          v-else
        >
          <NoRecordFound />
        </div>
      </div>
      <div class="col-right">
        <TableIntoMoneyBooking
          v-if="courses.selected && courses.selected.Id && golfClasses.selected && golfClasses.selected.Id"
          :header-tee-time="getHeaderTeeTime()"
          :detail-tee-time="tableIntoMoneyBooking"
          :course-id="courses.selected.Id"
          :number-of-hole="courses.selected.holeAmount"
          :golf-class-id="golfClasses.selected.Id"
          :rate-id="(rateCodes.selected) ? rateCodes.selected.Id : 0"
          @change="changeTee"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { commonServices } from '@/api/common-services'
import { courseSetting } from '@/api/course-setting'
import { booking } from '@/api/booking'
import { members } from '@/api/members'
import { golfClass } from '@/api/golf-class'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import TeeSheet from '@/views/modules/tee-sheet/TeeSheet'
import NoRecordFound from './NoRecordFound/NoRecordFound'
import TableIntoMoneyBooking from './TableIntoMoneyBooking/TableIntoMoneyBooking'

export default {
  name: 'TableAddBooking',
  components: {
    NoRecordFound, TableIntoMoneyBooking, vSelect, ValidationProvider, ValidationObserver, required,
  },
  props: [
    'step',
    'courses_selected',
    'date_selected',
    'add_member',
    'teeSelect',
    'bookingShowIconGolfer',
    'IsCheckLock'
  ],
  data() {
    return {
      courses: {
        selected: null,
        list: [],
      },
      companies: {
        selected: null,
        list: [],
      },
      holes: {
        selected: null,
        list: [],
        holeAmount: 9,
      },
      golfClasses: {
        selected: null,
        list: [],
      },
      rateCodes: {
        selected: null,
        list: [],
      },
      source: {
        selected: null,
        list: [],
      },
      national: {
        selected: null,
        list: [],
      },
      fields: [],
      items: [],
      // danh sách đầy đủ
      itemsAll: [],
      numberGuest: '',
      startTime: this.getUTC('start', 'day'),
      weekLyCode: 'MON',
      TA: null,
      sale: null,
      familyName: null,
      name: null,
      phoneNumber: null,
      note: null,
      selectedTeeTime: [],
      nameOfGroup: null,
      email: null,
      cardNumber: null,
      tableIntoMoneyBooking: [],
      groupName: null,
      isLoading: true,
      hovering: {
        indexTeeTime: '',
        indexPlayer: [],
      },
      timeFilter: null,
      isFilterTeetime: false,
      defaultLine: [],
      detailHole: null,
      // Bien xac dinh da load xong chua?
      isLoaded: false,
      ListRate_RRA04A: null,
      Selected_Rate_RRA04A: null,
      isLoadding: false,
    }
  },
  watch: {
    selectedTeeTime(newValue) {
      const teePickedInfo = []
      this.items.forEach(element => {
        element.TeeTime.forEach(tee => {
          tee.Time = element.Time
          tee.Line = []
          if(this.detailHole) {
            if (this.detailHole.DefaultLine.filter(x => x.CourseId == this.courses.selected.Id && x.NumberOfHoles == this.courses.selected.holeAmount && x.TeeStart == tee.TeeId).length) {
              const tmp = this.detailHole.DefaultLine.find(x => x.CourseId == this.courses.selected.Id && x.NumberOfHoles == this.courses.selected.holeAmount && x.TeeStart == tee.TeeId).Tee
              tmp.forEach((item, index) => {
                tee.Line[index] = this.detailHole.Tee.find(x => x.Id == item)
              })
            }
          }
          if (newValue.includes(tee.Id)) {
            teePickedInfo.push(tee)
          }
        })
      })
      if (teePickedInfo && teePickedInfo.length > 0) {
        teePickedInfo.forEach((element, index) => {
          if (+element.BookedNumberPlayer === 0) {
            teePickedInfo.splice(index, 1)
          }
        })
      }
      this.tableIntoMoneyBooking = teePickedInfo
      this.$store.commit('booking/SET_TEE_PICKED_INFO', teePickedInfo)
    },
    // sân, hố, ngày, công ty, nguồn, golf class thay đổi => rate thay đổi => call teetime Booking
    'courses.selected': {
      handler(val) {
        this.refreshAddNew()
        this.updateTeeTimeInfo()
        this.updateBookingInfo()
        this.getListRate_RRA04()
        this.getDefaultLine()
        this.getListRate_RRA04A()
      },
      deep: true,
    },
    // 'courses.selected': function (newValue, _) {
    //   this.updateTeeTimeInfo()
    //   this.updateBookingInfo()
    //   this.getGolfClasses_RGC01()
    // },
    // 'companies.selected': function (newValue, _) {
    //   this.updateTeeTimeInfo()
    //   this.updateBookingInfo()
    //   this.getListRate_RRA04()
    //   this.$store.commit('booking/SET_ITEMS_AGENT_INFO', {
    //     ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
    //     SourceId: (this.source.selected) ? this.source.selected.Id : null,
    //   })
    // },
    'holes.holeAmount': function (newValue, _) {
      this.refreshAddNew()
      this.updateTeeTimeInfo()
    },
    // 'golfClasses.selected': function (newValue, _) {
    //   this.updateTeeTimeInfo()
    //   this.getListRate_RRA04()
    // },
    'rateCodes.selected': function (newValue, _) {
      this.refreshAddNew()
      this.updateTeeTimeInfo()
    },
    // 'source.selected': function (newValue, _) {
    //   this.updateTeeTimeInfo()
    //   this.updateBookingInfo()
    //   this.getListRate_RRA04()
    //   this.$store.commit('booking/SET_ITEMS_AGENT_INFO', {
    //     ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
    //     SourceId: (this.source.selected) ? this.source.selected.Id : null,
    //   })
    // },
    'national.selected': function (newValue, _) {
      this.updateTeeTimeInfo()
    },
    startTime(newValue, _) {
      this.refreshAddNew()
      this.changeWeedlyCode()
      this.updateTeeTimeInfo()
      this.getListRate_RRA04()
      this.getListRate_RRA04A()
      this.getListCompany_RCL01()
      this.getListResources_RSO01()
    },
    courses_selected(newValue) {
      // prop sân thay đổi thì loại lại danh sách tee theo thời gian mới
      this.courses.selected = newValue
      // this.getListRate_RRA04();
    },
    date_selected(newValue) {
      this.startTime = this.date_selected
      // prop thời gian thay đổi thì loại lại danh sách tee theo thời gian mới
      // startTime thay đổi -> getListRate_RRA04 ( trong đó đã gọi getListTeeTimeBooking_RTB01 )
      // this.getListTeeTimeBooking_RTB01()
    },
    // xử lý click màn hình teeshet
    teeSelect(value) {
      if (value) {
        this.isFilterTeetime = true,
        this.timeFilter = this.teeSelect.OpenDateTime
        if (this.courses.selected.Id !== this.teeSelect.CourseId) {
          // khi sân thay đổi thì rate thay đổi =>  tee time thay đổi => filter
          this.courses.selected = this.courses.list.find(x => x.Id == this.teeSelect.CourseId)
        } else {
          // sân không đổi
          this.filterTeeTimeBooking()
        }
      }
    },
  },
  created() {
    this.isLoading = true
    try {
      this.doubleRaf(async () => {
        await this.getListCourse_RCO01()
        await this.getListNationals_RNA01()
        await this.getListCompany_RCL01()
        await this.getGolfClasses_RGC01()
        await this.getListResources_RSO01()
        await this.getDefaultLine()
        this.isLoaded = true
        await this.getListRate_RRA04()
      })
    } catch (e) {
      utils.logError(e)
    } finally {
      this.isLoading = false
      this.isLoaded = true
    }
  },
  mounted() {
    const days = [
      'SUN',
      'MON',
      'TUE',
      'WED',
      'THU',
      'FRI',
      'SAT',
    ]
    const d = new Date()
    this.weekLyCode = days[d.getDay()]
  },
  methods: {
    getDefaultLine() {
      booking.api_RTE03({ CourseId: this.courses.selected.Id }).then(res => {
        if (res.Status === '200') {
          this.detailHole = res?.Data
          // Reset đường line khi đổi số hố
          this.defaultLine = []

          if (this.detailHole.DefaultLine.filter(x => x.CourseId == this.courses.selected.Id && x.NumberOfHoles == this.courses.selected.holeAmount).length) {
            const tmp = this.detailHole.DefaultLine.find(x => x.CourseId == this.courses.selected.Id && x.NumberOfHoles == this.courses.selected.holeAmount).Tee
            tmp.forEach((item, index) => {
              this.defaultLine[index] = this.detailHole.Tee.find(x => x.Id == item)
            })
            // this.value = [...this.linePlay]
          } else {
            // this.value = []
          }
        }
      })
    },

    changeTee(val) {
      const arr = JSON.parse(JSON.stringify(this.items))
      arr.forEach(element => {
        element.TeeTime.forEach(tee => {
          if (tee.Id === val.Id) {
            tee.BookedNumberPlayer = +val.number
          }
        })
      })
      this.items = arr
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },

    updateTeeTimeInfo() {
      const payload = {
        courses: this.courses.selected,
        companies: this.companies.selected,
        holes: this.holes,
        golfClasses: this.golfClasses.selected,
        startTime: this.startTime,
        rateCodes: this.rateCodes.selected,
      }
      this.$store.commit('booking/SET_TEE_TIME_INFO', payload)
    },

    autoRang(start, end, step = 1, offset = 0) {
      const len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1
      const direction = start < end ? 1 : -1
      const startingPoint = start - (direction * (offset || 0))
      const stepSize = direction * (step || 1)

      return Array(len).fill(0).map((_, index) => startingPoint + (stepSize * index))
    },

    updateBookingInfo() {
      const payload = {
        groupName: this.groupName,
        courses: this.courses.selected,
        source: this.source.selected,
        companies: this.companies.selected,
        sale: this.sale,
        familyName: this.familyName,
        name: this.name,
        phoneNumber: this.phoneNumber,
        email: this.email,
        cardNumber: this.cardNumber,
        national: this.national.selected,
        note: this.note,
      }
      this.$store.commit('booking/SET_BOOKING_INFO', payload)
    },

    async getListCourse_RCO01() {
      const response = await courseSetting.getListCourse()
      if (response.Status === '200') {
        this.courses.list = response?.Data?.Course.map(x => Object.assign(x, { holeAmount: x.NumberOfHoles }))
        // this.courses.selected.push(response?.Data?.Course[0])
      }
    },

    // async getGolfClass_RGC01() {
    //   const CourseId = this.courses.selected.map(item => item.Id)
    //   const response = await booking.getGolfClasses({ CourseId })
    //   if (response.Status === '200') {
    //     this.golfClasses.list = response?.Data?.GolfClass
    //     this.golfClasses.selected = response?.Data?.GolfClass[0]
    //   }
    // },

    async getListRate_RRA04() {
      if (this.isLoaded) {
        this.isLoadding = true
        const response = await booking.getRateCode({
          Date: this.startTime,
          GolfClassId: this.golfClasses.selected?.Id,
          ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
          SourceId: (this.source.selected) ? this.source.selected.Id : null,
          Course: [
            {
              Id: this.courses.selected.Id,
              NumberOfHole: this.courses.selected.holeAmount,
            },
          ],
          WeeklyCode: this.weekLyCode,
        })

        if (response.Status === '200') {
          this.rateCodes.list = response?.Data?.Rate
          if(this.rateCodes.list.length > 0) {
            if( response?.Data?.Rate.length > 0 && this.Selected_Rate_RRA04A) {
              this.rateCodes.selected = response?.Data?.Rate.find(x => x.Id === this.Selected_Rate_RRA04A.Id)
              this.companies.selected = this.Selected_Rate_RRA04A.ClientId ? this.companies.list.find(x => x.Id === this.Selected_Rate_RRA04A.ClientId) : null 
              this.source.selected = this.Selected_Rate_RRA04A.SourceId ?  this.source.list.find(x => x.Id === this.Selected_Rate_RRA04A.SourceId) : null
            } else {
              this.rateCodes.selected = response?.Data?.Rate[0]
              this.companies.selected = response?.Data?.Rate[0].ClientId ? this.companies.list.find(x => x.Id === response?.Data?.Rate[0].ClientId) : null 
              this.source.selected = response?.Data?.Rate[0].SourceId ?  this.source.list.find(x => x.Id === response?.Data?.Rate[0].SourceId) : null
            }
            this.$store.commit('booking/SET_ITEMS_AGENT_INFO', {
              ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
              SourceId: (this.source.selected) ? this.source.selected.Id : null,
            })
            this.getListTeeTimeBooking_RTB01()
          } else {
            this.rateCodes.selected = null
            this.companies.selected = null
            this.source.selected = null
            this.$store.commit('booking/SET_ITEMS_AGENT_INFO', {
              ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
              SourceId: (this.source.selected) ? this.source.selected.Id : null,
            })
            this.items = []
            this.itemsAll = []
            this.$store.commit('booking/SET_FIELDS_TABLE_BOOKING', ['TEETIME', ...teeNames])
            this.$store.commit('booking/SET_ITEMS_TABLE_BOOKING', response.Data?.TeeSheet)
            this.isLoadding = false
          }
          
        } else {
          this.isLoadding = false
        }
      }
    },

    async getListTeeTimeBooking_RTB01() {
      if (this.isLoaded) {
        this.$store.commit('booking/SELECTED_TEE', {})
        this.fields = []
        this.items = []
        if (this.rateCodes.selected?.Id && this.golfClasses.selected?.Id) {
          const response = await booking.getListTeeTimeBooking({
            Date: this.startTime,
            GolfClassId: (this.golfClasses.selected?.Id) ? this.golfClasses.selected?.Id : null,
            RateId: (this.rateCodes.selected?.Id) ? this.rateCodes.selected.Id : null,
            Course: [
              {
                Id: this.courses.selected.Id,
                NumberOfHole: this.courses.selected.holeAmount,
              },
            ],
            WeeklyCode: this.weekLyCode,
          })

          if (response.Status === '200') {
            if (response.Data?.TeeSheet.length <= 0) {
              this.$toast.info(this.$t('can_not_find_tees'))
              return
            }
            const teeNames = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
            this.fields = ['TEETIME', ...teeNames]
            this.items = response.Data?.TeeSheet
            this.itemsAll = response.Data?.TeeSheet
            // filter TeeTime
            this.filterTeeTimeBooking()
            this.items.forEach(e => {
              e.TeeTime.forEach(tee => {
                tee.BookedNumberPlayer = 0
                tee.BookedNumberPlayerTemp = 0
              })
            })
            this.$store.commit('booking/SET_FIELDS_TABLE_BOOKING', ['TEETIME', ...teeNames])
            this.$store.commit('booking/SET_ITEMS_TABLE_BOOKING', response.Data?.TeeSheet)
          } else {
            this.showResToast(response)
          }
        }
        this.isLoadding = false

        // else {
        //   this.showToast('error', this.$t('golf_booking_not_found_rate'))
        // }
      // this.$toast.error(response?.Messages[0]?.MessageText)
      }
    },

    async getListNationals_RNA01() {
      const response = await booking.getListNational()
      if (response) {
        this.national.list = response?.Data?.Country
        this.national.selected = response?.Data?.Country[0]
      }
    },

    async getListCompany_RCL01() {
      const response = await members.getCompanies({ OpenDateTime: this.startTime })
      if (response.Status === '200') {
        this.companies.list = response?.Data?.Client
        // this.companies.selected = response?.Data?.Client[0]
      }
    },

    async getGolfClasses_RGC01() {
      const CourseId = [this.courses.selected.Id]

      const response = await golfClass.getListGolfClass({ CourseId })

      if (response.Status === '200') {
        this.golfClasses.list = response?.Data?.GolfClass

        await commonServices
          .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
          .then(res => {
            if (res.Status === '200') {
              if (response?.Data?.GolfClass.filter(x => x.Id == res?.Data?.find(x => x.KeyCode === 'BOOKING_GOLF_CLASSID_DEFAULT').KeyValue).length) {
                this.golfClasses.selected = response?.Data?.GolfClass.find(x => x.Id == res?.Data?.find(x => x.KeyCode === 'BOOKING_GOLF_CLASSID_DEFAULT').KeyValue)
              } else {
                this.golfClasses.selected = this.golfClasses.list[0]
              }
            }
          })
      }
    },

    async getListResources_RSO01() {
      const response = await booking.getListResources({ OpenDateTime: this.startTime })

      if (response.Status === '200') {
        this.source.list = response?.Data?.Source
        // this.source.selected = response?.Data?.Source[0]
      }
    },

    handleCleanTeeTime(_teeTime) {
      _teeTime.BookedNumberPlayer = 0
      let teeTimeRep = this.selectedTeeTime
      teeTimeRep = teeTimeRep.filter(item => item !== _teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      this.selectedTeeTime = teeTimeRep
      this.hovering = {
        indexTeeTime: '',
        indexPlayer: [],
      }
    },

    handleClickTeeTimeCell(_teeTime) {
      const teeTimeRep = this.selectedTeeTime
      teeTimeRep.push(_teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      const myset = new Set(teeTimeRep) // Chuyển mảng thành Set để xóa phần tử trùng
      this.selectedTeeTime = Array.from(myset)
    },

    handleClickPlus() {
      this.holes.holeAmount += 9
    },

    handleClickMinus() {
      if (this.holes.holeAmount > 0) {
        this.holes.holeAmount -= 9
      }
    },
    refreshAddNew() {
      this.selectedTeeTime = []
      this.getListTeeTimeBooking_RTB01()
    },

    async refresh() {
      this.doubleRaf(async () => {
        this.$store.commit('booking/SET_TEE_PICKED_INFO', [])
        this.$store.commit('booking/SET_TEE_TIME_INFO', [])
        this.$store.commit('booking/SELECTED_TEE', {})

        this.isLoading = true
        this.courses.selected = null
        this.items = []
        this.fields = []
        this.selectedTeeTime = []
        this.tableIntoMoneyBooking = []
        this.startTime = this.getUTC('start', 'day')
        this.headerTeeTime = ''
        this.isLoading = false

        await this.getListCourse_RCO01()
        await this.getListNationals_RNA01()
        await this.getListCompany_RCL01()
        await this.getGolfClasses_RGC01()
        await this.getListResources_RSO01()
        await this.getListRate_RRA04()
      })
    },

    getHeaderTeeTime() {
      const courseNames = `${this.courses.selected?.holeAmount} hole ${this.courses.selected?.CourseName}`
      return `${this.utcConvertToLocalTimeZone(this.startTime, 'DD/MM/YYYY')} - ${this.golfClasses.selected?.GolfClassName} - ${this.rateCodes.selected?.RateName} - ${courseNames}`
    },

    addPlayerToTee(tee, value, indexItems, indexTeeTime, isInput) {
      if(isInput) {
        if(value > 0) {
          this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value
          const players = this.hovering.indexPlayer
          this.handleClickTeeTimeCell(tee)
          this.hovering.indexTeeTime = tee.Id
          if (players.includes(value)) {
            this.hovering.indexPlayer = players.filter(item => item !== value).filter(item => item < value)
            return
          }
          for (let i = 0; i < value; i++) {
            this.hovering.indexPlayer.push(i + 1)
          }
        } else if (value == 0) {
          this.handleCleanTeeTime(tee)
        }
      } else {
        if (tee.BookedNumberPlayer !== 0 && tee.BookedNumberPlayer >= value) {
          tee.BookedNumberPlayer = value - 1
          if (value - 1 === 0) {
            this.handleCleanTeeTime(tee)
          }
        } else {
          this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value
        }
        const players = this.hovering.indexPlayer
        // const oldValue = this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer ? this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer : 0
        this.handleClickTeeTimeCell(tee)

        // if ((oldValue === value && oldValue !== 0) || (oldValue > value)) {
        //   this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value - 1
        //   if (value - 1 === 0) {
        //     this.handleCleanTeeTime(tee)
        //   }
        // } else {
        //   this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value
        // }

        this.hovering.indexTeeTime = tee.Id
        if (players.includes(value)) {
          this.hovering.indexPlayer = players.filter(item => item !== value).filter(item => item < value)
          return
        }

        for (let i = 0; i < value; i++) {
          this.hovering.indexPlayer.push(i + 1)
        }
      }
    },
    filterTeeTimeBooking() {
      if (this.isFilterTeetime && this.timeFilter && this.teeSelect) {
        const itemFilter = this.itemsAll
        this.items = itemFilter.filter(x => x.Time == this.teeSelect.OpenDateTime || x.Time > this.teeSelect.OpenDateTime)
      } else {
        this.items = this.itemsAll
      }
    },
    handerFilterTeeTime() {
      this.isFilterTeetime = !this.isFilterTeetime
      this.filterTeeTimeBooking()
    },
    async getListRate_RRA04A() {
      const body = {
        Date: this.startTime,
        GolfClassId: this.golfClasses.selected?.Id,
        Course: [
          {
            Id: this.courses.selected.Id,
            NumberOfHole: this.courses.selected.holeAmount,
          },
        ],
        WeeklyCode: this.weekLyCode,
      }
      await booking.api_RRA04A(body).then(res => {
        this.ListRate_RRA04A = res.Data.Rate
        // console.log(this.ListRate_RRA04A)
      })
    },
    changeWeedlyCode() {
      const days = [
        'SUN',
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI',
        'SAT',
      ]
      const d = new Date(this.convertUTC(this.startTime, 'YYYY-MM-DD'))
      this.weekLyCode = days[d.getDay()]
    },
    inputRate_RRA04A() {
      this.golfClasses.selected = this.golfClasses.list.find(x => x.Id === this.Selected_Rate_RRA04A.GolfClassId)
      this.companies.selected = this.companies.list.find(x => x.Id === this.Selected_Rate_RRA04A.ClientId)
      this.source.selected = this.source.list.find(x => x.Id === this.Selected_Rate_RRA04A.SourceId)
      this.rateCodes.list = [this.Selected_Rate_RRA04A]
      this.rateCodes.selected = this.Selected_Rate_RRA04A
      this.updateTeeTimeInfo()
      this.updateBookingInfo()
      this.$store.commit('booking/SET_ITEMS_AGENT_INFO', {
        ClientId: (this.companies.selected?.Id) ? this.companies.selected.Id : null,
        SourceId: (this.source.selected) ? this.source.selected.Id : null,
      })
      this.getListTeeTimeBooking_RTB01()
    },
    async OpenAddBooking() {
      this.companies.selected = null
      this.source.selected = null
      this.Selected_Rate_RRA04A = null
      await this.getGolfClasses_RGC01()
      this.getListCompany_RCL01()
      this.getListResources_RSO01()
      await this.getListRate_RRA04()
      await this.getListRate_RRA04A()
      this.updateTeeTimeInfo()
    },
    resetDateSelect() {
      this.startTime = this.date_selected
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/vue-select.scss';

#d-table-add-booking{
  .b-table-sticky-header {
    max-height: calc(95vh - 211px) !important;
  }
  .b-table-sticky-column{
    background: #fff;
    z-index: 99;
  }
}

.d-th{
  min-width: 200px;
}
.d-th:first-child{
  min-width: 100px !important;
  position: sticky;
  left: 0;
  z-index: 999 !important;
}

.hover-me{
  width: 100%;
  text-align: center;
  position: relative;
  height: 36px;
  .d-select-remaning{
    position: absolute;
    top: 9px;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .d-select-tee{
    opacity: 0;
    position: absolute;
    top: 9px;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
.hover-me:hover{
  .d-select-remaning{
    opacity: 0;
  }
  .d-select-tee{
    opacity: 1;
  }
}
.select-time {
  ::v-deep {
    .b-sidebar {
      width: 350px;
    }

    .vs__selected-options {
      // flex-wrap: inherit !important;
      max-width: 100%;
      overflow: hidden;

      .vs__selected {
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
      }
    }
  }
}

.select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .select-block {
    margin-right: 12px;
  }
}

.color-danger {
  color: $danger;
}

.table-max-height {
  height: calc(100%);
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.select-section {
  background-color: white;
  padding: 18px;
}

.bg-step-block {
  background-color: white;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  .text-block {
    padding: 18px 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.blocked-background-color {
  background-color: $tee-sheet-background-gray-color;
}

.banned-background-color {
  background-color: $tee-sheet-background-red-light-color;
}

.selected-background-color {
  background-color: $header-color;
}

.header-table {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

::v-deep {
  .select-opacity {
    .vs__selected {
      opacity: 0.3;
    }
  }
  .b-sidebar {
    width: 350px;
  }

  .vs__selected-options {
    // flex-wrap: inherit !important;
    max-width: 100%;
    overflow: hidden;

    .vs__selected {
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
    }
  }

  .b-table-sticky-header {
    overflow-y: auto;
    max-height: 100%;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .btn-group > .btn {
    flex: unset;
    width: 100%;
  }
}

.text-success {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #29B869
}
.inputRateRRA04A {
  ::placeholder {
    color: #ccc
  }
}

</style>
