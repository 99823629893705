import { commonServices } from '@/api/common-services'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import { booking } from '@/api/booking'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F, A, S,
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'

import EditService from './EditService.vue'

export default {
  name: 'AddGolfService',
  components: {
    ValidationProvider,
    ValidationObserver,
    UserAvatar,
    EditService,
    'confirm-dialog': ConfirmDialog,
    ShortKey
  },
  props: [
    'area',
    'dataDetail',
    'detailBookingId',
  ],
  data() {
    return {
      bookingDetailId: null,
      isOpen: false,
      listService: [],
      zone: [],
      hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      keyWord: null,
      productName: 'Chọn dịch vụ',
      activePosition: {
        ProductMenuId: null,
        ProductId: null,
      },
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F, A, S],
      isLoading: true,
      selectedServices: [],
      TotalAmount: 0,
      service: {},
      zoneId: null,
      allServices: [],
      allChildComponentClosed: [],
      discount: {
        list: [],
      },
      optionDiscountType: [
        {
          value: 'FIX',
          label: this.$t('golf_common_money'),
        },
        {
          value: 'PERCENT',
          label: '%',
        },
      ],
      isOpen: false,
      disableButton: false
    }
  },
  watch: {
    value(data) {
      if (data) {
        this.getServiceNameById(data)
      }
    },
    dataDetail(val) {
      if (this.detailBookingId) {
        // this.detailBookingId = val.BookingDetail.Id
        this.getServiceAdded(this.detailBookingId)
      }
    },
    isOpen(val) {
      this.$emit('is-open', { ComponentId: 'add-more-service-item', IsActive: val })
    },
  },
  computed: {
    fieldsService() {
      return [
        { key: 'ServiceName', label: this.$t('golf_common_service_name') },
        { key: 'UnitPrice', label: this.$t('golf_common_price'), class: 'text-right' },
        { key: 'Quantity', label: this.$t('golf_common_quantity'), class: 'text-right' },
        { key: 'TotalAmount', label: this.$t('golf_common_total'), class: 'text-right' },
        { key: 'action', label: '', class: 'text-right' },
      ]
    },
  },
  created() {
    this.getListZone(this.area)
    if (this.detailBookingId) {
      this.getServiceAdded(this.detailBookingId)
    }
    this.getListReasonDiscount_RRC01()
  },
  mounted() {
  },
  methods: {
    async getListReasonDiscount_RRC01() {
      const response = await booking.getListReasonDiscount({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      })
      if (response.Status === '200') {
        this.discount.list = response?.Data?.Reason
      }
    },
    async getServiceAdded(detailBookingId) {
      await booking.getRBK02C({
        BookingDetail: {
          Id: detailBookingId,
        },
      }).then(response => {
        if (response.Status === '200') {
          this.selectedServices = [...response?.Data?.BookingDetail.BookingDetailServices]

          // Tính tổng số tiền khi xem chi tiết
          this.TotalAmount = 0
          this.selectedServices.forEach(x => {
            this.TotalAmount += x.TotalAmount
          })
        }
      })
    },
    updateBookingService() {
      this.$refs.servicesForm.validate().then(success => {
        if (success) {
          this.disableButton = true
          const data = {
            RetailBy: 'RECEPTIONIST',
            BookingDetail: {
              Id: this.detailBookingId,
              BookingDetailServices: this.params_booking_service(),
            },
          }
          booking.updateBookingService(data).then(res => {
            this.showResToast(res)
          this.disableButton = false
            if (res.Status == '200') {
              // Load lại để lấy giá sau giảm
              this.getServiceAdded(this.detailBookingId)

              this.$emit('event', { type: 'after-add-new-service-item' })
              this.hideSideBar()
            } else if (res.Status == '400' && res.Messages[0].Code === 'E.UBK03C.C02') {
              const dataConfirm = {
                dataConfirm: {
                  title: 'Xóa dịch vụ',
                  content: 'Bạn có muốn xóa dịch vụ khỏi hóa đơn?',
                  data: this.params_booking_service()
                },
              }
              this.$refs.confirmDeleteService.open(dataConfirm)
            }
          })
        }
      })
    },
    params_booking_service() {
      const data = []
      this.selectedServices.forEach((item, index) => {
        const obj = {
          Id: item.Id,
          ServiceId: item.ServiceId,
          UnitPrice: parseFloat(item.UnitPrice),
          Quantity: parseInt(item.Quantity),
          Amount: item.TotalAmount,
          TransactionCode: 'VND',
          TotalAmount: item.TotalAmount,
          RateId: null,
          ServiceType: item.ServiceType,
          Discount: item.Discount,
          ZoneId: parseInt(item.ZoneId),
          RateAdditionalType: item.RateAdditionalType,
          RateAdditionalId: item.RateAdditionalId
          // {
          // "Id": 2,
          // "DiscountCode": "ABC",
          // "Amount": 1000.0000,
          // "DiscountAmount": 50.0000,
          // "DiscountType": "PERCENT",
          // "Reasons": "sax"
          // }
        }
        data.push(obj)
      })
      return data
    },
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'add-more-service-item')
    },
    removeItem(data) {
      if(data.Id) {
        this.selectedServices = this.selectedServices.filter(item => item.Id !== data.Id)
      } else {
        this.selectedServices = this.selectedServices.filter(item => !item.Id && item.ServiceId != data.ServiceId)
      }
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    handleSearch() {
      // const search = new RegExp(this.keyWord, 'i')
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach(y => {
          if (y.ProductNameUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })

      this.listService = tmpAllService
    },
    handleChangeQuantity(evt) {
      if (evt.target.value.trim().length === 0 || parseInt(evt.target.value) < 0) return
      this.selectedServices = this.selectedServices.map(item => {
        // Nếu tồn tại lý do giảm giá và giá trị giảm giá
        if (item.Discount[0].ReasonCode && item.Discount[0].Amount && item.Discount[0].DiscountType) {
          let tmp = 0
          if (item.Discount[0].DiscountType == 'PERCENT') {
            tmp = ((item.UnitPrice * parseInt(item.Quantity)) * parseInt(item.Discount[0].Amount)) / 100
          } else {
            tmp = parseInt(item.Discount[0].Amount)
          }
          item.TotalAmount = (item.UnitPrice * parseInt(item.Quantity)) - tmp
        } else {
          // nếu không có giảm giá
          item.TotalAmount = item.UnitPrice * parseInt(item.Quantity)
        }

        return item
      })
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    handleChangeDiscountAmountAndType() {
      this.selectedServices = this.selectedServices.map(item => {
        // Nếu tồn tại lý do giảm giá và giá trị giảm giá
        if (item.Discount[0].ReasonCode && item.Discount[0].Amount && item.Discount[0].DiscountType) {
          let tmp = 0
          if (item.Discount[0].DiscountType == 'PERCENT') {
            tmp = ((item.UnitPrice * parseInt(item.Quantity)) * parseInt(item.Discount[0].Amount)) / 100
          } else {
            tmp = parseInt(item.Discount[0].Amount)
          }
          item.TotalAmount = (item.UnitPrice * parseInt(item.Quantity)) - tmp
        } else {
          // nếu không có giảm giá
          item.TotalAmount = item.UnitPrice * parseInt(item.Quantity)
        }

        return item
      })
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    async getListZone(area) {
      const bodyArea = {
        KeyGroup: area,
      }
      await commonServices
        .getCommon(bodyArea)
        .then(res => {
          if (res.Status === '200') {
            res.Data.forEach(x => {
              if (x.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY') {
                this.zone.push(x.KeyValue)
              }
            })
          }
        })
      await this.getListService()
    },
    async getListService() {
      this.isLoading = true
      const body = {
        HotelId: this.hotelId,
        ZoneId: this.zone,
      }
      await commonServices
        .getListService(body)
        .then(res => {
          if (res.Status === '200') {
            this.listService = res.Data.Menu
            this.allServices = res.Data.Menu
            if (res.Data.Menu) {
              this.allServices.forEach(x => {
                x.MenuDetail.forEach(y => {
                  y.ProductNameUpperCase = y.ProductName.split(' ').join('').toUpperCase()
                })
              })
            }
            // Hiển thị tên service đã được chọn
            this.getServiceNameById(this.value)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    selectSevice(selectSevice, zoneId) {
      // this.isOpen = false
      this.service = selectSevice
      this.zoneId = zoneId
      this.$bvModal.show('edit-add-more-service-booking')
    },
    handleEvent(result) {
      const selectSevice = result.data
      this.value = selectSevice.ProductId
      const checkExits = this.selectedServices.find(item => item.ServiceId === selectSevice.ProductId)
      if (checkExits) return

      const temp = {}
      temp.Quantity = 1
      temp.TotalAmount = parseInt(selectSevice.ProductPrice)
      temp.ServiceName = selectSevice.ProductName
      temp.ServiceId = selectSevice.ProductId
      temp.UnitPrice = selectSevice.ProductPrice
      temp.ServiceType = selectSevice.ProductType
      temp.ZoneId = this.zoneId
      temp.Discount = [
        {
          DiscountCode: null,
          ReasonCode: null,
          Amount: 0,
          DiscountType: 'PERCENT',
          Reasons: null,
        },
      ]

      this.selectedServices = [...[temp], ...this.selectedServices]
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    async getServiceNameById(serviceId) {
      await this.listService.find(x => {
        x.MenuDetail.find(y => {
          if (y.ProductId === serviceId) {
            this.productName = y.ProductName
            this.activePosition.ProductMenuId = x.ProductMenuId
            this.activePosition.ProductId = y.ProductId
          }
        })
      })
    },
    async removeService(item) {
      const listBookingDetailServiceId = []
      item.dataConfirm.data.forEach(x => {
        if(x.Id) {
          listBookingDetailServiceId.push(x.Id)
        }
      })
      const body = {
        BookingDetailServiceId: listBookingDetailServiceId,
      }
      await booking.api_DPM07(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.updateBookingService()
        }
      })
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.disableButton) {
          this.updateBookingService()
        }
      }
    },
  },
}
