export const add_booking = {
  GroupName: '',
  SourceId: 2,
  SaleId: null,
  TotalNumberOfGolfer: 2,
  Amount: 20000,
  Discount: 0,
  TaxAmount: 0,
  TotalAmount: 20000,
  BookingRef: '',
  BookingInfo: [
    {
      ClientId: 1,
      GolfClassId: 1,
      BookingInfoDate: '2022-04-24',
      NumberOfGolfer: 2,
      RateId: 13,
      TeeTimeId: 30,
      BookingInfoCourse: [
        {
          CourseId: 3,
          NumberOfHole: 9,
        },
      ],
    },
  ],
}
