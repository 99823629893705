<template>
  <b-modal
    id="modal-statistics"
    size="lg"
    centered
    hide-header
    cancel-variant="none"
    cancel-title="Quay lại"
    ok-variant="primary"
    :no-close-on-backdrop="true"
    @ok="hideModal"
    @cancel="hideModal"
  >
    <b-card-header class="banned-background-color-primary">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 title-modal color-primary">
          {{ $t('golf_booking_statistic_title') }}
        </p>
        <feather-icon
          icon="XIcon"
          size="24"
          class="cursor-pointer color-primary"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-table
        :fields="fields"
        style="text-align: center;font-size: 14px;"
        :items="listBookingStatistic"
        bordered
        class="table-parent"
      >
        <template #cell(Title)="data">
          {{ $t(data.item.Title) }}
        </template>
        <template #cell(BOOKED)="data">
          <div class="status-book">
            <p style="font-size: 14px;">
              {{ data.item.BOOKED }}
            </p>
            <b-table
              v-if="data.item.BOOKED && checkDetailGolferStatic == 1"
              :fields="fieldsHole"
              style="text-align: center;font-size: 1rem;"
              :items="[data.item]"
              bordered
            >
            </b-table>
          </div>
        </template>
      </b-table>
      <div v-if="bookingRev.length > 0">
        <b-table-simple
          small
          borderless
          :sticky-header="false"
          :no-border-collapse="false"
          style="max-width: 350px;"
      >
          <b-thead>
              <b-tr class="header-table-custom">
                  <b-th style="font-size: 15px">
                    {{ $t('golf_booking_statistic_booking_rev') }}
                  <!-- {{ $t('golf_booking_tee_picked') }} -->
                  </b-th>
                  <b-th class="text-right" style="font-size: 15px">
                    {{ formatPrice(totalBookingRev()) }}
                  </b-th>
              </b-tr>
          </b-thead>
          <b-tbody>
              <b-tr v-for="(item) in bookingRev" :key="item">
                  <b-td>
                    {{ item.Status }}
                  </b-td>
                  <b-td class="text-right">
                    {{ formatPrice(item.Total) }}
                  </b-td>
              </b-tr>
              
          </b-tbody>
         
      </b-table-simple>
        <!-- <h4>{{ $t('golf_booking_statistic_booking_rev') }}: {{ formatPrice(totalBookingRev()) }}</h4>
        <div v-for="item in bookingRev" :key="item"  class="ml-1">
          <span>{{ item.Status }}</span>: <span style="font-weight: bold;">{{ formatPrice(item.Total) }}</span>
        </div> -->
      </div>
    </b-card-body>
  </b-modal>
</template>
<script>
export default {
  props: [
    'bookingStatusStatistic', 'bookingRev', 'checkDetailGolferStatic'
  ],
  data() {
    return {
      listBookingStatistic: [],
      fields: [
        { key: 'Title', label: this.$t('golf_booking_statistic_shift') },
        { key: 'FLIGHT', label: 'Flight' },
        { key: 'BOOKED', label: this.$t('golf_booking_statistic_booked') },
        { key: 'CHECK_IN', label: 'Check in' },
        { key: 'IN', label: this.$t('golf_booking_statistic_in') },
        { key: 'OUT', label: this.$t('golf_booking_statistic_out') },
        { key: 'CHECK_OUT', label: 'Check Out' },
      ],
      fieldsHole: [
        { key: 'Hole9', label: '9' },
        { key: 'Hole18', label: '18' },
        { key: 'Hole27', label: '27' },
        { key: 'Hole36', label: '36' }
      ],
    }
  },
  methods: {
    open() {
      this.listBookingStatistic = []
      this.bookingStatusStatistic.forEach(x => {
        const item = {
          Title: null,
          BOOKED: null,
          CHECK_IN: null,
          IN: null,
          OUT: null,
          CHECK_OUT: null,
          FLIGHT: null,
          Hole9: 0,
          Hole18: 0,
          Hole27: 0,
          Hole36: 0
        }
        item.Title = x.Title
        x.BookingStatus.forEach(y => {
          if (y.Title == 'BOOKED') {
            item.BOOKED = y.Value
          }
          if (y.Title == 'CHECK_IN') {
            item.CHECK_IN = y.Value
          }
          if (y.Title == 'IN') {
            item.IN = y.Value
          }
          if (y.Title == 'OUT') {
            item.OUT = y.Value
          }
          if (y.Title == 'CHECK_OUT') {
            item.CHECK_OUT = y.Value
          }
          if (y.Title == 'FLIGHT') {
            item.FLIGHT = y.Value
          }
          if (y.Title == 9) {
            item.Hole9 = y.Value
          }
          if (y.Title == 18) {
            item.Hole18 = y.Value
          }
          if (y.Title == 27) {
            item.Hole27 = y.Value
          }
          if (y.Title == 36) {
            item.Hole36 = y.Value
          }
        })
        this.listBookingStatistic.push(item)
      })

      let totalBooked = 0
      let totalCheckIn = 0
      let totalCheckOut = 0
      let totalIn = 0
      let totalOut = 0
      let totalFlight = 0
      let totalHole9 = 0
      let totalHole18 = 0
      let totalHole27 = 0
      let totalHole36 = 0

      this.listBookingStatistic.forEach(x => {
        totalBooked += x.BOOKED
        totalCheckIn += x.CHECK_IN
        totalCheckOut += x.CHECK_OUT
        totalIn += x.IN
        totalOut += x.OUT
        totalFlight += x.FLIGHT
        totalHole9 += x.Hole9
        totalHole18 += x.Hole18
        totalHole27 += x.Hole27
        totalHole36 += x.Hole36

      })

      this.listBookingStatistic.push({
        Title: this.$t('golf_common_total'),
        BOOKED: totalBooked,
        CHECK_IN: totalCheckIn,
        CHECK_OUT: totalCheckOut,
        IN: totalIn,
        OUT: totalOut,
        FLIGHT: totalFlight,
        Hole9: totalHole9,
        Hole18: totalHole18,
        Hole27: totalHole27,
        Hole36: totalHole36,

      })

    },
    totalBookingRev() {
      let total = 0 
      this.bookingRev.forEach(x => {
        total += x.Total
      })
      return total
    },
    hideModal() {
      this.$bvModal.hide('modal-statistics')
      this.listBookingStatistic = []
    },
  },
}
</script>
<style lang="scss">
.status-book {
  table {
    margin-bottom: 0;
    thead {
      th {
        background-color: #fff !important;
      }
    }
  }
}
.table-parent > tbody > tr > td {
  padding: 5px 0 0 0;
}


</style>
