<template>
  <div>
    <b-modal
      id="modal-input-member-to-booking"
      v-model="isOpen"
      size="xl"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <p class="mb-0 title-modal color-primary">
            {{ $t('golf_booking_input_member_to_booking') }}
          </p>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-overlay :show="isLoading" :opacity="0.3" rounded="sm">
        <b-card-body style="max-height: 450px; overflow-y: auto;">
        <div
          v-for="(item ,indexItem) in items" 
          :key="indexItem + 'item'"
          class="checkin-body mt-1"
        >
          <b-row>
            <span class="mb-0 content-body-modal" style="font-weight: bold;font-size: 15px;">
            {{ convertUTC(item.OpenDate, 'DD/MM/YYYY HH:mm') }} -
            {{ item.TeeName }}
          </span>
          </b-row>
            
         
          <b-row
            v-for="(member,index) in item.BookingDetail"
            :key="index +'mem'"
            class="pb-1 pt-1 border-bottom"
          > 
          <b-col
              md="2"
              class="block fullname"
            >
              <div>
                <div style="font-weight: bold" class="text-truncate">
                  {{ member.BookingCode }} - {{ member.GroupName }}
                </div>
                <div>
                  <div class="dot-size-10 d-inline-block rounded-circle"
                                    :style="'background-color: ' + member.GolfClassColor" />
                                  {{ member.GolfClassName }} - BDC: {{ member.BookingDetailCode }} - Hole {{ member.TotalNumberOfHole }}
                </div>
              </div>
            </b-col>
            <b-col
              md="2"
              class="block"
            >
              <b-form-input
                placeholder="Tên khách"
                v-model="member.DataMember.FullName"
                :autofocus="index === 0 && indexItem === 0 ?   true : false "
                :tabindex="member.indexTabAll + 1"
                @keyup.enter="nextForcus(member.indexTabAll + 1)"
                @keyup.down="nextForcusDown(member.indexTabAll + 1)"
                @keyup.up="nextForcusUp(member.indexTabAll + 1)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 1)"
                @keyup.right="nextForcusRight(member.indexTabAll + 1)"
              />
            </b-col>
            <b-col
              md="1"
              class="block"
            >
              <b-form-input
                placeholder="Mã thẻ"
                v-model="member.DataMember.CardCode"
                :tabindex="member.indexTabAll + 2"
                @keyup.enter="nextForcus(member.indexTabAll + 2)"
                @keyup.down="nextForcusDown(member.indexTabAll + 2)"
                @keyup.up="nextForcusUp(member.indexTabAll + 2)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 2)"
                @keyup.right="nextForcusRight(member.indexTabAll + 2)"
              />
            </b-col>
            <b-col
              md="1"
              class="block"
            >
              <b-form-input
                placeholder="SDT"
                v-model="member.DataMember.PhoneNumber"
                :tabindex="member.indexTabAll + 3"
                @keyup.enter="nextForcus(member.indexTabAll + 3)"
                @keyup.down="nextForcusDown(member.indexTabAll + 3)"
                @keyup.up="nextForcusUp(member.indexTabAll + 3)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 3)"
                @keyup.right="nextForcusRight(member.indexTabAll + 3)"
              />
            </b-col>
            <b-col
              md="2"
              class="block"
            >
              <b-form-input
                placeholder="Email"
                v-model="member.DataMember.Email"
                :tabindex="member.indexTabAll + 4"
                @keyup.enter="nextForcus(member.indexTabAll + 4)"
                @keyup.down="nextForcusDown(member.indexTabAll + 4)"
                @keyup.up="nextForcusUp(member.indexTabAll + 4)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 4)"
                @keyup.right="nextForcusRight(member.indexTabAll + 4)"
              />
            </b-col>
            <b-col
              md="1"
              class="block"
            > 
              <v-select 
                v-model="member.DataMember.SexCode" 
                label="KeyValue" 
                :options="listSex"
                :reduce="option => option.KeyCode" 
                :tabindex="member.indexTabAll + 5"
                :clearable="false"
                @keyup.enter="nextForcus(member.indexTabAll + 5)"
                @keyup.down="nextForcusDown(member.indexTabAll + 5)"
                @keyup.up="nextForcusUp(member.indexTabAll + 5)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 5)"
                @keyup.right="nextForcusRight(member.indexTabAll + 5)"
              />
            </b-col>
            <b-col
              md="3"
              class="block d-flex"
            > 
              <v-select 
                style="width: 85%;"
                v-model="member.DataMember.NationalityCode" 
                label="CountryNameVN" 
                :options="countries"
                :reduce="option => option.ID" 
                :tabindex="member.indexTabAll + 6"
                @keyup.enter="nextForcus(member.indexTabAll + 6)"
                @keyup.down="nextForcusDown(member.indexTabAll + 6)"
                @keyup.up="nextForcusUp(member.indexTabAll + 6)"
                @keyup.left="nextForcusLeft(member.indexTabAll + 6)"
                @keyup.right="nextForcusRight(member.indexTabAll + 6)"
              />
              <ez-icon
                icon="ezGolf-icon-trash"
                size="14"
                color="#EA5455"
                class="cursor-pointer ml-1 mt-auto mb-auto"
                @click="removeMember(indexItem,index)"
              />
            </b-col>
            
          </b-row>
        </div>
      </b-card-body>
      </b-overlay>
      
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="none"
            size="md"
            class="float-right"
            :disabled="isLoading"
            @click="hideModal"
          >
          {{ $t('golf_common_back') }}
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Ctrl + S"
            variant="primary"
            size="md"
            class="float-right mr-1"
            :disabled="isLoading"
            @click="save"
          >
          {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import jquery from 'jquery'
import { booking } from '@/api/booking'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { members } from '@/api/members'
export default {
  components: { UserAvatar, ShortKey },
  props: [
    'items',
    'countries',
    'listSex'
  ],
  data() {
    return {
      isOpen: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      inputForcus: false,
      isCheckAll: false,
      iTab: 1,
      isLoading: false
    }
  },
  watch: {
    isOpen(val) {
      if(val) {
        var indexAll = 0
        this.items.forEach((x,indexItem) => {
          x.BookingDetail.forEach((y,indexMember) => {
            if(indexItem === 0 && indexMember === 0) {
              y.indexTabAll = indexAll
            } else {
              indexAll += 6
              y.indexTabAll = indexAll
            }
          })
        })
      }
      this.$emit('is-open', {ComponentId: "modal-input-member-to-booking",IsActive:val});
      this.inputForcus = true
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-input-member-to-booking')
    },
    async checkIn_ICI01() {
      const listBookingDetailIdPrintBDC = []
      const BookingDetail = []
      for (const item of this.items) {
        for (const member of item.Members) {
          if(member.Checked) {
            listBookingDetailIdPrintBDC.push(member.BookingDetail.Id)
          }
          BookingDetail.push({
            Id: member.BookingDetail.Id,
            BagtagCode: member.BagtagCode,
            LockerCode: member.LockerCode,
            SendSMS: this.sendMailCheckin,
            SendEmail: this.sendMailCheckin,
            Bdc: member.BDC
          })
        }
      }
      const data = {
        BookingDetail,
      }
      if(BookingDetail.length > 0) {
        const response = await booking.checkIn(data)
        this.showResToast(response)
        if (response.Status == 200) {
          this.$bvModal.hide('modal-checkin')
          this.$emit('event', { type: 'after-checkin' })
          if(listBookingDetailIdPrintBDC.length > 0) {
            this.$emit('printBDC', { data: listBookingDetailIdPrintBDC })
          }
        }
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_one_person_printbdc'))
      }
    },
    onTriggeredEventHandler(payload) {
      if(payload.evt.ctrlKey && payload.key === S) {
        this.save()
      }
    },
    checkAll(data) {
      this.items.forEach(x => {
        x.Checked = data
        x.Members.forEach(y => {
          y.Checked = data
        })
      })
    },
    checkItem(data) {
      this.items.forEach(x => {
        if(x.OpenDate === data.OpenDate) {
          x.Members.forEach(y => {
            y.Checked = data.Checked
          })
        }
      })
    },
    nextForcus(tabindex) {
      jquery('[tabindex="' + (tabindex+1)  + '"]')[0].focus()
    },
    nextForcusDown(tabindex) {
      jquery('[tabindex="' + (tabindex+6)  + '"]')[0].focus()
    },
    nextForcusUp(tabindex) {
      jquery('[tabindex="' + (tabindex-6)  + '"]')[0].focus()
    },
    nextForcusLeft(tabindex) {
      jquery('[tabindex="' + (tabindex-1)  + '"]')[0].focus()
    },
    nextForcusRight(tabindex) {
      jquery('[tabindex="' + (tabindex+1)  + '"]')[0].focus()
    },
    removeMember(indexItem,index) {
      this.items[indexItem].BookingDetail.splice(index, 1)
      if(this.items[indexItem].BookingDetail.length == 0) {
        this.items.splice(indexItem, 1)
      }
    },
    async save() {
      this.isLoading = true
      var check = false
      const dataRequest = []
      this.items.forEach(x => {
        x.BookingDetail.forEach(y => {
          if(y.DataMember.FullName || y.DataMember.CardCode || y.DataMember.PhoneNumber || y.DataMember.Email) {

          } else {
            check = true
          }
        })
      })
      if(check) {
        this.isLoading = false
        return  this.showToast('error', this.$t('golf_booking_please_input_info'))
      }
      for(let i = 0; i < this.items.length; i ++) {
        for(let y = 0; y < this.items[i].BookingDetail.length; y++) {
          if(this.items[i].BookingDetail[y].DataMember.CardCode || this.items[i].BookingDetail[y].DataMember.PhoneNumber || this.items[i].BookingDetail[y].DataMember.Email) {
            const Member = await this.getListMember_RME01(this.items[i].BookingDetail[y])
            if(Member) {
              await this.addMemberToDetailBooking_UBK09(Member, this.items[i].BookingDetail[y])
            } else if (this.items[i].BookingDetail[y].DataMember.FullName || this.items[i].BookingDetail[y].DataMember.PhoneNumber || this.items[i].BookingDetail[y].DataMember.Email){
              this.items[i].BookingDetail[y].DataMember.FullName = this.items[i].BookingDetail[y].DataMember.FullName ? this.items[i].BookingDetail[y].DataMember.FullName : this.items[i].BookingDetail[y].DataMember.PhoneNumber || this.items[i].BookingDetail[y].DataMember.Email
              await this.addNewMemberToDetailBooking_IME01(this.items[i].BookingDetail[y])
            }
          } else if (this.items[i].BookingDetail[y].DataMember.FullName) {
             await this.addNewMemberToDetailBooking_IME01(this.items[i].BookingDetail[y])
          }
          if(i + 1 == this.items.length && y + 1 == this.items[i].BookingDetail.length) {
            this.$emit('event', { type: 'after-input-member' })
            this.isLoading = false
            this.hideModal()
          }
        }
      }
    },
    async addNewMemberToDetailBooking_IME01(data) {
      const body = {
        BookingDetailId: data.Id,
        Member: {
          FullName: data.DataMember.FullName,
          FirstName: data.DataMember.FirstName,
          LastName: data.DataMember.LastName,
          CardCode: data.DataMember.CardCode,
          SexCode: data.DataMember.SexCode,
          PhoneNumber: data.DataMember.PhoneNumber,
          Email: data.DataMember.Email,
          NationalityCode: data.DataMember.NationalityCode,
        },
      }
      await members.createMember(body).then(res => {
        if (res.Status === '200') {
          this.updateBooking_UBK02(null, null, data)
        } else {
          this.showResToast(res)
        }
      })
    },
    async updateBooking_UBK02(golfClassId, rateId, data) {
      // Nếu là member, có MemberSubscriptionId
      if (data.MemberSubscriptionId) {
        const params = {
          BookingDetail: {
            Id:data.Id,
            ClientId: data.ClientId,
            SourceId: data.SourceId,
            // GolfClassId: golfClassId,
            TeeTimeId: data.TeeTimeId,
            // RateId: rateId,
            CardCode4GolfClassRule: null,
            Bdc4golfClassRule: null,
            BookingDetailCourse: data.BookingDetailCourse.map(x => ({CourseId: x.CourseId , NumberOfHole: x.NumberOfHole, ActualNumberOfHole: x.ActualNumberOfHole}))
          },
        }
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          params.BookingDetail.GolfClassId = golfClassId
          params.BookingDetail.RateId = rateId
        } else {
        // Nếu không có rate id và golf class id
          params.BookingDetail.GolfClassId = data.GolfClassId
          params.BookingDetail.RateId = -1
        }
        await booking.updateTeeTimeForBooking(params)
        if (!golfClassId || !rateId) {
          this.showToast('error', this.$t('golf_common_please_select_golfclass'))
        }
        this.$emit('event', { type: 'after-change-rate-golf-class-from-select-add-new-member' })
      } else {
        // Nếu là Guest
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          const params = {
            BookingDetail: {
              Id: data.Id,
              ClientId: data.ClientId,
              SourceId: data.SourceId,
              GolfClassId: golfClassId,
              TeeTimeId: data.TeeTimeId,
              RateId: rateId,
              CardCode4GolfClassRule: null,
              Bdc4golfClassRule: null,
              BookingDetailCourse: data.BookingDetailCourse.map(x => ({CourseId: x.CourseId , NumberOfHole: x.NumberOfHole, ActualNumberOfHole: x.ActualNumberOfHole}))
            },
          }
          await booking.updateTeeTimeForBooking(params)
        }
      }
    },
    async addMemberToDetailBooking_UBK09(item, data) {
      let newCardCode = null
      let rateId = null
      let golfClassId = null
      // Khi nhập số thẻ
      // Tìm số thẻ phù hợp và trạng thái không bị truất quyền, nếu thấy thì kết quả phù hợp, không thông báo
      if (item.MemberSubscription.filter(x => x.Status == 'VALID' && x.CardCode && data.DataMember.CardCode && x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).length) {
        newCardCode = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).Id
        golfClassId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).MemberCard.GolfClassId
        rateId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).MemberCard.RateId[0]
      } else {
        // Không phù hợp thì hiện thông báo, chỉ hiện thông báo khi có giá trị cardCode
        if (data.DataMember.CardCode) {
          let error = ''
          if (item.MemberSubscription.filter(x => x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).length) {
            if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).Status == 'DEPOSITION') {
              error = this.$t('golf_booking_card_code_deposition')
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).Status == 'EXPIRED') {
              error = this.$t('golf_booking_card_code_expired')
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == data.DataMember.CardCode.toUpperCase()).Status == 'TURNOFF') {
              error = this.$t('golf_booking_card_code_turnoff')
            }
          }
          // this.showToast('error', `Mã thẻ ${data.DataMember.CardCode} đã ${error} và sẽ được thay thế bằng thẻ khác nếu có.`)
          this.showToast('error', this.replaceString(this.replaceString(this.$t('golf_booking_error_select_card_code'),'{CardCode}',data.DataMember.CardCode),'{error}',error))
        }

        // Chọn thẻ đầu tiên nếu có, nếu không có thẻ phù hợp
        if (item.MemberSubscription.filter(x => x.Status == 'VALID').length == 1) {
          newCardCode = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].Id
          golfClassId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.GolfClassId
          rateId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.RateId[0]
        }
        
      }
      
      const body = {
        BookingDetail: {
          Id: data.Id,
          MemberId: item.Id,
          MemberSubscriptionId: newCardCode,
        },
        IsForce: true,
      }
      await booking.api_UBK09(body).then(async res => {
        if (res.Status == 200) {
          // Nếu member đã được add
          await this.updateBooking_UBK02(golfClassId, rateId, data)
        } else {
          this.showResToast(res)
        }
      })
    },
    async getListMember_RME01(data) {
        const requestData = {
          MemberName: '',
          CardCode: data.DataMember.CardCode,
          CardTypeId: null,
          MemberType: null,
          Email: data.DataMember.Email,
          PhoneNumber: data.DataMember.PhoneNumber,
          BookingDetailId: null,
          Vgacode: null
        }

        const response = await members.getListMember(requestData)
        if (response.Status === '200') {
          if(response.Data.Member.length > 0) {
            return response.Data.Member[0]
          } else {
            this.showToast('error', `Không có khách phù hợp với ${data.DataMember.CardCode ? data.DataMember.CardCode : ''} ${data.DataMember.Email ? data.DataMember.Email : ''} ${data.DataMember.PhoneNumber ? data.DataMember.PhoneNumber : ''}` )
          }
        } else {
          this.showResToast(res)
        }
    },
    replaceString(string, oldValue, newValue) {
      return string.replace(oldValue, newValue)
    },
  },
}
</script>

<style lang="scss">
#modal-checkin {
  .modal-checkin {
        width: calc(100vw*75/100);
        max-width: 890px
  }
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .color-primary {
    color: #114A9F;
  }

  .w-50 {
    width: 65px !important;
  }

  .color-warning {
    color: #FF9F43;
  }

  .content-body-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .fullname {
    width: 160px;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      padding: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}
</style>
