export default {
  name: 'BookingOverview',
  components: {
  },
  props: [
    'teeSheet',
    'listTee',
    'filterBy',
    'chooseDate',
    'timeMorningEnd',
    'timeNightStart'
  ],
  data() {
    return {
      filterTeeSheet: this.teeSheet,
      moringTime: this.dayjs(this.chooseDate).utc().add(Number(this.timeMorningEnd), 'minutes').format(),
      afternoonTime: this.dayjs(this.chooseDate).utc().add(Number(this.timeNightStart), 'minutes').format(),
      nightTime: this.dayjs(this.chooseDate).utc().add(24, 'hours').format(),
    }
  },
  watch: {
    filterBy() {
      if (this.filterBy == 'golf_booking_morning_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() < this.dayjs(this.moringTime).unix())
      } else if (this.filterBy == 'golf_booking_afternoon_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.moringTime).unix() && this.dayjs(x.Time).unix() < this.dayjs(this.afternoonTime).unix())
      } else if (this.filterBy == 'golf_booking_night_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.afternoonTime).unix() && this.dayjs(x.Time).unix() <= this.dayjs(this.nightTime).unix())
      } else {
        this.filterTeeSheet = this.teeSheet
      }
    },
    teeSheet() {
      if (this.filterBy == 'golf_booking_morning_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() < this.dayjs(this.moringTime).unix())
      } else if (this.filterBy == 'golf_booking_afternoon_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.moringTime).unix() && this.dayjs(x.Time).unix() <= this.dayjs(this.afternoonTime).unix())
      } else if (this.filterBy == 'golf_booking_night_shift') {
        this.filterTeeSheet = this.teeSheet.filter(x => this.dayjs(x.Time).unix() > this.dayjs(this.afternoonTime).unix() && this.dayjs(x.Time).unix() <= this.dayjs(this.nightTime).unix())
      } else {
        this.filterTeeSheet = this.teeSheet
      }
    },
    chooseDate() {
      this.moringTime = this.dayjs(this.chooseDate).utc().add(Number(this.timeMorningEnd), 'minutes').format()
      this.afternoonTime = this.dayjs(this.chooseDate).utc().utc().add(Number(this.timeNightStart), 'minutes').format()
      this.nightTime = this.dayjs(this.chooseDate).utc().add(24, 'hours').format()
    },
  },
  created() {
  },
  methods: {
    viewDetailBoooking(bookingDetailId, teeTimeId) {
      this.$emit('event', { type: 'overview-view-detail-booking', bookingDetailId, teeTimeId })
    },
    addNewBooking(tee) {
      this.$emit('event', { type: 'overview-add-new-booking', tee })
    },
  },
}
