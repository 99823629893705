<template>
  <div>
    <b-modal
      id="modal-change-bdc"
      size="md"
      v-model="isOpen"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 color-primary">
            {{ $t('golf_booking_change_bdc') }}
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer color-primary"
            size="24"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div class="form">
          <div class="form-block">
            <b-form-group :label="$t('golf_booking_input_bdc')">
              <b-form-input
                autofocus
                v-model="reason"
                :placeholder="$t('golf_booking_input_bdc')"
              />
            </b-form-group>
          </div>
        </div>
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="noShowBooking_UBK08"
          >
            {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :is-active="isOpen"
      :all-child-component-closed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>

import { booking } from '@/api/booking'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
  components: {
    ShortKey
  },
  props: [
    'data',
  ],
  data() {
    return {
      reason: null,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      isOpen: false
    }
  },
  watch: {
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-change-bdc",IsActive:val});
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-change-bdc')
    },
    async noShowBooking_UBK08() {
      if(!this.reason) {
        return this.showToast('error', this.$t('golf_booking_please_input_bdc'))
      }
      const dataRequest = {
        BookingDetailId: this.data.BookingDetail.Id,
        BDC: this.reason
      }

      const response = await booking.api_UBK17(dataRequest)
      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'after-change-bdc' })
        this.hideModal()
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.noShowBooking_UBK08()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.color-primary {
    color: #114A9F;
  }
</style>
