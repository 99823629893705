import vSelect from 'vue-select'
import { courseSetting } from '@/api/course-setting'
import { booking } from '@/api/booking'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { getCssText } from '@/api/css-text'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, P,
} from '@/api/constant.js'
import jquery from 'jquery'
import History from '@/views/modules/booking/modal/History'
import PrintBDC from './modal/PrintBDC.vue'
import Payment from './Payment/Payment.vue'
import DeletePayment from './Payment/DeletePayment.vue'
import DeletePaymentDetail from './Payment/DeletePaymentDetail.vue'
import UserAvatar from './UserAvatar.vue'
import SidebarDetailBooking from './SidebarDetailBooking/SidebarDetailBooking.vue'
import SidebarAddBooking from './SidebarAddBooking/SidebarAddBooking.vue'
import SidebarCheckout from './SidebarCheckout/SidebarCheckout.vue'
import ModalDistribution from './ModalDistribution/ModalDistribution.vue'
import BookingOverview from './BookingOverview/BookingOverview.vue'
import ModalBookingStatistics from './modal/BookingStatistics.vue'

export default {
  name: 'Booking',
  components: {
    BookingOverview,
    UserAvatar,
    DeletePaymentDetail,
    DeletePayment,
    Payment,
    SidebarDetailBooking,
    SidebarAddBooking,
    SidebarCheckout,
    ModalDistribution,
    vSelect,
    ModalBookingStatistics,
    ShortKey,
    PrintBDC,
    History
  },
  data() {
    return {
      chooseDate: this.getUTC('start', 'day'),
      selectedCourse: [],
      courses: [],
      teeSheet: [],
      teeSheetFilter: [],
      maxLength: 10,
      addMember: null,
      paymentData: {
        paymentId: null,
      },
      delete_paymentId: null,
      delete_paymentDetailId: null,
      BookingDetail: null,
      closePay: false,
      bookingId: null,
      bookingDetailId: null,
      teeTimeId: null,
      bookingDetailData: null, // là object bao gồm booking detail id và tee time id,
      paymentId: null,
      deletePaymentId: null,
      deletePaymentDetailId: null,
      BookingRef: '',
      reRenderAddBooking: 0,
      bookingDetailIdDistribution: null,
      BookingCodeDistribution: null,
      BookingStatusStatistic: null,
      bdcForBookingLink: null,
      currentTeeTimeId: null,
      isLoading: false,
      teeSelect: null,
      isOpenSileBarDetail: null,
      bookingDetailprops: null,
      KeyWordDataProps: null,
      allChildComponentClosed: [
        {
          ComponentId: 'sidebar-detail-booking',
          IsActive: false,
        },
        {
          ComponentId: 'sidebar-checkout',
          IsActive: false,
        },
        {
          ComponentId: 'modal-payment',
          IsActive: false,
        },
        {
          ComponentId: 'sidebar-add-booking',
          IsActive: false,
        },
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, P],
      defaultHoleAddBooking: null,
      ROLE_OF_SALE: null,
      ROLE_OF_GM: null,
      ROLE_OF_CASHIER: null,
      listUserOfSale: null,
      listUserOfGM: null,
      listUserOfCashier: null,
      quickCheckOut: null,
      listBookingDetailIdQuickCheckOut: null,
      defaultPrintBDCinCheckIn: null,
      BookingDetailIdPropsPayment: null,
      viewBy: 'CLASSIC', // CLASSIC || OVERVIEW
      listTee: null,
      filterBy: [],
      defaultCheckIn: null,
      paymentSourceId: null,
      paymentClientId: null,
      moringTime: null,
      afternoonTime: null,
      nightTime: null,
      isClearDataBookingDetail: false,
      isCreateDefaultBdc: null,
      quickViewListBDC: null,
      TimeShiftMorningEnd: null,
      TimeShiftNightStart: null,
      ColorBookingDetail: {
        CHECK_IN: null,
        CHECK_OUT: null,
        TEEOFF: null,
        FINISH: null,
        BOOKED: null
      },
      IsPaymentAutoLock: null,
      currentFocus: null,
      IsShowBagtag: null,
      IsAutoDeleteServiceNoFolio: null,
      isShowNoteInCheckOut: null,
      isShowNoteTeeSheet: null,
      isShowNoteGroupTeeSheet: null,
      isShowTaInTeeSheet: null,
      isShowRateInTeeSheet: null,
      isShowRateInCheckOut: null,
      dataRBK02D: [],
      isShowPrintListInvoice: null,
      dataHistory: null,
      isShowHistoryInTeeSheet: null,
      colorBDC: null,
      listStatusProjectedRevenue: null,
      listBookingRev: [],
      bookingShowIconGolfer: null,
      manualSearchFromBDCBagtagNumber: null,
      colorCaddyBooking: null,
      ListBookingStatus: [
        {
          label: 'Booked',
          value: 'BOOKED',
          check: false
        },
        {
          label: 'CheckIn',
          value: 'CHECK_IN',
          check: false
        },
        {
          label: 'CheckOut',
          value: 'CHECK_OUT',
          check: false
        },
        {
          label: 'No-Show',
          value: 'NO_SHOW',
          check: false
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
          check: false
        }
      ],
      listStatusDefault: null,
      isShowBDC: null,
      colorNote: null,
      backgroundColorNote: null,
      colorNoteGroup: null,
      backgroundColorNoteGroup: null,
      ischeckTurnTime: null,
      TemBDCFormat: null,
      checkDetailGolferStatic: null ,
      CheckOutcolorBDC: null
    }
  },
  created() {
    this.isLoading = true
    if (this.$route.query.bdc) {
      this.teeTimeId = null
      this.bookingDetailId = null
      this.bookingId = null
      this.bookingDetailprops = null
      this.KeyWordDataProps = null

      this.quickViewListBDC = this.$route.query.bdc
      setTimeout(() => {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      }, 1)
    }
    this.getListCourse()
    this.getConfigInfo_RCOM01()
    this.isLoading = false
  },

  watch: {
    chooseDate() {
      this.moringTime = this.dayjs(this.chooseDate).utc().add(Number(this.TimeShiftMorningEnd), 'minutes').format()
      this.afternoonTime = this.dayjs(this.chooseDate).utc().add(Number(this.TimeShiftNightStart), 'minutes').format()
      this.nightTime = this.dayjs(this.chooseDate).utc().add(24, 'hours').format()

      this.getListBooking_RTS01()
    },
    selectedCourse() {
      this.getListBooking_RTS01()
    },
    ROLE_OF_SALE(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_GM(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_CASHIER(value) {
      this.getCashierRole(value)
    },
    filterBy() {
      if (this.filterBy == 'golf_booking_morning_shift') {
        this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() < this.dayjs(this.moringTime).unix())
      } else if (this.filterBy == 'golf_booking_afternoon_shift') {
        this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.moringTime).unix() && this.dayjs(x.Time).unix() < this.dayjs(this.afternoonTime).unix())
      } else if (this.filterBy == 'golf_booking_night_shift') {
        this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.afternoonTime).unix() && this.dayjs(x.Time).unix() <= this.dayjs(this.nightTime).unix())
      } else {
        this.teeSheetFilter = this.teeSheet
      }
    },
  },
  methods: {
    close(val) {
      this.closePay = !this.closePay
    },
    async getListBooking_RTS01() {
      if (!this.checkPermission('RTB01')) {
        this.isLoading = false
        return
      }

      this.isLoading = true
      const ChooseDate = this.chooseDate
      const CourseId = this.selectedCourse.map(x => x.Id)
      this.BookingStatusStatistic = []
      this.listBookingRev = []
      const data = {
        Date: String(ChooseDate),
        CourseId,
        Status: this.listStatusProjectedRevenue
      }
      const response = await booking.getListBooking(data)
      if (response.Status === '200') {
        this.BookingStatusStatistic = response?.Data?.BookingStatusStatistic
        this.teeSheet = response?.Data?.TeeSheet
        this.listBookingRev = response?.Data?.BookingRev
        // Lọc theo ca
        if (this.filterBy == 'golf_booking_morning_shift') {
          this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() < this.dayjs(this.moringTime).unix())
        } else if (this.filterBy == 'golf_booking_afternoon_shift') {
          this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.moringTime).unix() && this.dayjs(x.Time).unix() < this.dayjs(this.afternoonTime).unix())
        } else if (this.filterBy == 'golf_booking_night_shift') {
          this.teeSheetFilter = this.teeSheet.filter(x => this.dayjs(x.Time).unix() >= this.dayjs(this.afternoonTime).unix() && this.dayjs(x.Time).unix() <= this.dayjs(this.nightTime).unix())
        } else {
          this.teeSheetFilter = this.teeSheet
        }

        // Lấy thông tin tee dài nhất
        this.listTee = this.teeSheet.reduce(
          (a, b) => (a.TeeTime.length > b.TeeTime.length ? a : b),
        )

        let maxLength = 4
        response?.Data?.TeeSheet.map(item => {
          item.TeeTime.map(tee => {
            if (maxLength < tee.BookingDetail.length) {
              maxLength = tee.BookingDetail.length
            }
          })
        })
        this.maxLength = maxLength
      }
      this.isLoading = false
    },
    clickViewBy() {
      if (this.viewBy == 'CLASSIC') {
        this.viewBy = 'OVERVIEW'
      } else {
        this.viewBy = 'CLASSIC'
      }
    },
    clickFilterBy(value) {
      if (this.filterBy.filter(x => x == value).length) {
        const index = this.filterBy.indexOf(value)
        if (index > -1) { // only splice array when item is found
          this.filterBy.splice(index, 1) // 2nd parameter means remove one item only
        }
      } else {
        this.filterBy = []
        this.filterBy.push(value)
      }
    },
    async getListCourse() {
      this.checkPermission('RCO01')

      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.currentFocus = res?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
          this.colorBDC = res?.Data.find(x => x.KeyCode === 'BOOKING_BDC_COLOR').KeyValue
          this.CheckOutcolorBDC = res?.Data.find(x => x.KeyCode === 'BOOKING_CHECKOUT_BDC_COLOR').KeyValue
          this.isShowBDC = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_BDC_IN_TEESHEET').KeyValue
          this.ischeckTurnTime = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_TURN_TIME').KeyValue
          this.bookingShowIconGolfer = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_ICON_GOLFER').KeyValue
          this.isShowHistoryInTeeSheet = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_HISTORY_IN_TEESHEET').KeyValue
          this.isShowNoteInCheckOut = res?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_ADD_DISCOUNT_TO_NOTE').KeyValue
          this.isShowNoteTeeSheet = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_NOTE_TEESHEET').KeyValue
          this.TemBDCFormat = JSON.parse(res?.Data.find(x => x.KeyCode === 'PRINT_BDC_FORMAT').KeyValue)
          this.checkDetailGolferStatic = JSON.parse(res?.Data.find(x => x.KeyCode === 'BOOKING_STATICTIS_DETAIL_GOLFER_BY_HOLE').KeyValue)
          this.isShowNoteGroupTeeSheet = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_NOTE_OF_GROUP_TEESHEET').KeyValue
          this.IsShowBagtag = res?.Data.find(x => x.KeyCode === 'BOOKING_CHECK_OUT_SHOW_BAGTAG').KeyValue
          this.IsAutoDeleteServiceNoFolio = res?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_CLEAR_FAIL_SERVICE_FROM_BAR').KeyValue
          this.defaultHoleAddBooking = parseInt(res?.Data.find(item => item.KeyCode === 'NUMBER_OF_HOLE_4_BOOKING').KeyValue)
          this.ROLE_OF_SALE = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_SALE').KeyValue)
          this.ROLE_OF_GM = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_GM').KeyValue)
          this.colorCaddyBooking = res?.Data.find(item => item.KeyCode === 'BOOKING_CADDY_BOOKED_COLOR').KeyValue
          this.ROLE_OF_CASHIER = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
          this.defaultCheckIn = res?.Data.find(item => item.KeyCode === 'DEFAULT_CHECKIN').KeyValue
          this.defaultPrintBDCinCheckIn = res?.Data.find(item => item.KeyCode === 'PRINT_BDC').KeyValue
          this.listStatusDefault = JSON.parse(res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_SHOW_IN_PAYMENT').KeyValue)
          this.manualSearchFromBDCBagtagNumber = Number(res?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_SEARCH_FROM_BDC_BAGTAG_NUMBERS').KeyValue)
          this.isCreateDefaultBdc = res?.Data.find(item => item.KeyCode === 'BOOKING_AUTO_CREATE_BDC').KeyValue
          this.TimeShiftMorningEnd = res?.Data.find(item => item.KeyCode === 'BOOKING_SHIFT_MORNING_END').KeyValue
          this.TimeShiftNightStart = res?.Data.find(item => item.KeyCode === 'BOOKING_SHIFT_NIGHT_START').KeyValue
          this.isShowTaInTeeSheet = res?.Data.find(item => item.KeyCode === 'BOOKING_SHOW_TA_IN_TEESHEET').KeyValue
          this.isShowRateInTeeSheet = res?.Data.find(item => item.KeyCode === 'BOOKING_SHOW_RATE_IN_TEESHEET').KeyValue
          this.isShowRateInCheckOut = res?.Data.find(item => item.KeyCode === 'BOOKING_SHOW_RATE_IN_PAYMENT').KeyValue
          this.listStatusProjectedRevenue = res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_4_STATICTIS_PROJECTED_REVENUE').KeyValue.split(',')
          this.backgroundColorNote = res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_BACKGROUND_COLOR') ? res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_BACKGROUND_COLOR').KeyValue : null
          this.colorNote = res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_COLOR') ? res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_COLOR').KeyValue : null
          this.backgroundColorNoteGroup = res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_BACKGROUND_COLOR') ? res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_BACKGROUND_COLOR').KeyValue : null
          this.colorNoteGroup = res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_COLOR') ? res?.Data.find(x => x.KeyCode === 'BOOKING_NOTE_OF_GROUP_COLOR').KeyValue : null
          this.ColorBookingDetail = {
            CHECK_IN: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CHECK_IN_COLOR').KeyValue,
            CHECK_OUT: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CHECK_OUT_COLOR').KeyValue,
            TEEOFF: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_TEEOFF_COLOR').KeyValue,
            FINISH: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_FINISH_COLOR').KeyValue,
            BOOKED: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_BOOKED_COLOR').KeyValue,
            CANCELLED: res?.Data.find(item => item.KeyCode === 'BOOKING_STATUS_CANCELLED_COLOR').KeyValue,
          }
          this.ListBookingStatus.forEach(x => {
            if(this.listStatusDefault.find(y => y == x.value)) {
              x.check = true
            }
          })

          this.moringTime = this.dayjs(this.getUTC('start', 'day')).utc().add(Number(this.TimeShiftMorningEnd), 'minutes').format()
          this.afternoonTime = this.dayjs(this.getUTC('start', 'day')).utc().add(Number(this.TimeShiftNightStart), 'minutes').format()
          this.nightTime = this.dayjs(this.getUTC('start', 'day')).utc().add(24, 'hours').format()
        }
      })

      const response = await courseSetting.getListCourse()
      if (response.Status === '200') {
        if (this.defaultHoleAddBooking) {
          this.courses = response?.Data?.Course.map(x => Object.assign(x, { holeAmount: this.defaultHoleAddBooking }))
        } else {
          this.courses = response?.Data?.Course.map(x => Object.assign(x, { holeAmount: x.NumberOfHoles }))
        }
        this.selectedCourse = response?.Data?.Course // .push(response?.Data?.Course[0])
      }
    },
    async getConfigInfo_RCOM01() {
      const responsePaymentConfig = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      if(responsePaymentConfig.Status === '200') {
        this.IsPaymentAutoLock = responsePaymentConfig?.Data.find(x => x.KeyCode === 'PAYMENT_AUTO_LOCK').KeyValue
        this.isShowPrintListInvoice = responsePaymentConfig?.Data.find(x => x.KeyCode === 'PAYMENT_ALLOW_PRINT_LIST_INVOICE').KeyValue
      }

    },
    viewDetailBooking(bookingDetailId, teeTimeId) {
      this.quickViewListBDC = null
      this.handleEvent({ type: 'view-detail-booking', bookingDetailId, teeTimeId })
    },
    openModalDistribution() {
      this.handleEvent({ type: 'open-modal-distribution' })
    },
    async openModalStatistics() {
      await this.getListBooking_RTS01()
      this.handleEvent({ type: 'open-modal-statistics' })
    },
    handleEvent(result) {
      if (result.type) {
        // Phân bổ số tiền đã thanh toán trước cho đoàn
        if (result.type === 'open-modal-distribution') {
          this.BookingCodeDistribution = null
          this.bookingDetailIdDistribution = null
          this.$root.$emit('bv::show::modal', 'modal-distribution')
        }
        // Phân bổ số tiền đã thanh toán trước cho 1 người
        if (result.type === 'open-modal-distribution-single') {
          this.BookingCodeDistribution = null
          this.bookingDetailIdDistribution = result.bookingDetailIdDistribution
          this.$root.$emit('bv::show::modal', 'modal-distribution')
        }
        if (result.type === 'open-modal-distribution-group') {
          this.bookingDetailIdDistribution = null
          this.BookingCodeDistribution = result.BookingCodeDistribution
          this.$root.$emit('bv::show::modal', 'modal-distribution')
        }
        // Cập nhật lại booking detail id đang active để khi reload lại không bị mất người đang xem.
        if (result.type === 'update-current-booking-detail-id') {
          this.bookingDetailId = result.bookingDetailId
        }

        if (result.type === 'after-add-member-to-detail-booking' || result.type === 'after-add-new-member-to-detail-booking' || result.type === 'after-checkout' || result.type === 'after-checkin' || result.type === 'after-cancel-booking' || result.type === 'after-cancel-booking-group' || result.type === 'after-update-group-info' || result.type === 'after-update-booking-tee-time' || result.type === 'after-combine-group' || result.type === 'after-split-group' || result.type === 'after-update-hole' || result.type === 'after-checkout-from-sidebar-checkout' || result.type === 'after-change-rate-golf-class-from-select-add-new-member' || result.type === 'after-undo-checkout' || result.type === 'after-add-caddy' || result.type === 'after-update-add-caddy') {
          this.getListBooking_RTS01()
        }
        if (result.type === 'after-update-booking-tee-time') {
          this.teeTimeId = result.newTeeTimeId
        }
        // Thay đổi thông tin chơi, thay đổi tee time
        if (result.type === 'open-model-history') {
          this.viewHistory(false,result.data)
        }

        // Sau khi huỷ booking, load lại RRTB để lấy lại danh sách tee time
        if (result.type === 'after-cancel-booking' || result.type === 'after-cancel-booking-group') {
          this.reRenderAddBooking++
        }

        // Tạo booking từ màn overview
        if (result.type === 'overview-add-new-booking') {
          this.openSidebarAddBooking(true, result.tee)
        }

        // Sau khi tạo booking mới
        if (result.type === 'after-add-new-booking') {
          // Load lại danh sách booking
          this.getListBooking_RTS01()

          // Load lại 2A từ bookingId mới được tạo
          this.bookingId = result.BookingId
          this.bookingDetailId = null

          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
        }

        // Sau khi thêm khách vào booking đoàn đã có trước đó
        if (result.type === 'after-create-add-member-to-detail-booking') {
          // Load lại danh sách booking
          this.getListBooking_RTS01()

          // Load lại 2A từ bookingId mới được tạo
          this.bookingId = result.BookingId
          this.$refs.SidebarDetailBooking.getDetailBooking2A()

          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
        }

        if (result.type === 'view-detail-booking' || result.type === 'overview-view-detail-booking') {
          this.bookingId = null
          if (result.bookingDetailId) {
            this.bookingDetailId = result.bookingDetailId
          }

          if (result.teeTimeId) {
            this.teeTimeId = result.teeTimeId
          }

          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
        }

        if (result.type === 'open-sidebar-checkout') {
          this.bookingDetailprops = result.bookingDetailprops
          this.KeyWordDataProps = result.KeyWordDataProps
          this.bookingDetailData = result.bookingDetailData
          this.$root.$emit('bv::toggle::collapse', 'sidebar-checkout')
          this.isOpenSileBarDetail = true
        }
        if (result.type === 'close-sidebar-checkout') {
          this.bookingDetailId = this.bookingDetailprops.Id
          this.teeTimeId = this.bookingDetailprops.TeeTimeId
          setTimeout(() => {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
            this.$refs.SidebarDetailBooking.AfterCloseSidebarCheckout()
          }, 500)
        }

        if (result.type === 'open-modal-payment') {
          this.BookingDetailIdPropsPayment = result.BookingDetailId
          this.paymentId = result.paymentId
          this.quickCheckOut = result.quickCheckOut
          this.listBookingDetailIdQuickCheckOut = result.listBookingDetailIdQuickCheckOut
          this.paymentSourceId = result.sourceId
          this.paymentClientId = result.clientId
          this.dataRBK02D = result.dataRBK02D
          this.$root.$emit('bv::show::modal', 'modal-payment')
          return
        }

        if (result.type === 'open-modal-delete-payment') {
          this.deletePaymentId = result.paymentId
          this.$root.$emit('bv::show::modal', 'modal-delete-payment')
        }

        if (result.type === 'open-modal-delete-payment-detail') {
          this.deletePaymentDetailId = result.paymentDetailId
          this.$root.$emit('bv::show::modal', 'modal-delete-payment-detail')
        }

        if (result.type === 'after-delete-payment' || result.type === 'after-add-new-payment-detail' || result.type === 'after-delete-payment-detail') {
          // Đổi trạng thái closePay để load lại 2D, lấy danh sách các lần thanh toán
          this.closePay = !this.closePay
          // Load lại danh sách các hoá đơn tạm tính
          this.$refs.sidebarCheckout.getRPM01()
          // Nếu là xoá các thanh toán cũng load lại hoá đơn tạm tính
          if (result.type === 'after-delete-payment-detail') {
            // Load lại danh sách các lần thanh toán của hoá đơn tạm tính
            this.$refs.sidebarCheckout.RefreshAfterDeletePayment()
            // this.RefreshAfterDeletePayment()
          }
        }
        if(result.type === 'refresh-after-delete-payment-detail') {
          this.$refs.modalPayment.getDetailPayment_RPM04()
        }

        // Thêm khách vào booking từ màn chi tiết booking
        if (result.type && result.type === 'add-more-customer-from-detail-booking') {
          this.addMember = result.dataDetail2B
          this.openSidebarAddBooking(false)
        }

        // Thêm mới booking từ màn chi tiết booking
        if (result.type && result.type === 'add-new-booking-from-detail-booking') {
          this.openSidebarAddBooking(true)
        }

        // mở modal thống kê booking
        if (result.type === 'open-modal-statistics') {
          this.$root.$emit('bv::show::modal', 'modal-statistics')
          this.$refs.modalBookingStatistics.open()
        }
      }
    },
    showHour(nextHour, oldHour) {
      const next = this.dayjs(nextHour, 'HH:mm').format('HH')
      const old = this.dayjs(oldHour, 'HH:mm').format('HH')
      if (next !== old && !this.nextLine.includes(next)) {
        return `${next}:00`
      }

      return false
    },
    showStartHour(nextHour) {
      const next = this.dayjs(nextHour, 'HH:mm').format('HH')
      return `${next}:00`
    },
    openSidebarAddBooking(isReset, tee) {
      this.teeSelect = tee
      this.BookingRef = self.crypto.randomUUID()
      // Reset addMember trường hợp đã tạo thêm booking cho đoàn
      if (isReset) {
        this.addMember = null
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-add-booking')
    },
    async addBookingLink_UBK12(teeTimeId) {
      const response = await booking.api_UBK12({ BDC: this.bdcForBookingLink, TeeTimeId: teeTimeId })
      // Reset mã nếu thành công
      this.bdcForBookingLink = null
      this.showResToast(response)
      if (response.Status === '200') {
        this.getListBooking_RTS01()
      }
    },
    async removeBookingLink_UBK13(teeTimeId, bookingDetailId) {
      const response = await booking.api_UBK13({ BookingDetailId: bookingDetailId, TeeTimeId: teeTimeId })
      this.showResToast(response)
      if (response.Status === '200') {
        // this.isLoading = true
        // alert(this.isLoading)
        await this.getListBooking_RTS01()
        // this.isLoading = false
      }
    },
    printBooking() {
      const prtHtml = document.getElementById('booking-print').innerHTML
      let stylesHtml = `<style type="text/css">.ez-icon {display: none} ${getCssText()}</style>`
      for (const node of [...document.querySelectorAll('link')]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          <div style="text-align: center; margin: 10px">
            <h2>Danh sách booking</h2>
            <i>Thời gian in: ${this.utcConvertToLocalTimeZone(new Date(), 'DD/MM/YYYY HH:mm:ss')}</i>
          </div>
          ${prtHtml}
          <style>@media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} .table-responsive {overflow-x: hidden;}}</style>
          <script> window.onload = function (e){ window.print(); }</script>
        </body>
      </html>`)

      WinPrint.document.close()
      // WinPrint.focus();
      // WinPrint.print();
      // WinPrint.close();
    },
    RefreshTeeShet() {
      this.getListBooking_RTS01()
    },
    replaceString(string, oldValue, newValue) {
      return string.replace(oldValue, newValue)
    },
    onTriggeredEventHandler(payload) {
      if (payload.key === F1) {
        this.openSidebarAddBooking(true)
      } else if (payload.key === F2) {
        this.openSidebarCheckInByShortCutKey()
      } else if (payload.key === F3) {
        this.openSidebarCheckOutByShortCutKey()
      } else if (payload.key === F5) {
        this.getListBooking_RTS01()
      } else if (payload.evt.ctrlKey && payload.key === P) {
        this.openPrintTeeSheet()
        // this.printBooking()
      }
    },
    openSidebarCheckOutByShortCutKey() {
      this.bookingDetailData = null
      this.BookingDetail = null
      this.$refs.sidebarCheckout.clearData()
      this.checkPermissionOpenSideBar('RBK02D', 'sidebar-checkout')
      this.isOpenSileBarDetail = false
    },
    openSidebarCheckInByShortCutKey() {
      this.teeTimeId = null
      this.bookingDetailId = null
      this.bookingId = null
      this.bookingDetailprops = null
      this.KeyWordDataProps = null
      this.$refs.SidebarDetailBooking.clearData()
      this.checkPermissionOpenSideBar('RBK02A', 'sidebar-detail-booking')
    },
    SidebarOpenOrCloseHandler(val) {
      if (val.ComponentId === 'sidebar-detail-booking' && val.IsActive === false) {
        if (val.isClearDataBookingDetail) {
          this.bookingDetailId = null
          this.teeTimeId = null
        }
        this.getListBooking_RTS01()
      }
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    async getCashierRole(Id) {
      const body = {
        RoleId: [Id],
      }
      await booking.api_RCS01(body).then(res => {
        if (res.Status === '200') {
          if (Id === this.ROLE_OF_SALE) {
            this.listUserOfSale = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_GM) {
            this.listUserOfGM = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_CASHIER) {
            this.listUserOfCashier = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
        }
      })
    },
    printBDC() {
      this.$root.$emit('bv::show::modal', 'modal-print-BDC')
    },
    openPrintTeeSheet() {
      const routeData = this.$router.resolve({ name: 'PrintTeeSheet', params: { time: this.chooseDate }, query: { C: JSON.stringify(this.selectedCourse.map(x => x.Id)) } })
      window.open(routeData.href, '_blank')
    },
    findColorBookingStatus(BookingStatus) {
      const color = this.ColorBookingDetail[BookingStatus]
      return color
    },
    viewHistory(check, data) {
      if(check) {
        this.dataHistory = {
          BookingCode: data.BookingCode,
          Id: data.BookingId,
          BookingDetail: data
        }
      } else {
        this.dataHistory = data
      }
      this.$refs.History.open(this.dataHistory)
    }
  },
}
