<template>
    <div>
        <b-modal
            id="modal-undo-checkout"
            size="md"
            v-model="isOpen"
            centered
            hide-header
            :no-close-on-backdrop="true"
            >
            <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                <h4 class="mb-0 title-modal color-primary">
                    Undo CheckOut
                </h4>
                <ez-icon
                    icon="ezGolf-icon-x"
                    class="cursor-pointer color-primary"
                    size="24"
                    @click="hideModal"
                />
                </div>
            </b-card-header>
            <b-card-body>
                <div class="form">
                <div class="form-block">
                    <b-form-group>
                        <label>
                            Lý do
                            <span style="color:red">(*)</span>
                        </label>
                    <b-form-textarea
                        v-model="reason"
                        placeholder="Nhập lý do"
                        rows="2"
                        no-resize
                        :autofocus="isOpen"
                    />
                    </b-form-group>
                </div>
                </div>
            </b-card-body>
            <template #modal-footer>
                <div class="w-100">
                <b-button
                    v-b-tooltip.hover
                    title="Ctrl + S"
                    @click="confirmUndoCheckout_ICI03()"
                    variant="primary"
                    size="md"
                    class="float-right"
                    :disabled="isLoading"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                </div>
            </template>
        </b-modal>
        <ShortKey
            :shortcuts="ShortCutKey"
            :isActive="isOpen"
            :allChildComponentClosed="allChildComponentClosed"
            @triggered="onTriggeredEventHandler"
        />
    </div>
</template>
<script>
import { booking } from '@/api/booking'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
    components: {
        ShortKey
    },
    props: [
        'data'
    ],
    data() {
        return {
            isOpen: false,
            reason: '',
            isLoading: false,
            ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
            allChildComponentClosed: []
        }
    },
    watch: {
        isOpen(val) {
            if(val) {
                this.$emit('is-open', {ComponentId: "modal-undo-checkout",IsActive:val});
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-undo-checkout')
        },
        async confirmUndoCheckout_ICI03() {
            this.isLoading = true
            if(this.reason != '') {
                const body = {
                    BookingDetailId: this.data.Type == 'All' ? this.data.Id : [this.data.Id],
                    Note: this.reason
                }
                const response = await booking.api_ICI03(body)
                this.showResToast(response)
                this.isLoading = false
                if(response.Status === '200') {
                    this.$emit('event', { type: 'after-undo-checkout' })
                    this.hideModal()
                    this.reason = ''
                }
            } else {
                this.showToast('error', this.$t('golf_booking_please_reason_undocheckout'))
                this.isLoading = false
            }
        },
        onTriggeredEventHandler(payload) {
            if(payload.evt.ctrlKey && payload.key === S) {
                this.confirmUndoCheckout_ICI03()
            }
        },
    },
}
</script>
<style lang="scss">
    
</style>