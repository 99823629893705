<template>
  <div>
    <b-modal
      v-model="isOpen"
      id="modal-print-BDC"
      size="md"
      centered
      hide-header
      no-close-on-backdrop
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 text-primary">
            {{ $t('golf_booking_print_bdc') }}
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            color="#114A9F"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-overlay
          :show="isLoading"
          :opacity="0.3"
          rounded="sm"
      >
      <b-card-body style="min-height:150px">
          
              <b-row>
                  <b-col cols="8">
                      <b-form-group :label="$t('golf_booking_print_bdc_date')">
                          <date-range-picker
                              v-model="chooseDate"
                              ref="picker"
                              :single-date-picker="true"
                              opens="right"
                              :ranges="false"
                              :time-picker="false"
                              :time-picker-increment="1"
                              :show-dropdowns="true"
                              :auto-apply="true"
                              :locale-data="{
                                  format: 'DD/MM/YYYY',
                                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                  firstDay: 0
                              }"
                              />
                      </b-form-group>
                  </b-col>
                  <b-col cols="4">
                      <b-form-group :label="$t('golf_booking_print_bdc_quantity')">
                          <v-select
                              v-model="Quantity"
                              :options="optionQuantity"
                              :clearable="false"
                              style="min-width: 50px"
                          />
                      </b-form-group>
                  </b-col>
              </b-row>
      </b-card-body>
  </b-overlay>
  <template #modal-footer>
      <div class="w-100">
          <b-button
          variant="primary"
          size="md"
          class="float-right"
          @click="PrintBDC_apiRBK12"
          :disabled="disableButton"
        >
        {{ $t('golf_common_confirm') }}
        </b-button>
          <b-button
          variant="none"
          size="md"
          class="float-right"
          @click="hideModal"
        >
          {{ $t('golf_common_back') }}
        </b-button>
        
      </div>
    </template>
      <div
          id="print-tem1"
          style="width:100mm;  margin: auto; align-items: center;display: none;"
        >

          <div
            v-for="(i, index) in ListBDC"
            :key="index"
            style="display: flex; flex-direction: column; align-items: center;"
          >
            <div v-for="(_, index) in Math.round(TemBDCFormat.Number / 2)" :key="index +'BDC'" style="width:100mm;display: flex; justify-content: center; align-items: center;margin-top:2mm;page-break-after: always;">
                <div>
                  <div style="width: 47mm; height: 24mm;display: flex; justify-content: center; align-items: center;">
                    <div style="text-align: center;">
                      <vue-qrcode
                        :value="i.BDC"
                        :width="55"
                        :margin="0"
                        error-correction-level="L"
                      />
                      <p style="text-align: center; margin-top: 5px; margin-bottom: 0; font-size: 16px">
                        <strong>{{ i.BDC }}</strong>
                      </p>
                    </div>
                    <div style="margin: 0 8px !important; font-size: 11px">
                      <p style="margin-bottom: 0 ;font-size: 11px">
                          <strong style="font-size: 11px">{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}</strong> 
                      </p>
                      <p style="margin-bottom: 0 ;font-size: 6px">
                          {{ $t('golf_booking_print_bdc_time_print') }}: <strong style="font-size: 6px">{{ TimePrint }}</strong> 
                      </p>
                    </div>
                  </div>
                  <div style="height: 3mm;">
                    <p style="font-size: 9px;margin-bottom: 0;text-align: center;">
                      Powered by ezCloud (*)
                    </p>
                  </div>
                </div>
                <div style="width:6mm;height: 27mm" />
                <div>
                  <div style="width: 47mm; height: 24mm;display: flex; justify-content: center; align-items: center;">
                    <div v-if="(index*2 + 2) <= TemBDCFormat.Number" style="text-align: center;">
                      <vue-qrcode
                        :value="i.BDC"
                        :width="55"
                        :margin="0"
                        error-correction-level="L"
                      />
                      <p style="text-align: center; margin-top: 5px; margin-bottom: 0; font-size: 16px">
                        <strong>{{ i.BDC }}</strong>
                      </p>
                    </div>
                    <div v-if="(index*2 + 2) <= TemBDCFormat.Number" style="margin: 0 8px !important;  font-size: 11px">
                      <p style="margin-bottom: 0">
                        <strong style="font-size: 11px">{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}</strong>
                      </p>
                      <p style="margin-bottom: 0;font-size: 6px">
                        {{ $t('golf_booking_print_bdc_time_print') }}: <strong style="font-size: 6px">{{ TimePrint }}</strong>
                      </p>
                    </div>
                  </div>
                  <div style="height: 3mm;">
                    <p style="font-size: 9px;margin-bottom: 0;text-align: center;">
                      Powered by ezCloud (**)
                    </p>
                  </div>
                </div>
            </div>
              
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { booking } from '@/api/booking'
import VueQrcode from 'vue-qrcode'
import dayjs from 'dayjs'
export default {
  components: {
      VueQrcode
  },
  props: ['TemBDCFormat'],
  data() {
      return {
          optionQuantity: [5,10,20,50],
          Quantity: 10,
          chooseDate: this.getUTC('start', 'day'),
          isLoading: false,
          BookingDetailPrint: [{
              BDC: "123",
              OpenDate: "2022-05-31T17:00:00Z",
          }],
          isOpen: false,
          ListBDC: null,
          TimePrint: null,
          disableButton: false
      }
  },
  watch: {
      isOpen(value) {
        console.log(this.TemBDCFormat)
          if(!value) {
              const removeIframe = document.querySelectorAll('iframe')
              if (removeIframe.length > 0) {
                  removeIframe.forEach(x => {
                      document.body.removeChild(x)
                  })
              }
              this.clearData()
          }
      }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-print-BDC')
    },
    async PrintBDC_apiRBK12() {
      this.ListBDC = null
      this.disableButton = true
      this.TimePrint = dayjs().utc().tz(localStorage.getItem('timeZone')).format('HH:mm:ss DD/MM/YYYY')
      this.isLoading = true
      const body = {
        OpenDate: this.chooseDate,
        Number: Number(this.Quantity)
      }
      await booking.api_RBK12(body)
        .then(res => {
          this.ListBDC = res.Data.List
          this.$nextTick(() => {
            setTimeout(() => {
              this.printBDC()
            }, 2000)
          })
          
        })
      
    },
    clearData() {
        this.Quantity = 10,
        this.chooseDate = this.getUTC('start', 'day')
    },
    printBDC() {
      const divContents = document.getElementById('print-tem1').innerHTML
          const data = `<html><head><title>ezCloud_ezGolf</title> <meta charset="UTF-8">
          </head><body style="padding: 0px;margin: 0px;">
          <style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} </style>
          ${divContents}
          </body></html>
          `
          const newBlob = new Blob([data], {
              type: 'text/html',
          })
          const fileLink = window.URL.createObjectURL(newBlob)
          const iframe = document.createElement('iframe')
          iframe.src = fileLink
          iframe.style.display = 'none'
          iframe.onload = () => {
              iframe.contentWindow.focus()
              iframe.contentWindow.print()
              setTimeout(() => {
                this.ListBDC = null
                this.isLoading = false
                this.disableButton = false
              },1000)
          }
          document.body.appendChild(iframe)
    }
  },
}

</script>

<style lang="scss">

</style>
