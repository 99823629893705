import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import Cleave from 'vue-cleave-component'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import PaymentForDistribution from './PaymentForDistribution/PaymentForDistribution.vue'

export default {
  name: 'ModalDistribution',
  components: {
    'delete-dialog': DeleteDialog,
    PaymentForDistribution,
    Cleave,
  },
  props: [
    'bookingDetailIdDistribution',  'bookingCodeDistribution'
  ],
  watch: {
    // bookingDetailIdDistribution(val) {
    //   this.getListDistribution_RPM02D()
    // },
    // bookingCodeDistribution(value) {
    //   this.search.BookingCode = value
    //   this.getListDistribution_RPM02D()
    // },
    isOpen(value) {
      if(!value) {
        this.refreshData()
      } else {
        if(this.bookingDetailIdDistribution) {
          this.getListDistribution_RPM02D()
        } else if (this.bookingCodeDistribution) {
          this.search.BookingCode = this.bookingCodeDistribution
          this.getListDistribution_RPM02D()
        }
      } 
    }
  },
  data() {
    return {
      search: {
        PaymentDistributionCode: null,
        KeyWord: null,
        BookingCode: null,
        GroupName: null,
      },
      dataDistribution: {
        BookingDetail: [],
        PaymentDistribution: {
          TotalAmount: null,
          Code: null,
        },
      },
      dataDistributionEdit: {
        BookingDetail: [],
        PaymentDistribution: {
          TotalAmount: null,
          Code: null,
        },
      },
      fields: [
        {
          key: 'FirstName',
          label: this.$t('golf_booking_distribution_table_full_name'),
        },
        {
          key: 'BDC',
          label: 'BDC',
        },
        {
          key: 'PaymentDistributionCode',
          label: this.$t('golf_booking_distribution_table_distribution_code'),
        },
        {
          key: 'PaymentMethodId',
          label:  this.$t('golf_booking_distribution_table_payment_method'),
        },
        {
          key: 'PaymentTypeCode',
          label: this.$t('golf_booking_distribution_table_payment_type'),
        },
        {
          key: 'Amount',
          label: this.$t('golf_common_amount'),
        },
        {
          key: 'ClientId',
          label: this.$t('golf_common_client'),
        },
        {
          key: 'Note',
          label: this.$t('golf_common_note'),
        },
        {
          key: 'Action',
          // label: this.$t('golf_common_note'),
        },
      ],
      fieldsSingle: [
        {
          key: 'FirstName',
          label: this.$t('golf_booking_distribution_table_full_name'),
        },
        {
          key: 'BDC',
          label: 'BDC',
        },
        {
          key: 'PaymentMethodId',
          label: this.$t('golf_booking_distribution_table_payment_method'),
        },
        {
          key: 'PaymentTypeCode',
          label: this.$t('golf_booking_distribution_table_payment_type'),
        },
        {
          key: 'Amount',
          label: this.$t('golf_common_amount'),
        },
        {
          key: 'ClientId',
          label: this.$t('golf_common_client'),
        },
        {
          key: 'Note',
          label: this.$t('golf_common_note'),
        },
        {
          key: 'Action',
          // label: this.$t('golf_common_note'),
        },
      ],
      dataPayment: null,
      isLoading: false,
      bodyPayment: [],
      bodyPaymentEdit: [],
      totalDistribution: null,
      distributionCode: null,
      listPaymentType: null,
      paymentTypeOptions: null,
      isAddNew: 1,
      editOptions: [
        {
          title: this.$t('golf_common_add_new'),
          value: 1,
        },
        {
          title: this.$t('golf_common_update'),
          value: 0,
        },
      ],
      isCanAddNew: false,
      currentIndex: null,
      listClient: [],
      isOpen: false,
      disableButton: false
    }
  },
  created() {
    this.getConfigInfo_RCOM01()
    // Get list client
    this.getListClient()
  },
  methods: {
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res?.Data?.Client || []
          }
        })
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
        return this.call_api_RPF02()
      }
    },
    async call_api_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          this.listPaymentType = response?.Data?.PaymentType
          this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    getPaymentTypeCode(dataItem) {
      let paymentName = this.$t('golf_booking_no_distribution')
      if (dataItem.PaymentTypeCode) {
        paymentName = this.paymentTypeOptions.find(x => x.value == dataItem.PaymentTypeCode).title
      }

      if (this.bodyPayment.filter(x => x.PaymentDetail.BookingDetailId == dataItem.Id).length > 0) {
        paymentName = this.paymentTypeOptions.find(x => x.value == this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == dataItem.Id).PaymentDetail.PaymentTypeCode).title
      }

      return paymentName
    },
    getPaymentMethod(dataItem) {
      let paymentMethodName = this.$t('golf_booking_no_distribution')
      if (dataItem.PaymentMethodId) {
        paymentMethodName = this.listPaymentType.find(x => x.Id == dataItem.PaymentTypeCode).PaymentMethod.find(y => y.Id == dataItem.PaymentMethodId).Name
      }

      if (this.bodyPayment.filter(x => x.PaymentDetail.BookingDetailId == dataItem.Id).length > 0) {
        paymentMethodName = this.listPaymentType.find(x => x.Id == this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == dataItem.Id).PaymentDetail.PaymentTypeCode).PaymentMethod.find(y => y.Id == this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == dataItem.Id).PaymentDetail.PaymentMethodId).Name
      }

      return paymentMethodName
    },
    openModalPaymentForDistrinution(dataPayment, index) {
      this.dataPayment = dataPayment
      this.currentIndex = index
      this.$root.$emit('bv::show::modal', 'modal-payment-for-distribution')
    },
    openModalPaymentForDistrinutionSingle(dataPayment) {
      this.dataPayment = dataPayment
      this.$root.$emit('bv::show::modal', 'modal-payment-for-distribution')
    },
    deleteDistribution(data) {
      this.dataDistribution.BookingDetail.splice(this.dataDistribution.BookingDetail.indexOf(data), 1)
      if(this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == data.Id)) {
        this.bodyPayment.splice(this.bodyPayment.indexOf(this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == data.Id)), 1)
      }
      // Tính lại total amount
      this.dataDistribution.PaymentDistribution.TotalAmount = 0
      this.bodyPayment.forEach(x => {
        if (x.PaymentDetail.Amount) {
          this.dataDistribution.PaymentDistribution.TotalAmount = this.dataDistribution.PaymentDistribution.TotalAmount + x.PaymentDetail.Amount
        }
      })
    },
    async getListDistribution_RPM02D(isEdit = false) {
      this.isLoading = true
      const body = {
        PaymentDistributionCode: (isEdit) ? this.search.PaymentDistributionCode : null,
        KeyWord: (isEdit) ? null : this.search.KeyWord,
        BookingCode: (isEdit) ? null : this.search.BookingCode,
        BDC: (isEdit) ? null : this.search.BDC,
        GroupName: (isEdit) ? null : this.search.GroupName,
        BookingDetailId: (isEdit) ? null : this.bookingDetailIdDistribution,
      }
      await booking.api_RPM02D(body).then(res => {
        if (res.Status === '200') {
          if (isEdit) {
            this.dataDistributionEdit = res.Data
          } else {
            this.dataDistribution = res.Data
            if (res.Data.BookingDetail.length) {
              // Default mã phân bổ là bdc đầu tiên trong list
              if (this.search.BDC && this.search.BDC.length) {
                this.dataDistribution.PaymentDistribution.Code = this.search.BDC[0]
              } else {
                this.dataDistribution.PaymentDistribution.Code = res.Data.BookingDetail[0].BDC
              }
              this.$forceUpdate()
            }
            if (this.search.BDC && this.search.BDC.length) {
              this.$refs.listBDC.$refs.search.focus()
            }
            this.bodyPayment = []
          }
        } else {
          this.showResToast(res)
        }
      })
      this.isLoading = false
    },
    refreshData() {
      this.dataDistribution = {
        BookingDetail: [],
        PaymentDistribution: {
          TotalAmount: null,
          Code: null,
        },
      }
      this.search = {
        PaymentDistributionCode: null,
        KeyWord: null,
        BookingCode: null,
        GroupName: null,
      }
      this.dataDistributionEdit = {
        BookingDetail: [],
        PaymentDistribution: {
          TotalAmount: null,
          Code: null,
        },
      }
    },
    async handleEvent(result) {
      if (result.type === 'after-add-new-payment-for-distribution') {
        this.isLoading = true
        // Nếu là thêm mới
        if (this.isAddNew) {
          if (this.bodyPayment.filter(x => x.PaymentDetail.BookingDetailId == result.body.PaymentDetail.BookingDetailId).length) {
            this.bodyPayment.find(x => x.PaymentDetail.BookingDetailId == result.body.PaymentDetail.BookingDetailId).PaymentDetail = result.body.PaymentDetail
          } else {
            this.bodyPayment.push(result.body)
          }

          this.dataDistribution.PaymentDistribution.TotalAmount = 0
          this.bodyPayment.forEach(x => {
            if (x.PaymentDetail.Amount) {
              this.dataDistribution.PaymentDistribution.TotalAmount = this.dataDistribution.PaymentDistribution.TotalAmount + x.PaymentDetail.Amount
            }
          })

        // Nếu là cập nhật phân bổ
        } else {
          this.dataDistributionEdit.BookingDetail[this.currentIndex] = { ...this.dataDistributionEdit.BookingDetail[this.currentIndex], ...result.body.PaymentDetail }

          // Tính lại total amount
          this.dataDistributionEdit.PaymentDistribution.TotalAmount = 0
          this.dataDistributionEdit.BookingDetail.forEach(x => {
            if (x.Amount) {
              this.dataDistributionEdit.PaymentDistribution.TotalAmount = this.dataDistributionEdit.PaymentDistribution.TotalAmount + x.Amount
            }
          })
        }
        setTimeout(() => {
          this.isLoading = false
        }, 10)
      }

      // Load lại các lần phân bổ sau khi thêm mới thanh toán thành công
      if (result.type === 'after-add-new-payment-for-distribution-single') {
        this.getListDistribution_RPM02D()
      }
    },
    createDistribution_IPM02D() {
      this.disableButton = true
      let isSubmit = true
      if (!this.dataDistribution.PaymentDistribution.Code && !this.bookingDetailIdDistribution) {
        this.showToast('error', this.$t('golf_booking_distribution_code_invalid'))
        isSubmit = false
        this.disableButton = false
      }

      if (!this.dataDistribution.PaymentDistribution.TotalAmount && !this.bookingDetailIdDistribution || this.dataDistribution.PaymentDistribution.TotalAmount == 0 && !this.bookingDetailIdDistribution) {
        this.showToast('error', this.$t('golf_booking_distribution_amount_invalid'))
        isSubmit = false
        this.disableButton = false

      }
      if (!isSubmit) return
      const body = {
        TotalAmount: parseFloat(this.dataDistribution.PaymentDistribution.TotalAmount),
        Code: this.dataDistribution.PaymentDistribution.Code,
        CashierCode: (this.dataDistribution.PaymentDistribution.CashierCode) ? this.dataDistribution.PaymentDistribution.CashierCode : this.bodyPayment[0].PaymentDetail.CashierCode,
        PaymentDetail: this.bodyPayment.map(x => x.PaymentDetail),
      }
      booking.api_IPM02D(body).then(async res => {
        this.showResToast(res)
        if (res.Status === '200') {
        // Load lại giá trị sau update
          await this.getListDistribution_RPM02D()
          this.$bvModal.show('modal-show-distribution-code')
        }
        this.disableButton = false
      })
    },
    updateDistribution_UPM02D() {
      const TotalAmount = parseFloat(this.dataDistributionEdit.PaymentDistribution.TotalAmount)
      const bkdetailTemp = this.dataDistributionEdit.BookingDetail.filter((item, index) =>  item.BookingDetailStatus != 'CANCELLED' && item.BookingDetailStatus != 'NO_SHOW' && item.BookingDetailStatus != 'CHECK_OUT' && item.PaymentStatus != 'PERMANENT')
      const body = {
        PaymentDistribution: {
          Id: this.dataDistributionEdit.PaymentDistribution.Id,
          TotalAmount, // parseFloat(this.dataDistributionEdit.PaymentDistribution.TotalAmount),
          Code: this.dataDistributionEdit.PaymentDistribution.Code,
          CashierCode: (this.dataDistributionEdit.PaymentDistribution.CashierCode) ? this.dataDistributionEdit.PaymentDistribution.CashierCode : this.bodyPaymentEdit[0].PaymentDetail.CashierCode,
          PaymentDetail: bkdetailTemp.map(x => ({
            // Id: 7809,
            BookingDetailId: x.Id,
            BDC: x.BDC,
            Id: x.PaymentDetailId,
            PaymentId: x.PaymentId,
            PaymentMethodId: x.PaymentMethodId,
            PaymentTypeCode: x.PaymentTypeCode,
            PaymentDistributionCode: x.PaymentDistributionCode,
            Amount: x.Amount,
            CurrencyCode: x.CurrencyCode,
            Note: x.Note,
            HotelCode: x.HotelCode,
            RoomCode: x.RoomCode,
            GuestId: x.GuestId,
            FolioNumber: x.FolioNumber,
            DebitAccountId: x.DebitAccountId,
            ClientId: x.ClientId,
            CardNumber: x.CardNumber,
            ExpirationDate: x.ExpirationDate,
            PaymentGatewayCode: x.PaymentGatewayCode,
            Qrcode: x.Qrcode,
            RootPaymentId: x.RootPaymentId,
            PaymentDistributionId: x.PaymentDistributionId,
            TransactionCode: x.TransactionCode,

            // PaymentMethodId: 1,
            // PaymentTypeCode: 'PAY',
            // Amount: 400000,
            // Note: '',
            // CurrencyCode: 'VND',
            // CardNumber: '',
            // DebitAccountId: null,
            // Bdc: null,
            // ExpirationDate: null,
          })),
        },
      }
      booking.api_UPM02D(body).then(res => {
        this.showResToast(res)
      })
    },
    deleteDistribution_DPM02D(item) {
      const body = {
        PaymentDetail: {
          Id: item.PaymentDetailId,
        },
      }
      booking.api_DPM02D(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.getListDistribution_RPM02D()
        }
      })
    },
    hideModal() {
      this.dataPayment = null

      this.bodyPayment = []
      this.totalDistribution = null
      this.distributionCode = null

      this.$bvModal.hide('modal-distribution')
    },
    addItemDataDistributionEdit(item) {
      const temp = { ...item }
      temp.Note = ''
      temp.PaymentDetailId = 0
      temp.PaymentMethodId = null
      temp.PaymentTypeCode = null
      temp.Amount = null
      temp.TransactionCode = null
      this.dataDistributionEdit.BookingDetail.push(temp)
    },
    deleteItemDataDistributionEdit(index) {
      this.dataDistributionEdit.BookingDetail.splice(index, 1)
      // Tính lại total amount
      this.dataDistributionEdit.PaymentDistribution.TotalAmount = 0
      this.dataDistributionEdit.BookingDetail.forEach(x => {
        if (x.Amount) {
          this.dataDistributionEdit.PaymentDistribution.TotalAmount = this.dataDistributionEdit.PaymentDistribution.TotalAmount + x.Amount
        }
      })
    },
    applyAllDistribution(bookingDetailId) {
      this.dataDistribution.BookingDetail.filter(x => x.Id != bookingDetailId).forEach(z => {
        // Nếu đã tồn tại thì cập nhật, không thì thêm mới
        if (this.bodyPayment.filter(y => y.PaymentDetail.BookingDetailId == z.Id).length) {
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.PaymentMethodId = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.PaymentMethodId
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.PaymentTypeCode = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.PaymentTypeCode
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.Amount = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Amount
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.Note = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Note,
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.CurrencyCode = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CurrencyCode
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.CardNumber = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CardNumber
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.DebitAccountId = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.DebitAccountId
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.ClientId = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.ClientId
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.Bdc = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Bdc
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.CashierCode = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CashierCode
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.ExpirationDate = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.ExpirationDate
          this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == z.Id).PaymentDetail.TransactionCode = this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.TransactionCode
        } else {
          this.bodyPayment.push({
            PaymentDetail: {
              BookingDetailId: z.Id,
              PaymentMethodId: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.PaymentMethodId,
              PaymentTypeCode: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.PaymentTypeCode,
              Amount: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Amount,
              Note: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Note,
              CurrencyCode: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CurrencyCode,
              CardNumber: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CardNumber,
              DebitAccountId: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.DebitAccountId,
              ClientId: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.ClientId,
              Bdc: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.Bdc,
              CashierCode: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.CashierCode,
              ExpirationDate: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.ExpirationDate,
              TransactionCode: this.bodyPayment.find(y => y.PaymentDetail.BookingDetailId == bookingDetailId).PaymentDetail.TransactionCode,
            },
          })
        }
      })
      // Tính lại total amount
      this.dataDistribution.PaymentDistribution.TotalAmount = 0
      this.bodyPayment.forEach(x => {
        if (x.PaymentDetail.Amount) {
          this.dataDistribution.PaymentDistribution.TotalAmount = this.dataDistribution.PaymentDistribution.TotalAmount + x.PaymentDetail.Amount
        }
      })
    },
    applyAllDistributionUpdate(indexCopy) {
      this.dataDistributionEdit.BookingDetail.filter((item, index) => index != indexCopy && item.BookingDetailStatus != 'CANCELLED' && item.BookingDetailStatus != 'NO_SHOW' && item.BookingDetailStatus != 'CHECK_OUT' && item.PaymentStatus != 'PERMANENT').forEach(x => {
        x.PaymentMethodId = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).PaymentMethodId
        x.PaymentTypeCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).PaymentTypeCode
        x.Amount = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).Amount
        x.CurrencyCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).CurrencyCode
        x.Note = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).Note
        x.HotelCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).HotelCode
        x.RoomCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).RoomCode
        x.GuestId = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).GuestId
        x.FolioNumber = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).FolioNumber
        x.DebitAccountId = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).DebitAccountId
        x.ClientId = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).ClientId
        x.CardNumber = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).CardNumber
        x.ExpirationDate = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).ExpirationDate
        x.PaymentGatewayCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).PaymentGatewayCode
        x.Qrcode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).Qrcode
        x.TransactionCode = this.dataDistributionEdit.BookingDetail.find((item, index2) => index2 == indexCopy).TransactionCode
      })

      // Tính lại total amount
      this.dataDistributionEdit.PaymentDistribution.TotalAmount = 0
      this.dataDistributionEdit.BookingDetail.forEach(x => {
        if (x.Amount) {
          this.dataDistributionEdit.PaymentDistribution.TotalAmount = this.dataDistributionEdit.PaymentDistribution.TotalAmount + x.Amount
        }
      })
    },
    copyDistributionCode(text) {
        document.body.insertAdjacentHTML('beforeend', `<div id="copy" contenteditable>${text}</div>`)
        document.getElementById('copy').focus()
        document.execCommand('selectAll')
        document.execCommand('copy')
        document.getElementById('copy').remove()
        this.$bvModal.hide('modal-show-distribution-code')
        this.hideModal()
    }
  },
}
