import Ripple from 'vue-ripple-directive'
import { booking } from '@/api/booking'
import { add_booking } from '@/views/modules/booking/params/add_booking'
import { add_member_params } from '@/views/modules/booking/params/add_member'
import dayjs from 'dayjs'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S,
} from '@/api/constant.js'
import TableAddBooking from '@/views/modules/booking/SidebarAddBooking/TableAddBooking/TableAddBooking'

export default {
  name: 'SidebarAddBooking',
  directives: {
    Ripple,
  },
  components: {
    TableAddBooking,
    ShortKey,
  },
  props: [
    'courses', 'courses_selected', 'date_selected', 'add_member', 'BookingRandom', 'reRenderAddBooking', 'tee', 'isCreateDefaultBdc' , 'bookingShowIconGolfer'
  ],
  data() {
    return {
      step: 1,
      selectedTee: this.$store.state.booking.selectedTee,
      disableAddNewButton: false,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S],
      isOpen: false,
      allChildComponentClosed: [],
      IsCheckLock: {
        Check: true
      }
      // isCreateWithOutBdc: true,
    }
  },

  watch: {
    reRenderAddBooking(val) {
      // Sau khi huỷ booking, load lại RRTB để lấy lại danh sách tee time
      this.$refs.tableAddBooking.refreshAddNew()
    },
    isOpen(val) {
      this.IsCheckLock.Check = true
      if (val) {
        this.$refs.tableAddBooking.OpenAddBooking()
      } else {
        this.$refs.tableAddBooking.resetDateSelect()
      }
      this.$emit('is-open', { ComponentId: 'sidebar-add-booking', IsActive: val })
    },
  },
  computed: {
    // isCreateDefaultBdc() {
    //   let result = false
    //   booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
    //     if (res.Status === '200') {
    //       if (res.Data.filter(x => x.KeyCode == 'BOOKING_AUTO_CREATE_BDC').length && res.Data.find(x => x.KeyCode == 'BOOKING_AUTO_CREATE_BDC').KeyValue == '1') {
    //         result = true
    //       }
    //     }
    //   })
    //   return result
    // },
    coursesSelected() {
      return this.courses_selected
    },
  },
  methods: {
    async TotalNumberOfGolfer(obj) {
      let total = 0
      for (const property in obj) {
        total += obj[property].NumberOfGolfer
      }

      return total
    },
    async createBooking_IBK01() {
      this.disableAddNewButton = true
      const Booking = add_booking
      const _teeTimeInfo = this.$store.state.booking.teeTimeInfo
      const _teeTimePickedInfo = this.$store.state.booking.teePickedInfo
      const _agentInfo = this.$store.state.booking.agentInfo

      // Check điều kiện đã nhập đủ đường line hay chưa
      let isError = false
      _teeTimePickedInfo.forEach(item => {
        // Check điều kiện đã nhập đủ đường line hay chưa trước khi tạo booking, Line bị trống hoặc Line chưa chọn hết tee
        if (item.Line.length == 0 || item.Line.filter(x => !x.Id).length) {
          isError = true
        }
      })

      if (isError) {
        this.showToast('error', this.$t('golf_booking_ball_track_empty'))
        this.disableAddNewButton = false
        return
      }

      Booking.BookingFiscalDate = _teeTimeInfo.startTime
      Booking.BookingDate = _teeTimeInfo.startTime
      Booking.Amount = this.computeAmount(_teeTimePickedInfo)
      Booking.TotalAmount = this.computeAmount(_teeTimePickedInfo)
      Booking.BookingInfo = this.computeBookingInfo(_teeTimeInfo, _teeTimePickedInfo, _agentInfo)
      Booking.TotalNumberOfGolfer = await this.TotalNumberOfGolfer(Booking.BookingInfo)
      Booking.BookingRef = this.BookingRandom

      await booking.createBooking({ Booking, IsGenerateBDC: true, IsLock: this.IsCheckLock.Check }).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if (document.getElementById('form-add-booking')) {
            document.getElementById('form-add-booking').reset()
          }

          this.$emit('event', { type: 'after-add-new-booking', BookingId: response.Data.Booking.Id, TeeTimeId: null })
          this.hideSideBar()

          this.$refs.tableAddBooking.refreshAddNew()
        }
        this.disableAddNewButton = false
      })
    },
    async createBooking_IBK02() {
      const Booking = add_member_params
      const _teeTimeInfo = this.$store.state.booking.teeTimeInfo
      const _teeTimePickedInfo = this.$store.state.booking.teePickedInfo
      const _agentInfo = this.$store.state.booking.agentInfo

      // Check điều kiện đã nhập đủ đường line hay chưa
      let isError = false
      _teeTimePickedInfo.forEach(item => {
        // Check điều kiện đã nhập đủ đường line hay chưa trước khi tạo booking, Line bị trống hoặc Line chưa chọn hết tee
        if (item.Line.length == 0 || item.Line.filter(x => !x.Id).length) {
          isError = true
        }
      })

      if (isError) {
        this.showToast('error', this.$t('golf_booking_ball_track_empty'))
        return
      }

      this.disableAddNewButton = true

      Booking.BookingInfo = this.computeBookingInfo(_teeTimeInfo, _teeTimePickedInfo, _agentInfo)
      Booking.Id = this.add_member.Id

      await booking.addMember({ Booking, IsLock: this.IsCheckLock.Check  }).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-create-add-member-to-detail-booking', BookingId: this.add_member.Id, TeeTimeId: null })
          this.hideSideBar()

          this.$refs.tableAddBooking.refreshAddNew()
        }
        this.disableAddNewButton = false
      })
    },
    async createBookingWithOutBDC_IBK02() {
      const Booking = add_member_params
      const _teeTimeInfo = this.$store.state.booking.teeTimeInfo
      const _teeTimePickedInfo = this.$store.state.booking.teePickedInfo
      const _agentInfo = this.$store.state.booking.agentInfo

      // Check điều kiện đã nhập đủ đường line hay chưa
      let isError = false
      _teeTimePickedInfo.forEach(item => {
        // Check điều kiện đã nhập đủ đường line hay chưa trước khi tạo booking, Line bị trống hoặc Line chưa chọn hết tee
        if (item.Line.length == 0 || item.Line.filter(x => !x.Id).length) {
          isError = true
        }
      })

      if (isError) {
        this.showToast('error', this.$t('golf_booking_ball_track_empty'))
        return
      }

      this.disableAddNewButton = true

      Booking.BookingInfo = this.computeBookingInfo(_teeTimeInfo, _teeTimePickedInfo, _agentInfo)
      Booking.Id = this.add_member.Id

      await booking.addMember({ Booking , IsGenerateBDC: false, IsLock: this.IsCheckLock.Check  }).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-create-add-member-to-detail-booking', BookingId: this.add_member.Id, TeeTimeId: null })
          this.hideSideBar()

          this.$refs.tableAddBooking.refreshAddNew()
        }
        this.disableAddNewButton = false
      })
    },
    async createBookingWithOutBDC_IBK01() {
      const Booking = add_booking
      const _teeTimeInfo = this.$store.state.booking.teeTimeInfo
      const _teeTimePickedInfo = this.$store.state.booking.teePickedInfo
      const _agentInfo = this.$store.state.booking.agentInfo

      // Check điều kiện đã nhập đủ đường line hay chưa
      let isError = false
      _teeTimePickedInfo.forEach(item => {
        // Check điều kiện đã nhập đủ đường line hay chưa trước khi tạo booking, Line bị trống hoặc Line chưa chọn hết tee
        if (item.Line.length == 0 || item.Line.filter(x => !x.Id).length) {
          isError = true
        }
      })

      if (isError) {
        this.showToast('error', this.$t('golf_booking_ball_track_empty'))
        return
      }

      this.disableAddNewButton = true
      Booking.BookingFiscalDate = _teeTimeInfo.startTime
      Booking.BookingDate = _teeTimeInfo.startTime
      Booking.Amount = this.computeAmount(_teeTimePickedInfo)
      Booking.TotalAmount = this.computeAmount(_teeTimePickedInfo)
      Booking.BookingInfo = this.computeBookingInfo(_teeTimeInfo, _teeTimePickedInfo, _agentInfo)
      Booking.TotalNumberOfGolfer = await this.TotalNumberOfGolfer(Booking.BookingInfo)
      Booking.BookingRef = this.BookingRandom

      await booking.createBooking({ Booking, IsGenerateBDC: false, IsLock: this.IsCheckLock.Check  }).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if (document.getElementById('form-add-booking')) {
            document.getElementById('form-add-booking').reset()
          }

          this.$emit('event', { type: 'after-add-new-booking', BookingId: response.Data.Booking.Id, TeeTimeId: null })
          this.hideSideBar()

          this.$refs.tableAddBooking.refreshAddNew()
        }
        this.disableAddNewButton = false
      })
    },
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-add-booking')
      // Reset đường line về rỗng
      // this.$store.commit('booking/SET_GOLF_BALL_TRACK', [])
    },
    computeAmount(teePickedInfo) {
      let sum = 0
      teePickedInfo.map(item => sum += Number(item.Price) * Number(item.BookedNumberPlayer))
      return sum
    },
    computeBookingInfo(teeInfo, teePickedInfo, agentInfo) {
      const body = []
      teePickedInfo.forEach(item => {
        const BookingInfoCourse = []

        item.Line.forEach(x => {
          if (BookingInfoCourse.filter(y => y.CourseId == x.CourseId).length == 0) {
            BookingInfoCourse.push({
              CourseId: x.CourseId,
              NumberOfHole: 9,
            })
          } else {
            BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole + 9
          }
        })
        // Check điều kiện đã nhập đủ đường line hay chưa trước khi tạo booking, Line bị trống hoặc Line chưa chọn hết tee
        if (item.Line.length == 0 || item.Line.filter(x => !x.Id).length) {
          this.showToast('error', this.$t('golf_booking_ball_track_empty'))
          return
        }

        body.push({
          ClientId: agentInfo.ClientId,
          SourceId: agentInfo.SourceId,
          GolfClassId: Number(teeInfo.golfClasses.Id),
          BookingInfoDate: teeInfo.startTime,
          Line: (item.Line) ? item.Line.map(x => x.Id) : [],
          NumberOfGolfer: Number(item.BookedNumberPlayer),
          RateId: Number(teeInfo.rateCodes.Id),
          TeeTimeId: Number(item.Id),
          BookingInfoCourse,

        })
      })
      return body
    },
    onTriggeredEventHandler(payload) {
      // if(payload.key === F4) {
      //   this.hideSideBar()
      // }
      if (payload.evt.ctrlKey && payload.key === S) {
        if(this.$store.state.booking.teePickedInfo.length != 0 && !this.disableAddNewButton) {
          if (!this.add_member) {
            this.createBooking_IBK01()
          } else {
            this.createBooking_IBK02()
          }
        }
      }
      if (payload.evt.ctrlKey && payload.evt.shiftKey && payload.key === S) {
        if(this.$store.state.booking.teePickedInfo.length != 0 && !this.disableAddNewButton) {
          if (!this.add_member && this.isCreateDefaultBdc == '1') {
            this.createBookingWithOutBDC_IBK01()
          } else if (this.add_member && this.isCreateDefaultBdc == '1') {
            this.createBookingWithOutBDC_IBK02()
          }
        }
      }
    },
    // computeBookingInfoCourse(teeInfo) {
    //   console.log(teeInfo)
    //   return teeInfo.courses.map(item => ({ CourseId: item.Id, NumberOfHole: Number(teeInfo.holes.holeAmount) }))
    // },

  },
}
