import vSelect from 'vue-select'
import Decimal from 'decimal.js'
import { booking } from '@/api/booking'
import { bookingDrivingRange } from '@/api/booking-driving-range'
import PaymentType from '@/views/modules/booking/PaymentType.vue'
import ShortKey from '@/views/modules/ultils/ShortKey'
import Confirm from '@/views/modules/booking/Payment/Confirm.vue'
import Cleave from 'vue-cleave-component'
import {
  API_RIV01,
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P,
} from '@/api/constant.js'
import SelectDebitAccount from '@/views/modules/ultils/SelectDebitAccount'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import { commonServices } from '@/api/common-services'

const PaymentTypeCode = {
  Payment: 'PAY',
  Refund: 'REFUND',
}

const PaymentMethodGroup = {
  AR: 'AR',
  BANKTRANSFER: 'BANKTRANSFER',
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  EZTICKET: 'EZTICKET',
  EZGOLF: 'EZGOLF',
  OTHER: 'OTHER',
  PREPAIDCARD: 'PREPAIDCARD',
  QRCODE: 'QRCODE',
  ROOM: 'ROOM',
  VIP: 'VIP',
  PAYMENT_RECORDED_FOR_FO: 'PAYMENT_RECORDED_FOR_FO',
  PAYMENT_RECORDED_FOR_BA: 'PAYMENT_RECORDED_FOR_BA',
}

const paymentBase = {
  Id: null,
  PaymentCode: '',
  PaymentDate: '',
  FiscalPaymentDate: '',
  MemberId: null,
  TotalAmount: 0,
  ReceivedAmount: 0,
  PaymentDetail: [],
}

const formBase = {
  PaymentMethodId: null,
  PaymentTypeCode: null,
  Note: '',
  CashierCode: null,
  Amount: 0,
  CurrencyCode: '',
  CardNumber: '',
  DebitAccountId: null,
  ClientId: null,
  ExpirationDate: null,
  Bdc: null,
  GuestID: null,
  RoomCode: null,
  FolioNum: null,
  VoucherCode: null,
}

export default {
  components: {
    PaymentType,
    SelectDebitAccount,
    vSelect,
    Cleave,
    ConfirmDialog,
    ShortKey,
    Confirm
  },
  props: {
    paymentId: {
      type: Number,
    },
    BookingDetailId: {
      type: Number,
    },
    listUserOfCashier: {
      type: Array,
    },
    quickCheckOut: {
      type: Boolean,
    },
    listBookingDetailIdQuickCheckOut: {
      type: Array,
    },
    clientId: {
      type: Number,
    },
    isShowNoteInCheckOut: {
      type: String
    },
    dataRBK02D: {
      type: Array
    }
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      PaymentMethods: [],
      PaymentTypeData: [],
      form: {
        PaymentMethodId: null,
        PaymentTypeCode: null,
        Note: '',
        CashierCode: null,
        Amount: 0,
        CurrencyCode: '',
        CardNumber: '',
        DebitAccountId: null,
        ExpirationDate: null,
        Bdc: null,
        RoomCode: null,
        GuestID: null,
        FolioNum: null,
        VoucherCode: null,
      },
      fields: [
        {
          key: 'type',
          label: 'ID',
          thStyle: { width: '80%', display: 'none' },
          tdClass: 'e-td',
        },
        {
          key: 'total',
          label: 'ID',
          thStyle: { width: '20%', display: 'none' },
          tdClass: 'e-td',
        },
      ],
      paymentType: '',
      canShowPaymentType: false,
      paymentDr: { ...paymentBase },
      paymentAmountInputExchange: 0,
      debitAccountSelected: {
        Id: null,
        ClientName: '',
      },
      maskExpirationDate: {
        date: true,
        datePattern: ['m', 'y'],
        delimiter: '/',
      },
      maskCreditCard: {
        creditCard: true,
        delimiter: ' ',
      },
      Cashiers: [],
      paymentTypeOptions: [],
      listPaymentType: [],
      zoneId: [],
      paymentTypeName: null,
      TransactionCode: null,
      isLoading: true,
      listUserRoom: [],
      disableAddNew: false,
      voucherDetail: null,
      isOpen: false,
      allChildComponentClosed: [],
      ListPaymentMethodShortCutKey: null,
      listShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      isLoadingCheckVoucher: false,
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pPaymentId: null,
      pIndex: 0,
      pIsGetAll: true,
      pUrl: null,
      fileName: null,
      isShowDetailService: true,
      isInvoiceOnePerson: false,
      isPrintDirectbyPDF: false,
      isPrintInoice: false,
      PaymentMethodByEvoucherInTransactionCode: null,
      PaymentMethodByVoucherInTransactionCode: null,
      dataBookingDetail: {
        BookingDetail: {
          NoteOfDiscount: null,
          Note: null
        }
      },
      listClient: [],
      paymentConfig: null,
      arId: null,
      PrintInvoiceAll: null,
      isAutoLockPayment: null,
      isAutoPrintInvoice: null,
      isAutoChangeToCashAfterAr: null
    }
  },
  created() {
    // this.getPaymentConfig()
    this.getConfigInfo_RCOM01()
    this.getConfigInfo_RCOM01_PaymentMethodByVoucher()
    this.getParam()
    this.getListPaymentMethodShortCutKey()
  },
  computed: {
    // paymentTypeOptions() {
    //   return [
    //     { title: this.$t('golf_common_payment'), value: PaymentTypeCode.Payment },
    //     { title: this.$t('golf_common_refund'), value: PaymentTypeCode.Refund },
    //   ]
    // },
    LS_Currency() {
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      return userData?.currency || []
    },
    baseCurrency() {
      const baseCurrency = this.LS_Currency.find(s => s.IsBase || false)
      return baseCurrency || {
        CurrencyCode: '',
        NoOfDec: 0,
      }
    },
    baseCurrencyCode() {
      return 'CurrencyCode' in this.baseCurrency ? this.baseCurrency.CurrencyCode : ''
    },
    currencyCodeSelected() {
      return this.form.CurrencyCode || ''
    },
    currencySelected() {
      const currencySelectedDetail = this.LS_Currency.find(s => s.CurrencyCode === this.currencyCodeSelected)
      if (typeof currencySelectedDetail !== 'undefined') {
        return {
          DivBase: currencySelectedDetail.DivBase || false,
          ExRate: currencySelectedDetail.ExRate || 1,
          NoOfDec: currencySelectedDetail.NoOfDec || 0,
        }
      }
      return {
        CurrencyCode: this.currencyCodeSelected,
        DivBase: false,
        ExRate: 1,
        NoOfDec: 0,
      }
    },
    totalAmountPaymentBaseCurr() {
      if (this.paymentDr) {
        return this.paymentDr.TotalAmount || 0
      }
      return 0
    },
    totalAmountPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.totalAmountPaymentBaseCurr
      }
      return this.GFn_GetExchangeMoney(this.totalAmountPaymentBaseCurr, this.currencySelected)
    },
    amountRemainPayment() {
      if (this.paymentDr) {
        const receivedAmount = this.paymentDr.ReceivedAmount || 0
        const balance = this.totalAmountPaymentBaseCurr - receivedAmount
        return balance >= 0 ? balance : 0
      }
      return 0
    },
    amountRemainPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.amountRemainPayment
      }
      return this.GFn_GetExchangeMoney(this.amountRemainPayment, this.currencySelected)
    },
    isPaymentDiffBase() {
      return this.currencyCodeSelected !== this.baseCurrencyCode
    },
    showMoneyExchange() {
      return this.isPaymentDiffBase
    },
    // Format Display
    amountRemainPaymentDisplay() {
      return `${this.formatPrice(this.amountRemainPayment)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentBaseCurrDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentBaseCurr)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentExchangeDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentExchange)} ${this.currencyCodeSelected}`
    },
    totalAmountPaymentInputExchangeDisplay() {
      return `${this.formatPrice(this.paymentAmountInputExchange)} ${this.baseCurrencyCode}`
    },
    showFormInputPaymentMethod() {
      return {
        AR: this.paymentType === PaymentMethodGroup.AR,
        BANKTRANSFER: this.paymentType === PaymentMethodGroup.BANKTRANSFER,
        CASH: this.paymentType === PaymentMethodGroup.CASH,
        CREDIT: this.paymentType === PaymentMethodGroup.CREDIT,
        EZTICKET: this.paymentType === PaymentMethodGroup.EZTICKET,
        EZGOLF: this.paymentType === PaymentMethodGroup.EZGOLF,
        OTHER: this.paymentType === PaymentMethodGroup.OTHER,
        PREPAIDCARD: this.paymentType === PaymentMethodGroup.PREPAIDCARD,
        QRCODE: this.paymentType === PaymentMethodGroup.QRCODE,
        ROOM: this.paymentType === PaymentMethodGroup.ROOM,
        VIP: this.paymentType === PaymentMethodGroup.VIP,
        PAYMENT_RECORDED_FOR_FO: this.paymentType === PaymentMethodGroup.PAYMENT_RECORDED_FOR_FO,
        PAYMENT_RECORDED_FOR_BA: this.paymentType === PaymentMethodGroup.PAYMENT_RECORDED_FOR_BA,
      }
    },
  },
  watch: {
    'form.GuestID': function (val) {
      if (val) {
        this.form.FolioNum = this.listUserRoom.find(x => x.GuestID == val).FolioNum
      } else {
        this.form.FolioNum = null
      }
    },
    isOpen(val) {
      if (!val) {
        const removeIframe = document.querySelectorAll('iframe')
        if (removeIframe.length > 0) {
          removeIframe.forEach(x => {
            document.body.removeChild(x)
          })
        }
      }
      this.$emit('is-open', { ComponentId: 'modal-payment', IsActive: val })
    },
    paymentId(value) {
      if (value) {
        this.api_RIV01()
      }
    },
    BookingDetailId(value) {
      if (value) {
        this.getBookingDetailRBK02B(value)
      }
    },
  },
  methods: {
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
        return this.call_api_RPF02()
      }
    },
    async getConfigInfo_RCOM01_PaymentMethodByVoucher() {
      const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      if (response.Status === '200') {
        this.isAutoLockPayment = response?.Data.find(x => x.KeyCode === 'PAYMENT_AUTO_LOCK').KeyValue
        this.PaymentMethodByEvoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_EVOUCHER_IN_TRANSACTIONCODE').KeyValue
        this.PaymentMethodByVoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_VOUCHER_IN_TRANSACTIONCODE').KeyValue
        this.PrintInvoiceAll = response.Data.find(x => x.KeyCode === 'PAYMENT_PRINT_INVOICE_QUICK_ALL').KeyValue
        this.isAutoPrintInvoice = response.Data.find(x => x.KeyCode === 'PAYMENT_AUTO_PRINT_INVOICE').KeyValue
        this.arId = response?.Data.find(item => item.KeyCode === 'PAYMENT_METHOD_BY_BANK_AR').KeyValue
        this.isAutoChangeToCashAfterAr = response?.Data.find(item => item.KeyCode === 'PAYMENT_AUTO_CHANGE_TO_CASH_AFTER_PAY_AR').KeyValue
      }
    },
    async getVoucherCodeByEvoucher() {
      const body = {
        ClientId: this.dataBookingDetail.BookingDetail.ClientId,
        SourceId: this.dataBookingDetail.BookingDetail.SourceId,
        OpenDate: this.dataBookingDetail.BookingDetail.OpenDate,
        GolfClassId: this.dataBookingDetail.BookingDetail.GolfClassId,
        GolfRateId: this.dataBookingDetail.BookingDetail.RateId
      }
      await booking.api_VC_RV06(body).then(res => {
        if (res.Status === '200') {
          if (res.Data.VoucherCode) {
            this.form.VoucherCode = res.Data.VoucherCode
            this.validateVoucher_VC_RV03(this.form.VoucherCode)
          } else {
            this.showToast('error', this.$t('golf_booking_no_find_voucher'))
          }
        } else {
          this.showResToast(res)
        }
      })
    },
    async getBookingDetailRBK02B(BkId) {
      await booking.getDetailBooking2B({ BookingDetail: { Id: BkId } }).then(res => {
        if (res.Status === '200') {
          this.dataBookingDetail = res.Data.Booking
        }
      })
    },
    async call_api_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          this.listPaymentType = response?.Data?.PaymentType
          this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    async openForm() {
      this.isLoading = true
      this.resetFormData()
      // this.form.PaymentMethodId = this.listPaymentType[0].Id

      // Get list client
      await this.getListClient()

      this.form.CurrencyCode = this.baseCurrencyCode
      await this.getDetailPayment_RPM04(this.paymentId)
      this.getBookingDetailRBK02B(this.BookingDetailId)
      // await this.getPaymentMethod()
      this.getListCashier_RCS01()

      this.form.PaymentTypeCode = this.paymentTypeOptions[0].value
      if(this.isAutoChangeToCashAfterAr == 0) {
        if (this.clientId) {
          this.debitAccountSelected.Id = this.clientId
          this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
          this.form.DebitAccountId = this.clientId
          this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
        } else {
          this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
        }
      }
      this.isLoading = false
      this.disableAddNew = false
    },
    resetFormData() {
      this.form = { ...formBase }
      this.paymentDr = { ...paymentBase }
      this.paymentAmountExchange = 0
      this.PaymentTypeData = []
      this.listUserRoom = []
      this.voucherDetail = null
    },
    // async getPaymentConfig() {
    //   const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
    //   if (response.Status === '200') {
    //     this.arId = response?.Data.find(item => item.KeyCode === 'PAYMENT_METHOD_BY_BANK_AR').KeyValue
    //   }
    // },
    onSelectPaymentType(ev) {
      this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType.find(x => x.Code === ev.value).PaymentMethod[0], this.listPaymentType.find(x => x.Code === ev.value).PaymentMethod[0].TransactionCode[0])
      if (ev.value === PaymentTypeCode.Payment) {
        if (this.isPaymentDiffBase) {
          if (this.form.Amount > this.amountRemainPaymentExchange) {
            if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
              this.form.Amount = this.amountRemainPaymentExchange
            }
          }
          this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
        } else if (this.form.Amount > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
          this.form.Amount = this.amountRemainPayment
        }
      }
    },
    onSelectCurrency(ev) {
      this.paymentAmountInputExchange = this.amountRemainPayment
      this.form.Amount = this.amountRemainPaymentExchange
    },
    onChangeAmount(ev) {
      if (ev < 0) {
        this.form.Amount = 0
      }
      if (this.isPaymentDiffBase) {
        if (ev > this.amountRemainPaymentExchange) {
          if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
            this.form.Amount = this.amountRemainPaymentExchange
          }
        }
        this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
      } else if (ev > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
        this.form.Amount = this.amountRemainPayment
      }
    },
    // close() {
    //   this.$emit('closePopup', true)
    // },
    handleEvent() {
    },
    hideModal() {
      this.$bvModal.hide('modal-payment')
    },
    getListCashier_RCS01() {
      // await booking.api_RCS01().then(response => {
      //   if (response.Status === '200') {
      //     this.Cashiers = response?.Data?.Cashier.map(x => ({
      //       id: x.Id,
      //       label: x.CashierName,
      //     }))

      //     const userData = JSON.parse(localStorage.getItem('userData')) || {}
      //     const currentUser = userData?.userId || {}

      //     const temp = response?.Data?.Cashier.find(x => x.Id == currentUser).Id
      //     this.form.CashierCode = this.Cashiers.find(x => x.id == temp)
      //   } else {
      //     this.showResToast(response)
      //   }
      // })
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      const currentUser = userData?.userId || {}
      const temp = this.listUserOfCashier.find(x => x.id == currentUser).id
      this.form.CashierCode = this.listUserOfCashier.find(x => x.id == temp)
    },
    async getDetailRoom_RPM02M(RoomCode) {
      this.form.GuestID = null
      await booking.api_RPM02M({ RoomNumber: RoomCode }).then(response => {
        if (response.Status === '200') {
          this.listUserRoom = response.Data.Guests
          if (this.listUserRoom.length) {
            this.form.GuestID = this.listUserRoom[0].GuestID
          }
        } else {
          this.showResToast(response)
        }
      })
    },
    async validateVoucher_VC_RV03(voucher) {
      if (voucher) {
        this.isLoadingCheckVoucher = true
        await booking.api_VC_RV03({ VoucherCode: voucher, PaymentId: this.paymentId }).then(response => {
          if (response.Status === '200') {
            this.voucherDetail = response.Data
            this.form.Amount = response.Data.TotalAmount
          } else {
            this.voucherDetail = null
            this.form.Amount = 0
            this.showResToast(response)
          }
        })
        this.isLoadingCheckVoucher = false
      } else {
        this.showToast('error', this.$t('golf_common_voucher_invalid'))
      }
    },
    async retryPaymentForRoom_IPM02R(PaymentDetailId) {
      await booking.api_IPM02R({ PaymentDetailId }).then(response => {
        this.showResToast(response)
        if (response.Status == '200') {
          this.getDetailPayment_RPM04(this.paymentId)
          this.$emit('event', { type: 'after-add-new-payment-detail', response })
        }
      })
    },
    // async getPaymentMethod() {
    //   await booking.getListPaymentMethod({}).then(response => {
    //     if (response.Status === '200') {
    //       this.PaymentMethods = response?.Data?.PaymentMethodGroup
    //       const paymentMethodDefault = this.getPaymentMethodDefault(this.PaymentMethods)
    //       if (paymentMethodDefault != null) {
    //         this.paymentType = paymentMethodDefault.Code
    //         this.form.PaymentMethodId = paymentMethodDefault.Id
    //       }
    //       // tach ra lam bo 3
    //       let count = 0
    //       const fields = []
    //       let fieldtmp = []
    //       let datatmp = []
    //       let { length } = this.PaymentMethods
    //       for (const payment of this.PaymentMethods) {
    //         const tmp = {}
    //         tmp.key = payment.Name
    //         tmp.label = payment.Name
    //         tmp.name = 'Code'
    //         payment[`${payment.Name}`] = payment.Name
    //         datatmp.push(payment)
    //         fieldtmp.push(tmp)
    //         count++
    //         length--
    //         if (count == 3) {
    //           fields.push({ fields: fieldtmp, data: datatmp })
    //           fieldtmp = []
    //           datatmp = []
    //           count = 0
    //         }
    //         if (length == 0) {
    //           fields.push(fieldtmp)
    //         }
    //       }
    //       this.PaymentTypeData = fields
    //     }
    //   })
    // },

    async getDetailPayment_RPM04() {
      const payment = {
        Payment: {
          Id: this.paymentId,
        },
      }
      await booking.getListPaymentDetail(payment).then(async response => {
        if (response.Status === '200') {
          this.paymentDr = response?.Data?.Payment
          if(this.isAutoChangeToCashAfterAr == 1) {
            if(this.dataRBK02D.length == 0 && this.paymentDr.PaymentDetail.length == 0) {
              if(this.clientId) {
                this.debitAccountSelected.Id = this.clientId
                this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
                this.form.DebitAccountId = this.clientId
                this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
              } else {
                this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
              }
            } else {
              if(this.dataRBK02D.find(x => x.RootBookingDetailId == this.BookingDetailId  && x.PaymentMethodId == this.arId) || this.paymentDr.PaymentDetail.find(x => x.PaymentMethodId == this.arId && x.RootBookingDetailId == this.BookingDetailId)) {
                this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
              } else {
                if(this.clientId) {
                  this.debitAccountSelected.Id = this.clientId
                  this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
                  this.form.DebitAccountId = this.clientId
                  this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
                } else {
                  this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
                }
              }
            }

            // ----------------------------- nháp -------------------------------- 
            // if(this.dataRBK02D.length > 0) {
            //   if(this.dataRBK02D.find(x => x.RootBookingDetailId == this.BookingDetailId  && x.PaymentMethodId == this.arId) || this.paymentDr.PaymentDetail.find(x => x.PaymentMethodId == this.arId && x.RootBookingDetailId == this.BookingDetailId)) {
            //     this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            //   } else {
            //     if(this.clientId) {
            //       this.debitAccountSelected.Id = this.clientId
            //       this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
            //       this.form.DebitAccountId = this.clientId
            //       this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
            //     } else {
            //       this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            //     }
            //   }
            // } else {
            //   if(this.paymentDr.PaymentDetail.length > 0) {
            //     if(this.paymentDr.PaymentDetail.find(x => x.PaymentMethodId == this.arId && x.RootBookingDetailId == this.BookingDetailId)) {
            //       this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            //     } else {
            //       if(this.clientId) {
            //         this.debitAccountSelected.Id = this.clientId
            //         this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
            //         this.form.DebitAccountId = this.clientId
            //         this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
            //       } else {
            //         this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            //       }
            //     }
            //   } else {
            //     if (this.clientId) {
            //       this.debitAccountSelected.Id = this.clientId
            //       this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
            //       this.form.DebitAccountId = this.clientId
            //       this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId), this.listPaymentType[0].PaymentMethod.find(y => y.Id == this.arId).TransactionCode[0])
            //     } else {
            //       this.handleChoicePayment(this.form.PaymentTypeCode,this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            //     }
            //   }
            // }
            
          }
          if(this.paymentDr.LockPaymentCode === 'UN_LOCK' && this.isAutoLockPayment == 1) {
            this.lockPayment(this.paymentDr.BookingDetailId)
          }
          if (this.paymentDr.PaymentStatus === 'FULL_PAY' && this.isPrintInoice) {
            if(this.paymentDr.Status === 'PERMANENT') {
              if(this.isAutoPrintInvoice == 1) {
                this.getInoicePdf()
                this.isPrintInoice = false
              } else {
                this.isPrintInoice = false
              }
            } else {
              this.$refs.confirmPrintInvoice.open({
                Id: this.paymentId,
                dataConfirm: {
                  title: this.$t('golf_booking_issue_invoice'),
                  content: this.$t('golf_booking_confirm_issue_invoice'),
                }
              })
            }
          } else {
            this.isPrintInoice = false
          }
          this.form.Amount = this.amountRemainPaymentExchange || 0
          if (this.isPaymentDiffBase) {
            this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
          }
        } else {
          this.showResToast(response)
        }
      })
    },
    // getPaymentMethodDefault(paymentMethods) {
    //   let paymentMethodDefault = null
    //   paymentMethods.forEach(s => {
    //     const paymentMethodList = s.PaymentMethod || []
    //     paymentMethodList.forEach(s1 => {
    //       if (s1.Code === PaymentMethodGroup.CASH) {
    //         paymentMethodDefault = {
    //           Id: s1.Id,
    //           Code: s1.Code,
    //         }
    //       }
    //     })
    //   })
    //   return paymentMethodDefault
    // },
    calTotalAmount() {
      const totalAmount = this.paymentDr.TotalAmount || 0
      const receivedAmount = this.paymentDr.ReceivedAmount || 0
      const balance = totalAmount - receivedAmount
      const total = balance >= 0 ? balance : 0
      if (this.paymentDr) {
        return `${this.formatPrice(total)} ${this.baseCurrencyCode}`
      }
      return `0 ${this.baseCurrencyCode}`
    },
    async lockPayment(data) {
      const body = {
        BookingDetailId: [data],
        LockPayment: true,
      }
      await booking.api_UBK11(body)
    },
    async createPaymentDetail_IPM04() {
      this.disableAddNew = true
      const { paymentId } = this
      const amount = parseFloat(this.form.Amount || '0')
      const paymentData = {
        PaymentId: paymentId,
        PaymentMethodId: parseInt(this.form.PaymentMethodId, 10),
        PaymentTypeCode: this.form.PaymentTypeCode,
        Amount: amount,

        HotelCode: JSON.parse(localStorage.getItem('currentSiteId')),
        RoomCode: this.form.RoomCode,
        GuestId: (this.form.GuestID) ? this.form.GuestID.toString() : null,
        GuestName: (this.form.GuestID) ? this.listUserRoom.find(x => x.GuestID == this.form.GuestID).GuestName : null,
        FolioNumber: this.form.FolioNum,

        VoucherCode: this.form.VoucherCode,

        Note: this.form.Note,
        CurrencyCode: this.form.CurrencyCode,
        CardNumber: this.form.CardNumber,
        DebitAccountId: this.form.DebitAccountId,
        ClientId: this.form.ClientId,
        Bdc: this.form.Bdc,
        CashierCode: (this.form.CashierCode.id) ? `${this.form.CashierCode.id}` : null,
        ExpirationDate: this.createFullDataExpirationDate(this.form.ExpirationDate),
        TransactionCode: this.TransactionCode,
      }
      const dataPost = {
        PaymentDetail: {
          ...paymentData,
        },
      }
      let isSubmit = true
      if (!paymentData.PaymentTypeCode) {
        this.showToast('error', this.$t('golf_common_please_select_payment_type'))
        isSubmit = false
      }
      if (amount < 0) {
        isSubmit = false
        this.showToast('error', this.$t('golf_common_amount_invalid'))
      }
      if (!paymentData.CurrencyCode) {
        isSubmit = false
        this.showToast('error', this.$t('golf_common_please_select_a_currency'))
      }
      if (!this.paymentType || this.form.PaymentMethodId === null) {
        this.showToast('error', this.$t('golf_common_please_choose_a_form_of_payment'))
        isSubmit = false
      }
      if (this.paymentType === PaymentMethodGroup.AR) {
        if (!this.form.DebitAccountId) {
          this.showToast('error', this.$t('golf_common_please_choose_debit_account'))
          isSubmit = false
        }
      }
      if (this.paymentType === PaymentMethodGroup.CREDIT) {
        // if (!this.form.CardNumber) {
        //   this.showToast('error', this.$t('golf_common_please_enter_card_number'))
        //   isSubmit = false
        // }
        // if (!this.form.ExpirationDate) {
        //   this.showToast('error', this.$t('golf_common_please_enter_expiration_date'))
        //   isSubmit = false
        // }
      }
      if (this.paymentType === PaymentMethodGroup.OTHER && this.PaymentMethodByEvoucherInTransactionCode === this.TransactionCode || this.PaymentMethodByVoucherInTransactionCode === this.TransactionCode) {
        if (!this.form.VoucherCode) {
          this.showToast('error', this.$t('golf_common_please_enter_voucher'))
          isSubmit = false
        }
        if (this.form.VoucherCode && !this.voucherDetail) {
          this.showToast('error', this.$t('golf_common_voucher_invalid'))
          isSubmit = false
        }
      }

      if (!isSubmit) {
        setTimeout(() => {
          this.disableAddNew = false
        }, 1000)
        return
      }

      await booking.createPaymentDetail(dataPost).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.isPrintInoice = true
          this.getDetailPayment_RPM04(paymentId)
          this.$emit('event', { type: 'after-add-new-payment-detail', response })

          // this.$emit('closePopup', true)
        }
      })
      setTimeout(() => {
        this.disableAddNew = false
      }, 1000)
    },
    getMemberPay() {
      if (this.paymentDr) {
        return `Hóa đơn #${this.paymentDr.Id} ${this.paymentDr.FullName}`
      }
      return ''
    },
    openModalDeletePaymentDetail(id) {
      this.$emit('event', { type: 'open-modal-delete-payment-detail', paymentDetailId: id })
    },
    handlePaymentType(type) {
      const isShow = type === 'show'
      this.canShowPaymentType = isShow
    },
    handleChoicePayment(item, payment, trans) {
      this.form.PaymentTypeCode = item
      this.paymentType = payment.Code
      this.paymentTypeName = `${payment.Name} - ${trans.Description}`
      this.TransactionCode = trans.TransactionCode
      this.form.PaymentMethodId = payment.Id
      this.canShowPaymentType = false
      this.form.VoucherCode = null
      if (this.paymentType !== PaymentMethodGroup.CREDIT) {
        this.form.ExpirationDate = null
        this.form.CardNumber = ''
      }
      if (this.paymentType !== PaymentMethodGroup.PAYMENT_RECORDED_FOR_FO) {
        this.form.GuestID = null
        this.form.RoomCode = null
        this.form.GuestName = null
        this.form.HotelCode = null
      }
      if (this.paymentType !== PaymentMethodGroup.PAYMENT_RECORDED_FOR_BA) {
        this.form.GuestID = null
        this.form.RoomCode = null
        this.form.GuestName = null
        this.form.HotelCode = null
      }
      // if (this.paymentType !== PaymentMethodGroup.OTHER) {
        // this.form.VoucherCode = null
      // }
      if (this.paymentType !== PaymentMethodGroup.AR) {
        this.form.ClientId = null
        this.form.DebitAccountId = null
      }
      if (this.paymentType == PaymentMethodGroup.AR && this.clientId && this.listClient.filter(x => x.Id == this.clientId).length) {
        this.form.DebitAccountId = this.clientId
        this.form.ClientId = this.clientId
        this.debitAccountSelected.Id = this.clientId
        this.debitAccountSelected.ClientName = this.listClient.find(x => x.Id == this.clientId).ClientName
      }
    },
    getPaymentMethodNameById(id) {
      let name = id
      if (this.paymentTypeOptions.filter(x => x.value == id).length) {
        name = this.paymentTypeOptions.find(x => x.value == id).title
      }

      // this.PaymentMethods.forEach(x => {
      //   x.PaymentMethod.forEach(y => {
      //     if (y.Id === id) {
      //       name = y.Name
      //     }
      //   })
      // })
      return name
    },
    openSelectARAccount() {
      this.$root.$emit('bv::show::modal', 'modal-select-ar')
    },
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res?.Data?.Client || []
          }
        })
    },
    selectArAccount(arAccountData) {
      Object.keys(this.debitAccountSelected).forEach(arKey => {
        if (arKey in arAccountData) {
          this.debitAccountSelected[arKey] = arAccountData[arKey]
        }
      })
      this.form.DebitAccountId = this.debitAccountSelected.Id
      this.form.ClientId = this.debitAccountSelected.Id
    },
    createFullDataExpirationDate(shortExpirationDate) {
      if (shortExpirationDate) {
        const [month, yearOld] = shortExpirationDate.split('/')
        const year = this.dayjs(`${month}/01/${yearOld}`).format('YYYY')
        return new Date(year, month, 0, 23, 59, 59)
      }
      return null
    },
    GFn_GetExchangeMoney(baseMoney, {
      DivBase,
      ExRate,
      NoOfDec,
    }) {
      const baseMoneyToDecimal = new Decimal(baseMoney || 0)
      let exchangeMoney = 0
      const noOfDec = NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        exchangeMoney = Decimal.div(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        exchangeMoney = Decimal.mul(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(exchangeMoney).mul(1).toNumber()
    },
    GFn_GetBaseMoneyFromExchange(exchangeMoney, {
      DivBase,
      ExRate,
    }) {
      const exchangeMoneyToDecimal = new Decimal(exchangeMoney || 0)
      let baseMoney = 0
      const noOfDec = this.baseCurrency.NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        baseMoney = Decimal.mul(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        baseMoney = Decimal.div(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(baseMoney).mul(1).toNumber()
    },
    call_api_UPM02(data) {
      const body = {
        Payment: {
          Id: data.Id,
        },
      }
      booking.api_UPM02(body).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if(this.isPrintInoice) {
            if(this.isAutoPrintInvoice == 1) {
              this.getInoicePdf()
              this.isPrintInoice = false
            } else {
              this.isPrintInoice = false
            }
          }
          this.getDetailPayment_RPM04()
          this.$emit('event', { type: 'after-add-new-payment-detail'})
        }
      })
    },
    async PublicInvoiceQuickCheckOut() {
      const body = {
        Payment: {
          Id: this.paymentId,
        },
      }
      if (this.paymentDr.Status !== 'PERMANENT') {
        await booking.api_UPM02(body).then(response => {
          if (response.Status === '200') {
            this.getDetailPayment_RPM04()
            this.QuickCheckOut_apiICI02()
          }
        })
      } else {
        this.QuickCheckOut_apiICI02()
      }
    },
    async QuickCheckOut_apiICI02() {
      const body = {
        BookingDetailId: this.listBookingDetailIdQuickCheckOut,
      }
      await booking.api_ICI02(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.$emit('event', { type: 'after-add-new-payment-detail', res })
        }
      })
    },
    openRIV01(paymentId) {
      const routeData = this.$router.resolve({ name: 'booking-print-invoice', params: { id: paymentId } })
      window.open(routeData.href, '_blank')
    },
    // openRIV01_Pdf(paymentId) {
    //       const routeData = this.$router.resolve({ name: 'booking-print-invoice', params: { id: paymentId },query: {pdf: true} })
    //       window.open(routeData.href, '_blank')
    //  },
    async getListPaymentMethodShortCutKey() {
      const body = {
        KeyGroup: 'SHORTCUT_KEY',
      }
      await commonServices.getCommon(body).then(res => {
        this.ListPaymentMethodShortCutKey = res.Data
        this.ListPaymentMethodShortCutKey.forEach((x, index) => {
          x.KeyCode = this.listShortCutKey[index]
        })
      })
    },
    onTriggeredEventHandler(payload) {
      this.ListPaymentMethodShortCutKey.forEach(x => {
        if (payload.key === x.KeyCode && x.KeyCode !== P && x.KeyCode !== S && x.KeyCode !== R) {
          this.listPaymentType.forEach(y => {
            y.PaymentMethod.forEach(z => {
              for (let i = 0; i < z.TransactionCode.length; i++) {
                if (z.TransactionCode[i].TransactionCode == x.KeyValue) {
                  this.handleChoicePayment(this.form.PaymentTypeCode,z, z.TransactionCode[i])
                  break
                }
              }
            })
          })
        }
      })
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.disableAddNew) {
          this.createPaymentDetail_IPM04()
        }
      }
      if (payload.evt.ctrlKey && payload.key === R) {
        this.$refs.confirmDialogComponent.open({
          Id: this.paymentDr.Id,
          dataConfirm: {
            title: this.$t('golf_booking_issue_invoice'),
            content: this.$t('golf_booking_confirm_issue_invoice'),
          },
        })
      }
      if (payload.evt.ctrlKey && !payload.evt.shiftKey && payload.key === P) {
        this.openRIV01(this.paymentDr.Id)
      }
      if (payload.evt.ctrlKey && payload.evt.shiftKey && payload.key === P) {
        this.getInoicePdf()
      }
    },
    QuickCheckOut() {
      this.PublicInvoiceQuickCheckOut()
    },
    async getInoicePdf() {
      if(this.PrintInvoiceAll == 1) {
        var body = {
          ReportName: this.fileName,
          Params: {
            pIsGetAll: true, pInvoiceIndex: 0, pToken: this.pToken, pPaymentId: this.paymentId, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName
          },
        }
      } else {
        var body = {
          ReportName: this.fileName,
          Params: {
            pIsGetAll: false, pInvoiceIndex: 0, pToken: this.pToken, pPaymentId: this.paymentId, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName
          },
        }
      }
      await booking.api_RRPDF(body).then(response => {
        const decodedData = Buffer.from(response.Data.FileContents, 'base64')
        const newBlob = new Blob([decodedData], {
          type: 'application/pdf',
        })
        const iframe = document.createElement('iframe')
        const url = window.URL.createObjectURL(newBlob)
        iframe.src = url
        iframe.style.display = 'none'
        iframe.onload = () => {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        }
        document.body.appendChild(iframe)
      })
    },
    getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang = localStorage.getItem('systemLanguage') || '1000000'
      this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone = localStorage.getItem('timeZone')
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
      this.pPaymentId = this.$route.params.id * 1
      this.api_RCOM01().then(() => {
        this.api_RCF00()
      })
    },
    async api_RCOM01() {
      const body = {
        KeyGroup: 'REPORT_CONFIG',
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == 'IV01_REPORT_FILE_NAME').KeyValue
      })
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrl = `${response.Data.find(x => x.SettingCode === 'SITE_API_URL').SettingValue}/api${API_RIV01}`
      })
    },
    async api_RIV01() {
      const body = {
        PaymentId: this.paymentId,
        Type: 'ALL',
      }
      await booking.api_RIV01(body).then(response => {
        if (response.Data.Invoice[0].ExtenalInvoiceProduct.length == 0) {
          this.isShowDetailService = false
        } else {
          this.isShowDetailService = true
        }
      })
    },
    PrintInvoice() {
      if(this.isAutoPrintInvoice == 1) {
        this.getInoicePdf()
        this.isPrintInoice = false
      } else {
        this.isPrintInoice = false
      }
    }
  },
}
