import { booking } from '@/api/booking'

export default {
  name: 'SelectChecbox',
  components: {
  },
  props: {
    value: {
      type: Array,
      required: false,
    },
    courseId: {
      type: Number,
      required: true,
    },
    numberOfHole: {
      type: Number,
      required: true,
    },
    teeStartId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      linePlay: [],
      detailHole: {
        Tee: [],
      },
      isOpen: false,
    }
  },
  watch: {
    teeStartId() {
      this.getTeeByCourseId()
    },
    courseId() {
      this.getTeeByCourseId()
    },
    numberOfHole(val) {
      this.linePlay = []
      for (let item = 0; item < val / 9; item++) {
        this.linePlay.push(item)
      }
      this.getTeeByCourseId()
    },
  },
  created() {
    this.getTeeByCourseId()
    for (let item = 0; item < this.numberOfHole / 9; item++) {
      this.linePlay.push(item)
    }
  },
  methods: {
    updateValue(tee, index) {
      this.linePlay[index] = tee
      this.value = [...this.linePlay]
      this.$emit('input', this.value)
      this.$emit('update', this.value)
    },
    getTeeByCourseId() {
      booking.api_RTE03({ CourseId: this.courseId }).then(res => {
        if (res.Status === '200') {
          this.detailHole = res?.Data

          if (this.detailHole.DefaultLine.filter(x => x.CourseId == this.courseId && x.NumberOfHoles == this.numberOfHole && x.TeeStart == this.teeStartId).length) {
            const tmp = this.detailHole.DefaultLine.find(x => x.CourseId == this.courseId && x.NumberOfHoles == this.numberOfHole && x.TeeStart == this.teeStartId).Tee
            tmp.forEach((item, index) => {
              this.linePlay[index] = this.detailHole.Tee.find(x => x.Id == item)
            })
            this.value = [...this.linePlay]
          } else {
            this.value = []
          }
          this.$emit('input', this.value)
          this.$emit('update', this.value)
        }
      })
    },
  },
}
